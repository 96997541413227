// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Mortality } from '../model';
// @ts-ignore
import { MortalityResource } from '../model';
/**
 * MortalitiesApi - axios parameter creator
 * @export
 */
export const MortalitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityByTypeId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMortalityByTypeId', 'id', id)
            const localVarPath = `/api/mortality/byType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMortalityId', 'id', id)
            const localVarPath = `/api/mortality/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortality: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityByTypeId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiMortalityByTypeId', 'id', id)
            const localVarPath = `/api/mortality/byType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiMortalityId', 'id', id)
            const localVarPath = `/api/mortality/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortalityResource} [mortalityResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortality: async (mortalityResource?: MortalityResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityResource} [mortalityResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityId: async (id: number, mortalityResource?: MortalityResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMortalityId', 'id', id)
            const localVarPath = `/api/mortality/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortalitiesApi - functional programming interface
 * @export
 */
export const MortalitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortalitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMortalityByTypeId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMortalityByTypeId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMortalityId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMortalityId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortality(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mortality>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortality(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityByTypeId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Mortality>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityByTypeId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mortality>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MortalityResource} [mortalityResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMortality(mortalityResource?: MortalityResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mortality>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMortality(mortalityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityResource} [mortalityResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMortalityId(id: number, mortalityResource?: MortalityResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mortality>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMortalityId(id, mortalityResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortalitiesApi - factory interface
 * @export
 */
export const MortalitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortalitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {MortalitiesApiDeleteApiMortalityByTypeIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityByTypeId(requestParameters: MortalitiesApiDeleteApiMortalityByTypeIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiMortalityByTypeId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalitiesApiDeleteApiMortalityIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityId(requestParameters: MortalitiesApiDeleteApiMortalityIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiMortalityId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortality(options?: AxiosRequestConfig): AxiosPromise<Array<Mortality>> {
            return localVarFp.getApiMortality(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalitiesApiGetApiMortalityByTypeIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityByTypeId(requestParameters: MortalitiesApiGetApiMortalityByTypeIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Mortality>> {
            return localVarFp.getApiMortalityByTypeId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalitiesApiGetApiMortalityIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityId(requestParameters: MortalitiesApiGetApiMortalityIdRequest, options?: AxiosRequestConfig): AxiosPromise<Mortality> {
            return localVarFp.getApiMortalityId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalitiesApiPostApiMortalityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortality(requestParameters: MortalitiesApiPostApiMortalityRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Mortality> {
            return localVarFp.postApiMortality(requestParameters.mortalityResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalitiesApiPutApiMortalityIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityId(requestParameters: MortalitiesApiPutApiMortalityIdRequest, options?: AxiosRequestConfig): AxiosPromise<Mortality> {
            return localVarFp.putApiMortalityId(requestParameters.id, requestParameters.mortalityResource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortalitiesApi - interface
 * @export
 * @interface MortalitiesApi
 */
export interface MortalitiesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    deleteApiMortalityByTypeId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    deleteApiMortalityId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    getApiMortality(options?: AxiosRequestConfig): AxiosPromise<Array<Mortality>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    getApiMortalityByTypeId(id: number, options?: AxiosRequestConfig): AxiosPromise<Array<Mortality>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    getApiMortalityId(id: number, options?: AxiosRequestConfig): AxiosPromise<Mortality>;

    /**
     * 
     * @param {MortalityResource} [mortalityResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    postApiMortality(mortalityResource?: MortalityResource, options?: AxiosRequestConfig): AxiosPromise<Mortality>;

    /**
     * 
     * @param {number} id 
     * @param {MortalityResource} [mortalityResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApiInterface
     */
    putApiMortalityId(id: number, mortalityResource?: MortalityResource, options?: AxiosRequestConfig): AxiosPromise<Mortality>;

}

/**
 * Request parameters for deleteApiMortalityByTypeId operation in MortalitiesApi.
 * @export
 * @interface MortalitiesApiDeleteApiMortalityByTypeIdRequest
 */
export interface MortalitiesApiDeleteApiMortalityByTypeIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalitiesApiDeleteApiMortalityByTypeId
     */
    readonly id: number
}

/**
 * Request parameters for deleteApiMortalityId operation in MortalitiesApi.
 * @export
 * @interface MortalitiesApiDeleteApiMortalityIdRequest
 */
export interface MortalitiesApiDeleteApiMortalityIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalitiesApiDeleteApiMortalityId
     */
    readonly id: number
}

/**
 * Request parameters for getApiMortalityByTypeId operation in MortalitiesApi.
 * @export
 * @interface MortalitiesApiGetApiMortalityByTypeIdRequest
 */
export interface MortalitiesApiGetApiMortalityByTypeIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalitiesApiGetApiMortalityByTypeId
     */
    readonly id: number
}

/**
 * Request parameters for getApiMortalityId operation in MortalitiesApi.
 * @export
 * @interface MortalitiesApiGetApiMortalityIdRequest
 */
export interface MortalitiesApiGetApiMortalityIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalitiesApiGetApiMortalityId
     */
    readonly id: number
}

/**
 * Request parameters for postApiMortality operation in MortalitiesApi.
 * @export
 * @interface MortalitiesApiPostApiMortalityRequest
 */
export interface MortalitiesApiPostApiMortalityRequest {
    /**
     * 
     * @type {MortalityResource}
     * @memberof MortalitiesApiPostApiMortality
     */
    readonly mortalityResource?: MortalityResource
}

/**
 * Request parameters for putApiMortalityId operation in MortalitiesApi.
 * @export
 * @interface MortalitiesApiPutApiMortalityIdRequest
 */
export interface MortalitiesApiPutApiMortalityIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalitiesApiPutApiMortalityId
     */
    readonly id: number

    /**
     * 
     * @type {MortalityResource}
     * @memberof MortalitiesApiPutApiMortalityId
     */
    readonly mortalityResource?: MortalityResource
}

/**
 * MortalitiesApi - object-oriented interface
 * @export
 * @class MortalitiesApi
 * @extends {BaseAPI}
 */
export class MortalitiesApi extends BaseAPI implements MortalitiesApiInterface {
    /**
     * 
     * @param {MortalitiesApiDeleteApiMortalityByTypeIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public deleteApiMortalityByTypeId(requestParameters: MortalitiesApiDeleteApiMortalityByTypeIdRequest, options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).deleteApiMortalityByTypeId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalitiesApiDeleteApiMortalityIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public deleteApiMortalityId(requestParameters: MortalitiesApiDeleteApiMortalityIdRequest, options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).deleteApiMortalityId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public getApiMortality(options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).getApiMortality(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalitiesApiGetApiMortalityByTypeIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public getApiMortalityByTypeId(requestParameters: MortalitiesApiGetApiMortalityByTypeIdRequest, options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).getApiMortalityByTypeId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalitiesApiGetApiMortalityIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public getApiMortalityId(requestParameters: MortalitiesApiGetApiMortalityIdRequest, options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).getApiMortalityId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalitiesApiPostApiMortalityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public postApiMortality(requestParameters: MortalitiesApiPostApiMortalityRequest = {}, options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).postApiMortality(requestParameters.mortalityResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalitiesApiPutApiMortalityIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalitiesApi
     */
    public putApiMortalityId(requestParameters: MortalitiesApiPutApiMortalityIdRequest, options?: AxiosRequestConfig) {
        return MortalitiesApiFp(this.configuration).putApiMortalityId(requestParameters.id, requestParameters.mortalityResource, options).then((request) => request(this.axios, this.basePath));
    }
}
