// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Company } from '../model';
// @ts-ignore
import { CompanySiteBriefInfo } from '../model';
// @ts-ignore
import { CompanyWeb } from '../model';
// @ts-ignore
import { CompanyWebSMA } from '../model';
// @ts-ignore
import { SimpleCompanyWeb } from '../model';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiCompaniesId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiCompaniesId', 'id', id)
            const localVarPath = `/api/Companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompanies: async (select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountNumber 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesAccountnumberAccountNumber: async (accountNumber: string, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('getApiCompaniesAccountnumberAccountNumber', 'accountNumber', accountNumber)
            const localVarPath = `/api/Companies/accountnumber/{accountNumber}`
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [$include] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesCompanyIdHierarchy: async (companyId: string, $include?: string, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiCompaniesCompanyIdHierarchy', 'companyId', companyId)
            const localVarPath = `/api/Companies/{companyId}/hierarchy`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($include !== undefined) {
                localVarQueryParameter['$include'] = $include;
            }

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint is only used by chilean companies which can have associated companies when they take over other companies  It assumes that sites from purchased companies already exist on the parent company, and has an association set to its old company.
         * @param {string} companyId 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesCompanyIdSma: async (companyId: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiCompaniesCompanyIdSma', 'companyId', companyId)
            const localVarPath = `/api/Companies/{companyId}/sma`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} area 
         * @param {string} [level] 
         * @param {boolean} [includeDeleted] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesEntitiesArea: async (area: string, level?: string, includeDeleted?: boolean, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'area' is not null or undefined
            assertParamExists('getApiCompaniesEntitiesArea', 'area', area)
            const localVarPath = `/api/Companies/entities/{area}`
                .replace(`{${"area"}}`, encodeURIComponent(String(area)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesEntitiesSimple: async (companyId?: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/entities/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesId: async (id: string, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiCompaniesId', 'id', id)
            const localVarPath = `/api/Companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesIdAccountnumber: async (id: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiCompaniesIdAccountnumber', 'id', id)
            const localVarPath = `/api/Companies/{id}/accountnumber`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$include] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesSelf: async ($include?: string, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/Self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($include !== undefined) {
                localVarQueryParameter['$include'] = $include;
            }

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesSites: async (select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies/Sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiCompanies: async (select?: string, expand?: string, company?: Company, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiCompaniesId: async (id: string, select?: string, expand?: string, company?: Company, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiCompaniesId', 'id', id)
            const localVarPath = `/api/Companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiCompaniesId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiCompaniesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompanies(select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompanies(select, expand, filter, orderby, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountNumber 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesAccountnumberAccountNumber(accountNumber: string, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesAccountnumberAccountNumber(accountNumber, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [$include] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesCompanyIdHierarchy(companyId: string, $include?: string, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesCompanyIdHierarchy(companyId, $include, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint is only used by chilean companies which can have associated companies when they take over other companies  It assumes that sites from purchased companies already exist on the parent company, and has an association set to its old company.
         * @param {string} companyId 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesCompanyIdSma(companyId: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyWebSMA>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesCompanyIdSma(companyId, select, expand, filter, orderby, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} area 
         * @param {string} [level] 
         * @param {boolean} [includeDeleted] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesEntitiesArea(area: string, level?: string, includeDeleted?: boolean, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesEntitiesArea(area, level, includeDeleted, select, expand, filter, orderby, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesEntitiesSimple(companyId?: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleCompanyWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesEntitiesSimple(companyId, select, expand, filter, orderby, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesId(id: string, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesId(id, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesIdAccountnumber(id: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesIdAccountnumber(id, select, expand, filter, orderby, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$include] 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesSelf($include?: string, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySiteBriefInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesSelf($include, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {string} [filter] 
         * @param {string} [orderby] 
         * @param {boolean} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesSites(select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesSites(select, expand, filter, orderby, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiCompanies(select?: string, expand?: string, company?: Company, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiCompanies(select, expand, company, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Company} [company] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiCompaniesId(id: string, select?: string, expand?: string, company?: Company, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiCompaniesId(id, select, expand, company, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {CompaniesApiDeleteApiCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiCompaniesId(requestParameters: CompaniesApiDeleteApiCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.deleteApiCompaniesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompanies(requestParameters: CompaniesApiGetApiCompaniesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Company>> {
            return localVarFp.getApiCompanies(requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesAccountnumberAccountNumberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesAccountnumberAccountNumber(requestParameters: CompaniesApiGetApiCompaniesAccountnumberAccountNumberRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.getApiCompaniesAccountnumberAccountNumber(requestParameters.accountNumber, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesCompanyIdHierarchyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesCompanyIdHierarchy(requestParameters: CompaniesApiGetApiCompaniesCompanyIdHierarchyRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyWeb> {
            return localVarFp.getApiCompaniesCompanyIdHierarchy(requestParameters.companyId, requestParameters.$include, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint is only used by chilean companies which can have associated companies when they take over other companies  It assumes that sites from purchased companies already exist on the parent company, and has an association set to its old company.
         * @param {CompaniesApiGetApiCompaniesCompanyIdSmaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesCompanyIdSma(requestParameters: CompaniesApiGetApiCompaniesCompanyIdSmaRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CompanyWebSMA>> {
            return localVarFp.getApiCompaniesCompanyIdSma(requestParameters.companyId, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesEntitiesAreaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesEntitiesArea(requestParameters: CompaniesApiGetApiCompaniesEntitiesAreaRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CompanyWeb>> {
            return localVarFp.getApiCompaniesEntitiesArea(requestParameters.area, requestParameters.level, requestParameters.includeDeleted, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesEntitiesSimpleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesEntitiesSimple(requestParameters: CompaniesApiGetApiCompaniesEntitiesSimpleRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SimpleCompanyWeb>> {
            return localVarFp.getApiCompaniesEntitiesSimple(requestParameters.companyId, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesId(requestParameters: CompaniesApiGetApiCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyWeb> {
            return localVarFp.getApiCompaniesId(requestParameters.id, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesIdAccountnumberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesIdAccountnumber(requestParameters: CompaniesApiGetApiCompaniesIdAccountnumberRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getApiCompaniesIdAccountnumber(requestParameters.id, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesSelfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesSelf(requestParameters: CompaniesApiGetApiCompaniesSelfRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CompanySiteBriefInfo> {
            return localVarFp.getApiCompaniesSelf(requestParameters.$include, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiGetApiCompaniesSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesSites(requestParameters: CompaniesApiGetApiCompaniesSitesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Company>> {
            return localVarFp.getApiCompaniesSites(requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiPostApiCompaniesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiCompanies(requestParameters: CompaniesApiPostApiCompaniesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.postApiCompanies(requestParameters.select, requestParameters.expand, requestParameters.company, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesApiPutApiCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiCompaniesId(requestParameters: CompaniesApiPutApiCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiCompaniesId(requestParameters.id, requestParameters.select, requestParameters.expand, requestParameters.company, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - interface
 * @export
 * @interface CompaniesApi
 */
export interface CompaniesApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    deleteApiCompaniesId(id: string, options?: AxiosRequestConfig): AxiosPromise<Company>;

    /**
     * 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {string} [filter] 
     * @param {string} [orderby] 
     * @param {boolean} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompanies(select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Company>>;

    /**
     * 
     * @param {string} accountNumber 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesAccountnumberAccountNumber(accountNumber: string, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<Company>;

    /**
     * 
     * @param {string} companyId 
     * @param {string} [$include] 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesCompanyIdHierarchy(companyId: string, $include?: string, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<CompanyWeb>;

    /**
     * 
     * @summary This endpoint is only used by chilean companies which can have associated companies when they take over other companies  It assumes that sites from purchased companies already exist on the parent company, and has an association set to its old company.
     * @param {string} companyId 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {string} [filter] 
     * @param {string} [orderby] 
     * @param {boolean} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesCompanyIdSma(companyId: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<CompanyWebSMA>>;

    /**
     * 
     * @param {string} area 
     * @param {string} [level] 
     * @param {boolean} [includeDeleted] 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {string} [filter] 
     * @param {string} [orderby] 
     * @param {boolean} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesEntitiesArea(area: string, level?: string, includeDeleted?: boolean, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<CompanyWeb>>;

    /**
     * 
     * @param {string} [companyId] 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {string} [filter] 
     * @param {string} [orderby] 
     * @param {boolean} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesEntitiesSimple(companyId?: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<SimpleCompanyWeb>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesId(id: string, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<CompanyWeb>;

    /**
     * 
     * @param {string} id 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {string} [filter] 
     * @param {string} [orderby] 
     * @param {boolean} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesIdAccountnumber(id: string, select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} [$include] 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesSelf($include?: string, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<CompanySiteBriefInfo>;

    /**
     * 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {string} [filter] 
     * @param {string} [orderby] 
     * @param {boolean} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getApiCompaniesSites(select?: string, expand?: string, filter?: string, orderby?: string, count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Company>>;

    /**
     * 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Company} [company] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    postApiCompanies(select?: string, expand?: string, company?: Company, options?: AxiosRequestConfig): AxiosPromise<Company>;

    /**
     * 
     * @param {string} id 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Company} [company] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    putApiCompaniesId(id: string, select?: string, expand?: string, company?: Company, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiCompaniesId operation in CompaniesApi.
 * @export
 * @interface CompaniesApiDeleteApiCompaniesIdRequest
 */
export interface CompaniesApiDeleteApiCompaniesIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiDeleteApiCompaniesId
     */
    readonly id: string
}

/**
 * Request parameters for getApiCompanies operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesRequest
 */
export interface CompaniesApiGetApiCompaniesRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompanies
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompanies
     */
    readonly expand?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompanies
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompanies
     */
    readonly orderby?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompanies
     */
    readonly count?: boolean
}

/**
 * Request parameters for getApiCompaniesAccountnumberAccountNumber operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesAccountnumberAccountNumberRequest
 */
export interface CompaniesApiGetApiCompaniesAccountnumberAccountNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesAccountnumberAccountNumber
     */
    readonly accountNumber: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesAccountnumberAccountNumber
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesAccountnumberAccountNumber
     */
    readonly expand?: string
}

/**
 * Request parameters for getApiCompaniesCompanyIdHierarchy operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesCompanyIdHierarchyRequest
 */
export interface CompaniesApiGetApiCompaniesCompanyIdHierarchyRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdHierarchy
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdHierarchy
     */
    readonly $include?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdHierarchy
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdHierarchy
     */
    readonly expand?: string
}

/**
 * Request parameters for getApiCompaniesCompanyIdSma operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesCompanyIdSmaRequest
 */
export interface CompaniesApiGetApiCompaniesCompanyIdSmaRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdSma
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdSma
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdSma
     */
    readonly expand?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdSma
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdSma
     */
    readonly orderby?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompaniesCompanyIdSma
     */
    readonly count?: boolean
}

/**
 * Request parameters for getApiCompaniesEntitiesArea operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesEntitiesAreaRequest
 */
export interface CompaniesApiGetApiCompaniesEntitiesAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly area: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly level?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly expand?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly orderby?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompaniesEntitiesArea
     */
    readonly count?: boolean
}

/**
 * Request parameters for getApiCompaniesEntitiesSimple operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesEntitiesSimpleRequest
 */
export interface CompaniesApiGetApiCompaniesEntitiesSimpleRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesSimple
     */
    readonly companyId?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesSimple
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesSimple
     */
    readonly expand?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesSimple
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesEntitiesSimple
     */
    readonly orderby?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompaniesEntitiesSimple
     */
    readonly count?: boolean
}

/**
 * Request parameters for getApiCompaniesId operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesIdRequest
 */
export interface CompaniesApiGetApiCompaniesIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesId
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesId
     */
    readonly expand?: string
}

/**
 * Request parameters for getApiCompaniesIdAccountnumber operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesIdAccountnumberRequest
 */
export interface CompaniesApiGetApiCompaniesIdAccountnumberRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesIdAccountnumber
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesIdAccountnumber
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesIdAccountnumber
     */
    readonly expand?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesIdAccountnumber
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesIdAccountnumber
     */
    readonly orderby?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompaniesIdAccountnumber
     */
    readonly count?: boolean
}

/**
 * Request parameters for getApiCompaniesSelf operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesSelfRequest
 */
export interface CompaniesApiGetApiCompaniesSelfRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSelf
     */
    readonly $include?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSelf
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSelf
     */
    readonly expand?: string
}

/**
 * Request parameters for getApiCompaniesSites operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetApiCompaniesSitesRequest
 */
export interface CompaniesApiGetApiCompaniesSitesRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSites
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSites
     */
    readonly expand?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSites
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiGetApiCompaniesSites
     */
    readonly orderby?: string

    /**
     * 
     * @type {boolean}
     * @memberof CompaniesApiGetApiCompaniesSites
     */
    readonly count?: boolean
}

/**
 * Request parameters for postApiCompanies operation in CompaniesApi.
 * @export
 * @interface CompaniesApiPostApiCompaniesRequest
 */
export interface CompaniesApiPostApiCompaniesRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiPostApiCompanies
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiPostApiCompanies
     */
    readonly expand?: string

    /**
     * 
     * @type {Company}
     * @memberof CompaniesApiPostApiCompanies
     */
    readonly company?: Company
}

/**
 * Request parameters for putApiCompaniesId operation in CompaniesApi.
 * @export
 * @interface CompaniesApiPutApiCompaniesIdRequest
 */
export interface CompaniesApiPutApiCompaniesIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesApiPutApiCompaniesId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiPutApiCompaniesId
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesApiPutApiCompaniesId
     */
    readonly expand?: string

    /**
     * 
     * @type {Company}
     * @memberof CompaniesApiPutApiCompaniesId
     */
    readonly company?: Company
}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI implements CompaniesApiInterface {
    /**
     * 
     * @param {CompaniesApiDeleteApiCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteApiCompaniesId(requestParameters: CompaniesApiDeleteApiCompaniesIdRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteApiCompaniesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompanies(requestParameters: CompaniesApiGetApiCompaniesRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompanies(requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesAccountnumberAccountNumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesAccountnumberAccountNumber(requestParameters: CompaniesApiGetApiCompaniesAccountnumberAccountNumberRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesAccountnumberAccountNumber(requestParameters.accountNumber, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesCompanyIdHierarchyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesCompanyIdHierarchy(requestParameters: CompaniesApiGetApiCompaniesCompanyIdHierarchyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesCompanyIdHierarchy(requestParameters.companyId, requestParameters.$include, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint is only used by chilean companies which can have associated companies when they take over other companies  It assumes that sites from purchased companies already exist on the parent company, and has an association set to its old company.
     * @param {CompaniesApiGetApiCompaniesCompanyIdSmaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesCompanyIdSma(requestParameters: CompaniesApiGetApiCompaniesCompanyIdSmaRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesCompanyIdSma(requestParameters.companyId, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesEntitiesAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesEntitiesArea(requestParameters: CompaniesApiGetApiCompaniesEntitiesAreaRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesEntitiesArea(requestParameters.area, requestParameters.level, requestParameters.includeDeleted, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesEntitiesSimpleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesEntitiesSimple(requestParameters: CompaniesApiGetApiCompaniesEntitiesSimpleRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesEntitiesSimple(requestParameters.companyId, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesId(requestParameters: CompaniesApiGetApiCompaniesIdRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesId(requestParameters.id, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesIdAccountnumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesIdAccountnumber(requestParameters: CompaniesApiGetApiCompaniesIdAccountnumberRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesIdAccountnumber(requestParameters.id, requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesSelfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesSelf(requestParameters: CompaniesApiGetApiCompaniesSelfRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesSelf(requestParameters.$include, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiGetApiCompaniesSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getApiCompaniesSites(requestParameters: CompaniesApiGetApiCompaniesSitesRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getApiCompaniesSites(requestParameters.select, requestParameters.expand, requestParameters.filter, requestParameters.orderby, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiPostApiCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public postApiCompanies(requestParameters: CompaniesApiPostApiCompaniesRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).postApiCompanies(requestParameters.select, requestParameters.expand, requestParameters.company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesApiPutApiCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public putApiCompaniesId(requestParameters: CompaniesApiPutApiCompaniesIdRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).putApiCompaniesId(requestParameters.id, requestParameters.select, requestParameters.expand, requestParameters.company, options).then((request) => request(this.axios, this.basePath));
    }
}
