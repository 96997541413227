// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UnitGroup } from '../model';
/**
 * UnitGroupApi - axios parameter creator
 * @export
 */
export const UnitGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesSiteIdUnitgroupsId: async (siteId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteApiSitesSiteIdUnitgroupsId', 'siteId', siteId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiSitesSiteIdUnitgroupsId', 'id', id)
            const localVarPath = `/api/sites/{siteId}/unitgroups/{id}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [includeUnit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitgroups: async (siteId: number, includeUnit?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnitgroups', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/unitgroups`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeUnit !== undefined) {
                localVarQueryParameter['includeUnit'] = includeUnit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {UnitGroup} [unitGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnitgroups: async (siteId: number, unitGroup?: UnitGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postApiSitesSiteIdUnitgroups', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/unitgroups`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Array<UnitGroup>} [unitGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnitgroupsAll: async (siteId: number, unitGroup?: Array<UnitGroup>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postApiSitesSiteIdUnitgroupsAll', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/unitgroups/all`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {UnitGroup} [unitGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesSiteIdUnitgroupsId: async (siteId: number, id: number, unitGroup?: UnitGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('putApiSitesSiteIdUnitgroupsId', 'siteId', siteId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiSitesSiteIdUnitgroupsId', 'id', id)
            const localVarPath = `/api/sites/{siteId}/unitgroups/{id}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitGroupApi - functional programming interface
 * @export
 */
export const UnitGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiSitesSiteIdUnitgroupsId(siteId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiSitesSiteIdUnitgroupsId(siteId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [includeUnit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdUnitgroups(siteId: number, includeUnit?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdUnitgroups(siteId, includeUnit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {UnitGroup} [unitGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSitesSiteIdUnitgroups(siteId: number, unitGroup?: UnitGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSitesSiteIdUnitgroups(siteId, unitGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Array<UnitGroup>} [unitGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSitesSiteIdUnitgroupsAll(siteId: number, unitGroup?: Array<UnitGroup>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSitesSiteIdUnitgroupsAll(siteId, unitGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {UnitGroup} [unitGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiSitesSiteIdUnitgroupsId(siteId: number, id: number, unitGroup?: UnitGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiSitesSiteIdUnitgroupsId(siteId, id, unitGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitGroupApi - factory interface
 * @export
 */
export const UnitGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {UnitGroupApiDeleteApiSitesSiteIdUnitgroupsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesSiteIdUnitgroupsId(requestParameters: UnitGroupApiDeleteApiSitesSiteIdUnitgroupsIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiSitesSiteIdUnitgroupsId(requestParameters.siteId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitGroupApiGetApiSitesSiteIdUnitgroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitgroups(requestParameters: UnitGroupApiGetApiSitesSiteIdUnitgroupsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UnitGroup>> {
            return localVarFp.getApiSitesSiteIdUnitgroups(requestParameters.siteId, requestParameters.includeUnit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitGroupApiPostApiSitesSiteIdUnitgroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnitgroups(requestParameters: UnitGroupApiPostApiSitesSiteIdUnitgroupsRequest, options?: AxiosRequestConfig): AxiosPromise<UnitGroup> {
            return localVarFp.postApiSitesSiteIdUnitgroups(requestParameters.siteId, requestParameters.unitGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitGroupApiPostApiSitesSiteIdUnitgroupsAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnitgroupsAll(requestParameters: UnitGroupApiPostApiSitesSiteIdUnitgroupsAllRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postApiSitesSiteIdUnitgroupsAll(requestParameters.siteId, requestParameters.unitGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitGroupApiPutApiSitesSiteIdUnitgroupsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesSiteIdUnitgroupsId(requestParameters: UnitGroupApiPutApiSitesSiteIdUnitgroupsIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiSitesSiteIdUnitgroupsId(requestParameters.siteId, requestParameters.id, requestParameters.unitGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitGroupApi - interface
 * @export
 * @interface UnitGroupApi
 */
export interface UnitGroupApiInterface {
    /**
     * 
     * @param {number} siteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApiInterface
     */
    deleteApiSitesSiteIdUnitgroupsId(siteId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} siteId 
     * @param {boolean} [includeUnit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApiInterface
     */
    getApiSitesSiteIdUnitgroups(siteId: number, includeUnit?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<UnitGroup>>;

    /**
     * 
     * @param {number} siteId 
     * @param {UnitGroup} [unitGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApiInterface
     */
    postApiSitesSiteIdUnitgroups(siteId: number, unitGroup?: UnitGroup, options?: AxiosRequestConfig): AxiosPromise<UnitGroup>;

    /**
     * 
     * @param {number} siteId 
     * @param {Array<UnitGroup>} [unitGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApiInterface
     */
    postApiSitesSiteIdUnitgroupsAll(siteId: number, unitGroup?: Array<UnitGroup>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} id 
     * @param {UnitGroup} [unitGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApiInterface
     */
    putApiSitesSiteIdUnitgroupsId(siteId: number, id: number, unitGroup?: UnitGroup, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiSitesSiteIdUnitgroupsId operation in UnitGroupApi.
 * @export
 * @interface UnitGroupApiDeleteApiSitesSiteIdUnitgroupsIdRequest
 */
export interface UnitGroupApiDeleteApiSitesSiteIdUnitgroupsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiDeleteApiSitesSiteIdUnitgroupsId
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiDeleteApiSitesSiteIdUnitgroupsId
     */
    readonly id: number
}

/**
 * Request parameters for getApiSitesSiteIdUnitgroups operation in UnitGroupApi.
 * @export
 * @interface UnitGroupApiGetApiSitesSiteIdUnitgroupsRequest
 */
export interface UnitGroupApiGetApiSitesSiteIdUnitgroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiGetApiSitesSiteIdUnitgroups
     */
    readonly siteId: number

    /**
     * 
     * @type {boolean}
     * @memberof UnitGroupApiGetApiSitesSiteIdUnitgroups
     */
    readonly includeUnit?: boolean
}

/**
 * Request parameters for postApiSitesSiteIdUnitgroups operation in UnitGroupApi.
 * @export
 * @interface UnitGroupApiPostApiSitesSiteIdUnitgroupsRequest
 */
export interface UnitGroupApiPostApiSitesSiteIdUnitgroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiPostApiSitesSiteIdUnitgroups
     */
    readonly siteId: number

    /**
     * 
     * @type {UnitGroup}
     * @memberof UnitGroupApiPostApiSitesSiteIdUnitgroups
     */
    readonly unitGroup?: UnitGroup
}

/**
 * Request parameters for postApiSitesSiteIdUnitgroupsAll operation in UnitGroupApi.
 * @export
 * @interface UnitGroupApiPostApiSitesSiteIdUnitgroupsAllRequest
 */
export interface UnitGroupApiPostApiSitesSiteIdUnitgroupsAllRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiPostApiSitesSiteIdUnitgroupsAll
     */
    readonly siteId: number

    /**
     * 
     * @type {Array<UnitGroup>}
     * @memberof UnitGroupApiPostApiSitesSiteIdUnitgroupsAll
     */
    readonly unitGroup?: Array<UnitGroup>
}

/**
 * Request parameters for putApiSitesSiteIdUnitgroupsId operation in UnitGroupApi.
 * @export
 * @interface UnitGroupApiPutApiSitesSiteIdUnitgroupsIdRequest
 */
export interface UnitGroupApiPutApiSitesSiteIdUnitgroupsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiPutApiSitesSiteIdUnitgroupsId
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof UnitGroupApiPutApiSitesSiteIdUnitgroupsId
     */
    readonly id: number

    /**
     * 
     * @type {UnitGroup}
     * @memberof UnitGroupApiPutApiSitesSiteIdUnitgroupsId
     */
    readonly unitGroup?: UnitGroup
}

/**
 * UnitGroupApi - object-oriented interface
 * @export
 * @class UnitGroupApi
 * @extends {BaseAPI}
 */
export class UnitGroupApi extends BaseAPI implements UnitGroupApiInterface {
    /**
     * 
     * @param {UnitGroupApiDeleteApiSitesSiteIdUnitgroupsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApi
     */
    public deleteApiSitesSiteIdUnitgroupsId(requestParameters: UnitGroupApiDeleteApiSitesSiteIdUnitgroupsIdRequest, options?: AxiosRequestConfig) {
        return UnitGroupApiFp(this.configuration).deleteApiSitesSiteIdUnitgroupsId(requestParameters.siteId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitGroupApiGetApiSitesSiteIdUnitgroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApi
     */
    public getApiSitesSiteIdUnitgroups(requestParameters: UnitGroupApiGetApiSitesSiteIdUnitgroupsRequest, options?: AxiosRequestConfig) {
        return UnitGroupApiFp(this.configuration).getApiSitesSiteIdUnitgroups(requestParameters.siteId, requestParameters.includeUnit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitGroupApiPostApiSitesSiteIdUnitgroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApi
     */
    public postApiSitesSiteIdUnitgroups(requestParameters: UnitGroupApiPostApiSitesSiteIdUnitgroupsRequest, options?: AxiosRequestConfig) {
        return UnitGroupApiFp(this.configuration).postApiSitesSiteIdUnitgroups(requestParameters.siteId, requestParameters.unitGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitGroupApiPostApiSitesSiteIdUnitgroupsAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApi
     */
    public postApiSitesSiteIdUnitgroupsAll(requestParameters: UnitGroupApiPostApiSitesSiteIdUnitgroupsAllRequest, options?: AxiosRequestConfig) {
        return UnitGroupApiFp(this.configuration).postApiSitesSiteIdUnitgroupsAll(requestParameters.siteId, requestParameters.unitGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitGroupApiPutApiSitesSiteIdUnitgroupsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitGroupApi
     */
    public putApiSitesSiteIdUnitgroupsId(requestParameters: UnitGroupApiPutApiSitesSiteIdUnitgroupsIdRequest, options?: AxiosRequestConfig) {
        return UnitGroupApiFp(this.configuration).putApiSitesSiteIdUnitgroupsId(requestParameters.siteId, requestParameters.id, requestParameters.unitGroup, options).then((request) => request(this.axios, this.basePath));
    }
}
