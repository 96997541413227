// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedCompany } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * FeedCompaniesApi - axios parameter creator
 * @export
 */
export const FeedCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedCompaniesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiFeedCompaniesId', 'id', id)
            const localVarPath = `/api/FeedCompanies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedCompaniesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiFeedCompaniesId', 'id', id)
            const localVarPath = `/api/FeedCompanies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiFeedCompaniesId: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchApiFeedCompaniesId', 'id', id)
            const localVarPath = `/api/FeedCompanies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<FeedCompany>} [feedCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedCompanies: async (feedCompany?: Array<FeedCompany>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedCompany} [feedCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedCompaniesId: async (id: number, feedCompany?: FeedCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiFeedCompaniesId', 'id', id)
            const localVarPath = `/api/FeedCompanies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedCompaniesApi - functional programming interface
 * @export
 */
export const FeedCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiFeedCompaniesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiFeedCompaniesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedCompaniesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedCompaniesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiFeedCompaniesId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiFeedCompaniesId(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<FeedCompany>} [feedCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiFeedCompanies(feedCompany?: Array<FeedCompany>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiFeedCompanies(feedCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedCompany} [feedCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiFeedCompaniesId(id: number, feedCompany?: FeedCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiFeedCompaniesId(id, feedCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedCompaniesApi - factory interface
 * @export
 */
export const FeedCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedCompaniesApiDeleteApiFeedCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedCompaniesId(requestParameters: FeedCompaniesApiDeleteApiFeedCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedCompany> {
            return localVarFp.deleteApiFeedCompaniesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<FeedCompany>> {
            return localVarFp.getApiFeedCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedCompaniesApiGetApiFeedCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedCompaniesId(requestParameters: FeedCompaniesApiGetApiFeedCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedCompany> {
            return localVarFp.getApiFeedCompaniesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedCompaniesApiPatchApiFeedCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiFeedCompaniesId(requestParameters: FeedCompaniesApiPatchApiFeedCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.patchApiFeedCompaniesId(requestParameters.id, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedCompaniesApiPostApiFeedCompaniesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedCompanies(requestParameters: FeedCompaniesApiPostApiFeedCompaniesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedCompany> {
            return localVarFp.postApiFeedCompanies(requestParameters.feedCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedCompaniesApiPutApiFeedCompaniesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedCompaniesId(requestParameters: FeedCompaniesApiPutApiFeedCompaniesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedCompany> {
            return localVarFp.putApiFeedCompaniesId(requestParameters.id, requestParameters.feedCompany, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedCompaniesApi - interface
 * @export
 * @interface FeedCompaniesApi
 */
export interface FeedCompaniesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApiInterface
     */
    deleteApiFeedCompaniesId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedCompany>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApiInterface
     */
    getApiFeedCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<FeedCompany>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApiInterface
     */
    getApiFeedCompaniesId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedCompany>;

    /**
     * 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApiInterface
     */
    patchApiFeedCompaniesId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {Array<FeedCompany>} [feedCompany] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApiInterface
     */
    postApiFeedCompanies(feedCompany?: Array<FeedCompany>, options?: AxiosRequestConfig): AxiosPromise<FeedCompany>;

    /**
     * 
     * @param {number} id 
     * @param {FeedCompany} [feedCompany] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApiInterface
     */
    putApiFeedCompaniesId(id: number, feedCompany?: FeedCompany, options?: AxiosRequestConfig): AxiosPromise<FeedCompany>;

}

/**
 * Request parameters for deleteApiFeedCompaniesId operation in FeedCompaniesApi.
 * @export
 * @interface FeedCompaniesApiDeleteApiFeedCompaniesIdRequest
 */
export interface FeedCompaniesApiDeleteApiFeedCompaniesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedCompaniesApiDeleteApiFeedCompaniesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiFeedCompaniesId operation in FeedCompaniesApi.
 * @export
 * @interface FeedCompaniesApiGetApiFeedCompaniesIdRequest
 */
export interface FeedCompaniesApiGetApiFeedCompaniesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedCompaniesApiGetApiFeedCompaniesId
     */
    readonly id: number
}

/**
 * Request parameters for patchApiFeedCompaniesId operation in FeedCompaniesApi.
 * @export
 * @interface FeedCompaniesApiPatchApiFeedCompaniesIdRequest
 */
export interface FeedCompaniesApiPatchApiFeedCompaniesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedCompaniesApiPatchApiFeedCompaniesId
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedCompaniesApiPatchApiFeedCompaniesId
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiFeedCompanies operation in FeedCompaniesApi.
 * @export
 * @interface FeedCompaniesApiPostApiFeedCompaniesRequest
 */
export interface FeedCompaniesApiPostApiFeedCompaniesRequest {
    /**
     * 
     * @type {Array<FeedCompany>}
     * @memberof FeedCompaniesApiPostApiFeedCompanies
     */
    readonly feedCompany?: Array<FeedCompany>
}

/**
 * Request parameters for putApiFeedCompaniesId operation in FeedCompaniesApi.
 * @export
 * @interface FeedCompaniesApiPutApiFeedCompaniesIdRequest
 */
export interface FeedCompaniesApiPutApiFeedCompaniesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedCompaniesApiPutApiFeedCompaniesId
     */
    readonly id: number

    /**
     * 
     * @type {FeedCompany}
     * @memberof FeedCompaniesApiPutApiFeedCompaniesId
     */
    readonly feedCompany?: FeedCompany
}

/**
 * FeedCompaniesApi - object-oriented interface
 * @export
 * @class FeedCompaniesApi
 * @extends {BaseAPI}
 */
export class FeedCompaniesApi extends BaseAPI implements FeedCompaniesApiInterface {
    /**
     * 
     * @param {FeedCompaniesApiDeleteApiFeedCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApi
     */
    public deleteApiFeedCompaniesId(requestParameters: FeedCompaniesApiDeleteApiFeedCompaniesIdRequest, options?: AxiosRequestConfig) {
        return FeedCompaniesApiFp(this.configuration).deleteApiFeedCompaniesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApi
     */
    public getApiFeedCompanies(options?: AxiosRequestConfig) {
        return FeedCompaniesApiFp(this.configuration).getApiFeedCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedCompaniesApiGetApiFeedCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApi
     */
    public getApiFeedCompaniesId(requestParameters: FeedCompaniesApiGetApiFeedCompaniesIdRequest, options?: AxiosRequestConfig) {
        return FeedCompaniesApiFp(this.configuration).getApiFeedCompaniesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedCompaniesApiPatchApiFeedCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApi
     */
    public patchApiFeedCompaniesId(requestParameters: FeedCompaniesApiPatchApiFeedCompaniesIdRequest, options?: AxiosRequestConfig) {
        return FeedCompaniesApiFp(this.configuration).patchApiFeedCompaniesId(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedCompaniesApiPostApiFeedCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApi
     */
    public postApiFeedCompanies(requestParameters: FeedCompaniesApiPostApiFeedCompaniesRequest = {}, options?: AxiosRequestConfig) {
        return FeedCompaniesApiFp(this.configuration).postApiFeedCompanies(requestParameters.feedCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedCompaniesApiPutApiFeedCompaniesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedCompaniesApi
     */
    public putApiFeedCompaniesId(requestParameters: FeedCompaniesApiPutApiFeedCompaniesIdRequest, options?: AxiosRequestConfig) {
        return FeedCompaniesApiFp(this.configuration).putApiFeedCompaniesId(requestParameters.id, requestParameters.feedCompany, options).then((request) => request(this.axios, this.basePath));
    }
}
