// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PutStationLicenseBillingGroupRequestWeb } from '../model';
// @ts-ignore
import { StationLicenseBillingGroupWeb } from '../model';
/**
 * StationLicenseBillingGroupsApi - axios parameter creator
 * @export
 */
export const StationLicenseBillingGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicenseBillingGroupsSiteIdApplicationId: async (siteId: number, applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiStationLicenseBillingGroupsSiteIdApplicationId', 'siteId', siteId)
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApiStationLicenseBillingGroupsSiteIdApplicationId', 'applicationId', applicationId)
            const localVarPath = `/api/StationLicenseBillingGroups/{siteId}/{applicationId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {string} applicationId 
         * @param {PutStationLicenseBillingGroupRequestWeb} [putStationLicenseBillingGroupRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiStationLicenseBillingGroupsSiteIdApplicationId: async (siteId: number, applicationId: string, putStationLicenseBillingGroupRequestWeb?: PutStationLicenseBillingGroupRequestWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('putApiStationLicenseBillingGroupsSiteIdApplicationId', 'siteId', siteId)
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('putApiStationLicenseBillingGroupsSiteIdApplicationId', 'applicationId', applicationId)
            const localVarPath = `/api/StationLicenseBillingGroups/{siteId}/{applicationId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putStationLicenseBillingGroupRequestWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StationLicenseBillingGroupsApi - functional programming interface
 * @export
 */
export const StationLicenseBillingGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StationLicenseBillingGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiStationLicenseBillingGroupsSiteIdApplicationId(siteId: number, applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicenseBillingGroupWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiStationLicenseBillingGroupsSiteIdApplicationId(siteId, applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {string} applicationId 
         * @param {PutStationLicenseBillingGroupRequestWeb} [putStationLicenseBillingGroupRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiStationLicenseBillingGroupsSiteIdApplicationId(siteId: number, applicationId: string, putStationLicenseBillingGroupRequestWeb?: PutStationLicenseBillingGroupRequestWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicenseBillingGroupWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiStationLicenseBillingGroupsSiteIdApplicationId(siteId, applicationId, putStationLicenseBillingGroupRequestWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StationLicenseBillingGroupsApi - factory interface
 * @export
 */
export const StationLicenseBillingGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StationLicenseBillingGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters: StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationIdRequest, options?: AxiosRequestConfig): AxiosPromise<StationLicenseBillingGroupWeb> {
            return localVarFp.getApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters.siteId, requestParameters.applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters: StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationIdRequest, options?: AxiosRequestConfig): AxiosPromise<StationLicenseBillingGroupWeb> {
            return localVarFp.putApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters.siteId, requestParameters.applicationId, requestParameters.putStationLicenseBillingGroupRequestWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StationLicenseBillingGroupsApi - interface
 * @export
 * @interface StationLicenseBillingGroupsApi
 */
export interface StationLicenseBillingGroupsApiInterface {
    /**
     * 
     * @param {number} siteId 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicenseBillingGroupsApiInterface
     */
    getApiStationLicenseBillingGroupsSiteIdApplicationId(siteId: number, applicationId: string, options?: AxiosRequestConfig): AxiosPromise<StationLicenseBillingGroupWeb>;

    /**
     * 
     * @param {number} siteId 
     * @param {string} applicationId 
     * @param {PutStationLicenseBillingGroupRequestWeb} [putStationLicenseBillingGroupRequestWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicenseBillingGroupsApiInterface
     */
    putApiStationLicenseBillingGroupsSiteIdApplicationId(siteId: number, applicationId: string, putStationLicenseBillingGroupRequestWeb?: PutStationLicenseBillingGroupRequestWeb, options?: AxiosRequestConfig): AxiosPromise<StationLicenseBillingGroupWeb>;

}

/**
 * Request parameters for getApiStationLicenseBillingGroupsSiteIdApplicationId operation in StationLicenseBillingGroupsApi.
 * @export
 * @interface StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationIdRequest
 */
export interface StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationIdRequest {
    /**
     * 
     * @type {number}
     * @memberof StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationId
     */
    readonly siteId: number

    /**
     * 
     * @type {string}
     * @memberof StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationId
     */
    readonly applicationId: string
}

/**
 * Request parameters for putApiStationLicenseBillingGroupsSiteIdApplicationId operation in StationLicenseBillingGroupsApi.
 * @export
 * @interface StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationIdRequest
 */
export interface StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationIdRequest {
    /**
     * 
     * @type {number}
     * @memberof StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationId
     */
    readonly siteId: number

    /**
     * 
     * @type {string}
     * @memberof StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationId
     */
    readonly applicationId: string

    /**
     * 
     * @type {PutStationLicenseBillingGroupRequestWeb}
     * @memberof StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationId
     */
    readonly putStationLicenseBillingGroupRequestWeb?: PutStationLicenseBillingGroupRequestWeb
}

/**
 * StationLicenseBillingGroupsApi - object-oriented interface
 * @export
 * @class StationLicenseBillingGroupsApi
 * @extends {BaseAPI}
 */
export class StationLicenseBillingGroupsApi extends BaseAPI implements StationLicenseBillingGroupsApiInterface {
    /**
     * 
     * @param {StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicenseBillingGroupsApi
     */
    public getApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters: StationLicenseBillingGroupsApiGetApiStationLicenseBillingGroupsSiteIdApplicationIdRequest, options?: AxiosRequestConfig) {
        return StationLicenseBillingGroupsApiFp(this.configuration).getApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters.siteId, requestParameters.applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicenseBillingGroupsApi
     */
    public putApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters: StationLicenseBillingGroupsApiPutApiStationLicenseBillingGroupsSiteIdApplicationIdRequest, options?: AxiosRequestConfig) {
        return StationLicenseBillingGroupsApiFp(this.configuration).putApiStationLicenseBillingGroupsSiteIdApplicationId(requestParameters.siteId, requestParameters.applicationId, requestParameters.putStationLicenseBillingGroupRequestWeb, options).then((request) => request(this.axios, this.basePath));
    }
}
