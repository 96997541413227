// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Silo } from '../model';
// @ts-ignore
import { SiloContentOrder } from '../model';
/**
 * SilosApi - axios parameter creator
 * @export
 */
export const SilosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesSiteIdSilosId: async (siteId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteApiSitesSiteIdSilosId', 'siteId', siteId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiSitesSiteIdSilosId', 'id', id)
            const localVarPath = `/api/sites/{siteId}/silos/{id}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSilocontentorders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/silocontentorders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSilos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/silos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdSilos: async (siteId: number, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdSilos', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/silos`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {string} [siloName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdSilosAvailable: async (siteId: number, siloName?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdSilosAvailable', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/silos/available`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (siloName !== undefined) {
                localVarQueryParameter['siloName'] = siloName;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} siloId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdSilosSiloId: async (siteId: number, siloId: number, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdSilosSiloId', 'siteId', siteId)
            // verify required parameter 'siloId' is not null or undefined
            assertParamExists('getApiSitesSiteIdSilosSiloId', 'siloId', siloId)
            const localVarPath = `/api/sites/{siteId}/silos/{siloId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"siloId"}}`, encodeURIComponent(String(siloId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdSilos: async (siteId: number, silo?: Silo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postApiSitesSiteIdSilos', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/silos`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Array<Silo>} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdSilosAll: async (siteId: number, silo?: Array<Silo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postApiSitesSiteIdSilosAll', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/silos/all`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesSiteIdSilosId: async (siteId: number, id: number, silo?: Silo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('putApiSitesSiteIdSilosId', 'siteId', siteId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiSitesSiteIdSilosId', 'id', id)
            const localVarPath = `/api/sites/{siteId}/silos/{id}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SilosApi - functional programming interface
 * @export
 */
export const SilosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SilosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiSitesSiteIdSilosId(siteId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiSitesSiteIdSilosId(siteId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSilocontentorders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiloContentOrder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSilocontentorders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSilos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Silo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSilos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdSilos(siteId: number, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Silo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdSilos(siteId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {string} [siloName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdSilosAvailable(siteId: number, siloName?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdSilosAvailable(siteId, siloName, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} siloId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdSilosSiloId(siteId: number, siloId: number, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdSilosSiloId(siteId, siloId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSitesSiteIdSilos(siteId: number, silo?: Silo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSitesSiteIdSilos(siteId, silo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Array<Silo>} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSitesSiteIdSilosAll(siteId: number, silo?: Array<Silo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSitesSiteIdSilosAll(siteId, silo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} id 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiSitesSiteIdSilosId(siteId: number, id: number, silo?: Silo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiSitesSiteIdSilosId(siteId, id, silo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SilosApi - factory interface
 * @export
 */
export const SilosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SilosApiFp(configuration)
    return {
        /**
         * 
         * @param {SilosApiDeleteApiSitesSiteIdSilosIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesSiteIdSilosId(requestParameters: SilosApiDeleteApiSitesSiteIdSilosIdRequest, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.deleteApiSitesSiteIdSilosId(requestParameters.siteId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSilocontentorders(options?: AxiosRequestConfig): AxiosPromise<Array<SiloContentOrder>> {
            return localVarFp.getApiSilocontentorders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSilos(options?: AxiosRequestConfig): AxiosPromise<Array<Silo>> {
            return localVarFp.getApiSilos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiGetApiSitesSiteIdSilosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdSilos(requestParameters: SilosApiGetApiSitesSiteIdSilosRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Silo>> {
            return localVarFp.getApiSitesSiteIdSilos(requestParameters.siteId, requestParameters.includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiGetApiSitesSiteIdSilosAvailableRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdSilosAvailable(requestParameters: SilosApiGetApiSitesSiteIdSilosAvailableRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.getApiSitesSiteIdSilosAvailable(requestParameters.siteId, requestParameters.siloName, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiGetApiSitesSiteIdSilosSiloIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdSilosSiloId(requestParameters: SilosApiGetApiSitesSiteIdSilosSiloIdRequest, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.getApiSitesSiteIdSilosSiloId(requestParameters.siteId, requestParameters.siloId, requestParameters.includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPostApiSitesSiteIdSilosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdSilos(requestParameters: SilosApiPostApiSitesSiteIdSilosRequest, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.postApiSitesSiteIdSilos(requestParameters.siteId, requestParameters.silo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPostApiSitesSiteIdSilosAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdSilosAll(requestParameters: SilosApiPostApiSitesSiteIdSilosAllRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postApiSitesSiteIdSilosAll(requestParameters.siteId, requestParameters.silo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPutApiSitesSiteIdSilosIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesSiteIdSilosId(requestParameters: SilosApiPutApiSitesSiteIdSilosIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiSitesSiteIdSilosId(requestParameters.siteId, requestParameters.id, requestParameters.silo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SilosApi - interface
 * @export
 * @interface SilosApi
 */
export interface SilosApiInterface {
    /**
     * 
     * @param {number} siteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    deleteApiSitesSiteIdSilosId(siteId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiSilocontentorders(options?: AxiosRequestConfig): AxiosPromise<Array<SiloContentOrder>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiSilos(options?: AxiosRequestConfig): AxiosPromise<Array<Silo>>;

    /**
     * 
     * @param {number} siteId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiSitesSiteIdSilos(siteId: number, includeDeleted?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Silo>>;

    /**
     * 
     * @param {number} siteId 
     * @param {string} [siloName] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiSitesSiteIdSilosAvailable(siteId: number, siloName?: string, type?: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} siloId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiSitesSiteIdSilosSiloId(siteId: number, siloId: number, includeDeleted?: boolean, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} siteId 
     * @param {Silo} [silo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    postApiSitesSiteIdSilos(siteId: number, silo?: Silo, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} siteId 
     * @param {Array<Silo>} [silo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    postApiSitesSiteIdSilosAll(siteId: number, silo?: Array<Silo>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} id 
     * @param {Silo} [silo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    putApiSitesSiteIdSilosId(siteId: number, id: number, silo?: Silo, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiSitesSiteIdSilosId operation in SilosApi.
 * @export
 * @interface SilosApiDeleteApiSitesSiteIdSilosIdRequest
 */
export interface SilosApiDeleteApiSitesSiteIdSilosIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiDeleteApiSitesSiteIdSilosId
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof SilosApiDeleteApiSitesSiteIdSilosId
     */
    readonly id: number
}

/**
 * Request parameters for getApiSitesSiteIdSilos operation in SilosApi.
 * @export
 * @interface SilosApiGetApiSitesSiteIdSilosRequest
 */
export interface SilosApiGetApiSitesSiteIdSilosRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiGetApiSitesSiteIdSilos
     */
    readonly siteId: number

    /**
     * 
     * @type {boolean}
     * @memberof SilosApiGetApiSitesSiteIdSilos
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for getApiSitesSiteIdSilosAvailable operation in SilosApi.
 * @export
 * @interface SilosApiGetApiSitesSiteIdSilosAvailableRequest
 */
export interface SilosApiGetApiSitesSiteIdSilosAvailableRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiGetApiSitesSiteIdSilosAvailable
     */
    readonly siteId: number

    /**
     * 
     * @type {string}
     * @memberof SilosApiGetApiSitesSiteIdSilosAvailable
     */
    readonly siloName?: string

    /**
     * 
     * @type {string}
     * @memberof SilosApiGetApiSitesSiteIdSilosAvailable
     */
    readonly type?: string
}

/**
 * Request parameters for getApiSitesSiteIdSilosSiloId operation in SilosApi.
 * @export
 * @interface SilosApiGetApiSitesSiteIdSilosSiloIdRequest
 */
export interface SilosApiGetApiSitesSiteIdSilosSiloIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiGetApiSitesSiteIdSilosSiloId
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof SilosApiGetApiSitesSiteIdSilosSiloId
     */
    readonly siloId: number

    /**
     * 
     * @type {boolean}
     * @memberof SilosApiGetApiSitesSiteIdSilosSiloId
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for postApiSitesSiteIdSilos operation in SilosApi.
 * @export
 * @interface SilosApiPostApiSitesSiteIdSilosRequest
 */
export interface SilosApiPostApiSitesSiteIdSilosRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPostApiSitesSiteIdSilos
     */
    readonly siteId: number

    /**
     * 
     * @type {Silo}
     * @memberof SilosApiPostApiSitesSiteIdSilos
     */
    readonly silo?: Silo
}

/**
 * Request parameters for postApiSitesSiteIdSilosAll operation in SilosApi.
 * @export
 * @interface SilosApiPostApiSitesSiteIdSilosAllRequest
 */
export interface SilosApiPostApiSitesSiteIdSilosAllRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPostApiSitesSiteIdSilosAll
     */
    readonly siteId: number

    /**
     * 
     * @type {Array<Silo>}
     * @memberof SilosApiPostApiSitesSiteIdSilosAll
     */
    readonly silo?: Array<Silo>
}

/**
 * Request parameters for putApiSitesSiteIdSilosId operation in SilosApi.
 * @export
 * @interface SilosApiPutApiSitesSiteIdSilosIdRequest
 */
export interface SilosApiPutApiSitesSiteIdSilosIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPutApiSitesSiteIdSilosId
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof SilosApiPutApiSitesSiteIdSilosId
     */
    readonly id: number

    /**
     * 
     * @type {Silo}
     * @memberof SilosApiPutApiSitesSiteIdSilosId
     */
    readonly silo?: Silo
}

/**
 * SilosApi - object-oriented interface
 * @export
 * @class SilosApi
 * @extends {BaseAPI}
 */
export class SilosApi extends BaseAPI implements SilosApiInterface {
    /**
     * 
     * @param {SilosApiDeleteApiSitesSiteIdSilosIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public deleteApiSitesSiteIdSilosId(requestParameters: SilosApiDeleteApiSitesSiteIdSilosIdRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).deleteApiSitesSiteIdSilosId(requestParameters.siteId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiSilocontentorders(options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiSilocontentorders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiSilos(options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiSilos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiGetApiSitesSiteIdSilosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiSitesSiteIdSilos(requestParameters: SilosApiGetApiSitesSiteIdSilosRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiSitesSiteIdSilos(requestParameters.siteId, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiGetApiSitesSiteIdSilosAvailableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiSitesSiteIdSilosAvailable(requestParameters: SilosApiGetApiSitesSiteIdSilosAvailableRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiSitesSiteIdSilosAvailable(requestParameters.siteId, requestParameters.siloName, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiGetApiSitesSiteIdSilosSiloIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiSitesSiteIdSilosSiloId(requestParameters: SilosApiGetApiSitesSiteIdSilosSiloIdRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiSitesSiteIdSilosSiloId(requestParameters.siteId, requestParameters.siloId, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPostApiSitesSiteIdSilosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public postApiSitesSiteIdSilos(requestParameters: SilosApiPostApiSitesSiteIdSilosRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).postApiSitesSiteIdSilos(requestParameters.siteId, requestParameters.silo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPostApiSitesSiteIdSilosAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public postApiSitesSiteIdSilosAll(requestParameters: SilosApiPostApiSitesSiteIdSilosAllRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).postApiSitesSiteIdSilosAll(requestParameters.siteId, requestParameters.silo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPutApiSitesSiteIdSilosIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public putApiSitesSiteIdSilosId(requestParameters: SilosApiPutApiSitesSiteIdSilosIdRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).putApiSitesSiteIdSilosId(requestParameters.siteId, requestParameters.id, requestParameters.silo, options).then((request) => request(this.axios, this.basePath));
    }
}
