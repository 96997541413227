// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuditWeb } from '../model';
// @ts-ignore
import { Unit } from '../model';
// @ts-ignore
import { UnitModelWeb } from '../model';
// @ts-ignore
import { UnitShape } from '../model';
/**
 * UnitsApi - axios parameter creator
 * @export
 */
export const UnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesSiteIdUnitsId: async (id: number, siteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiSitesSiteIdUnitsId', 'id', id)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteApiSitesSiteIdUnitsId', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnits: async (siteId: number, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnits', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/units`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {string} [unitName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitsAvailable: async (siteId: number, unitName?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnitsAvailable', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/units/available`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (unitName !== undefined) {
                localVarQueryParameter['unitName'] = unitName;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitsUnitId: async (siteId: number, unitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnitsUnitId', 'siteId', siteId)
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnitsUnitId', 'unitId', unitId)
            const localVarPath = `/api/sites/{siteId}/units/{unitId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitsUnitIdHistory: async (siteId: number, unitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnitsUnitIdHistory', 'siteId', siteId)
            // verify required parameter 'unitId' is not null or undefined
            assertParamExists('getApiSitesSiteIdUnitsUnitIdHistory', 'unitId', unitId)
            const localVarPath = `/api/sites/{siteId}/units/{unitId}/history`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUnits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUnitsModels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/units/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUnitsShapes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/units/shapes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Unit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnits: async (siteId: number, unit?: Unit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postApiSitesSiteIdUnits', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/units`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [keepValueWhenNull] 
         * @param {Array<Unit>} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnitsAll: async (siteId: number, keepValueWhenNull?: boolean, unit?: Array<Unit>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postApiSitesSiteIdUnitsAll', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/units/all`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keepValueWhenNull !== undefined) {
                localVarQueryParameter['keepValueWhenNull'] = keepValueWhenNull;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} siteId 
         * @param {Unit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesSiteIdUnitsId: async (id: number, siteId: string, unit?: Unit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiSitesSiteIdUnitsId', 'id', id)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('putApiSitesSiteIdUnitsId', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}/units/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitsApi - functional programming interface
 * @export
 */
export const UnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiSitesSiteIdUnitsId(id: number, siteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiSitesSiteIdUnitsId(id, siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdUnits(siteId: number, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Unit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdUnits(siteId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {string} [unitName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdUnitsAvailable(siteId: number, unitName?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdUnitsAvailable(siteId, unitName, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdUnitsUnitId(siteId: number, unitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdUnitsUnitId(siteId, unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSiteIdUnitsUnitIdHistory(siteId: number, unitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSiteIdUnitsUnitIdHistory(siteId, unitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUnits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Unit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUnits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUnitsModels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitModelWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUnitsModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUnitsShapes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnitShape>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUnitsShapes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {Unit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSitesSiteIdUnits(siteId: number, unit?: Unit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSitesSiteIdUnits(siteId, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {boolean} [keepValueWhenNull] 
         * @param {Array<Unit>} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSitesSiteIdUnitsAll(siteId: number, keepValueWhenNull?: boolean, unit?: Array<Unit>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Unit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSitesSiteIdUnitsAll(siteId, keepValueWhenNull, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} siteId 
         * @param {Unit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiSitesSiteIdUnitsId(id: number, siteId: string, unit?: Unit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiSitesSiteIdUnitsId(id, siteId, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitsApi - factory interface
 * @export
 */
export const UnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitsApiFp(configuration)
    return {
        /**
         * 
         * @param {UnitsApiDeleteApiSitesSiteIdUnitsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesSiteIdUnitsId(requestParameters: UnitsApiDeleteApiSitesSiteIdUnitsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Unit> {
            return localVarFp.deleteApiSitesSiteIdUnitsId(requestParameters.id, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiGetApiSitesSiteIdUnitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnits(requestParameters: UnitsApiGetApiSitesSiteIdUnitsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Unit>> {
            return localVarFp.getApiSitesSiteIdUnits(requestParameters.siteId, requestParameters.includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiGetApiSitesSiteIdUnitsAvailableRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitsAvailable(requestParameters: UnitsApiGetApiSitesSiteIdUnitsAvailableRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.getApiSitesSiteIdUnitsAvailable(requestParameters.siteId, requestParameters.unitName, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiGetApiSitesSiteIdUnitsUnitIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitsUnitId(requestParameters: UnitsApiGetApiSitesSiteIdUnitsUnitIdRequest, options?: AxiosRequestConfig): AxiosPromise<Unit> {
            return localVarFp.getApiSitesSiteIdUnitsUnitId(requestParameters.siteId, requestParameters.unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiGetApiSitesSiteIdUnitsUnitIdHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSiteIdUnitsUnitIdHistory(requestParameters: UnitsApiGetApiSitesSiteIdUnitsUnitIdHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AuditWeb>> {
            return localVarFp.getApiSitesSiteIdUnitsUnitIdHistory(requestParameters.siteId, requestParameters.unitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUnits(options?: AxiosRequestConfig): AxiosPromise<Array<Unit>> {
            return localVarFp.getApiUnits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUnitsModels(options?: AxiosRequestConfig): AxiosPromise<Array<UnitModelWeb>> {
            return localVarFp.getApiUnitsModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUnitsShapes(options?: AxiosRequestConfig): AxiosPromise<Array<UnitShape>> {
            return localVarFp.getApiUnitsShapes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiPostApiSitesSiteIdUnitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnits(requestParameters: UnitsApiPostApiSitesSiteIdUnitsRequest, options?: AxiosRequestConfig): AxiosPromise<Unit> {
            return localVarFp.postApiSitesSiteIdUnits(requestParameters.siteId, requestParameters.unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiPostApiSitesSiteIdUnitsAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSitesSiteIdUnitsAll(requestParameters: UnitsApiPostApiSitesSiteIdUnitsAllRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Unit>> {
            return localVarFp.postApiSitesSiteIdUnitsAll(requestParameters.siteId, requestParameters.keepValueWhenNull, requestParameters.unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnitsApiPutApiSitesSiteIdUnitsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesSiteIdUnitsId(requestParameters: UnitsApiPutApiSitesSiteIdUnitsIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiSitesSiteIdUnitsId(requestParameters.id, requestParameters.siteId, requestParameters.unit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitsApi - interface
 * @export
 * @interface UnitsApi
 */
export interface UnitsApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {string} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    deleteApiSitesSiteIdUnitsId(id: number, siteId: string, options?: AxiosRequestConfig): AxiosPromise<Unit>;

    /**
     * 
     * @param {number} siteId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiSitesSiteIdUnits(siteId: number, includeDeleted?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Unit>>;

    /**
     * 
     * @param {number} siteId 
     * @param {string} [unitName] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiSitesSiteIdUnitsAvailable(siteId: number, unitName?: string, type?: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiSitesSiteIdUnitsUnitId(siteId: number, unitId: number, options?: AxiosRequestConfig): AxiosPromise<Unit>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiSitesSiteIdUnitsUnitIdHistory(siteId: number, unitId: number, options?: AxiosRequestConfig): AxiosPromise<Array<AuditWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiUnits(options?: AxiosRequestConfig): AxiosPromise<Array<Unit>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiUnitsModels(options?: AxiosRequestConfig): AxiosPromise<Array<UnitModelWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    getApiUnitsShapes(options?: AxiosRequestConfig): AxiosPromise<Array<UnitShape>>;

    /**
     * 
     * @param {number} siteId 
     * @param {Unit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    postApiSitesSiteIdUnits(siteId: number, unit?: Unit, options?: AxiosRequestConfig): AxiosPromise<Unit>;

    /**
     * 
     * @param {number} siteId 
     * @param {boolean} [keepValueWhenNull] 
     * @param {Array<Unit>} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    postApiSitesSiteIdUnitsAll(siteId: number, keepValueWhenNull?: boolean, unit?: Array<Unit>, options?: AxiosRequestConfig): AxiosPromise<Array<Unit>>;

    /**
     * 
     * @param {number} id 
     * @param {string} siteId 
     * @param {Unit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApiInterface
     */
    putApiSitesSiteIdUnitsId(id: number, siteId: string, unit?: Unit, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiSitesSiteIdUnitsId operation in UnitsApi.
 * @export
 * @interface UnitsApiDeleteApiSitesSiteIdUnitsIdRequest
 */
export interface UnitsApiDeleteApiSitesSiteIdUnitsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiDeleteApiSitesSiteIdUnitsId
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof UnitsApiDeleteApiSitesSiteIdUnitsId
     */
    readonly siteId: string
}

/**
 * Request parameters for getApiSitesSiteIdUnits operation in UnitsApi.
 * @export
 * @interface UnitsApiGetApiSitesSiteIdUnitsRequest
 */
export interface UnitsApiGetApiSitesSiteIdUnitsRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiGetApiSitesSiteIdUnits
     */
    readonly siteId: number

    /**
     * 
     * @type {boolean}
     * @memberof UnitsApiGetApiSitesSiteIdUnits
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for getApiSitesSiteIdUnitsAvailable operation in UnitsApi.
 * @export
 * @interface UnitsApiGetApiSitesSiteIdUnitsAvailableRequest
 */
export interface UnitsApiGetApiSitesSiteIdUnitsAvailableRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsAvailable
     */
    readonly siteId: number

    /**
     * 
     * @type {string}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsAvailable
     */
    readonly unitName?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsAvailable
     */
    readonly type?: string
}

/**
 * Request parameters for getApiSitesSiteIdUnitsUnitId operation in UnitsApi.
 * @export
 * @interface UnitsApiGetApiSitesSiteIdUnitsUnitIdRequest
 */
export interface UnitsApiGetApiSitesSiteIdUnitsUnitIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsUnitId
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsUnitId
     */
    readonly unitId: number
}

/**
 * Request parameters for getApiSitesSiteIdUnitsUnitIdHistory operation in UnitsApi.
 * @export
 * @interface UnitsApiGetApiSitesSiteIdUnitsUnitIdHistoryRequest
 */
export interface UnitsApiGetApiSitesSiteIdUnitsUnitIdHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsUnitIdHistory
     */
    readonly siteId: number

    /**
     * 
     * @type {number}
     * @memberof UnitsApiGetApiSitesSiteIdUnitsUnitIdHistory
     */
    readonly unitId: number
}

/**
 * Request parameters for postApiSitesSiteIdUnits operation in UnitsApi.
 * @export
 * @interface UnitsApiPostApiSitesSiteIdUnitsRequest
 */
export interface UnitsApiPostApiSitesSiteIdUnitsRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiPostApiSitesSiteIdUnits
     */
    readonly siteId: number

    /**
     * 
     * @type {Unit}
     * @memberof UnitsApiPostApiSitesSiteIdUnits
     */
    readonly unit?: Unit
}

/**
 * Request parameters for postApiSitesSiteIdUnitsAll operation in UnitsApi.
 * @export
 * @interface UnitsApiPostApiSitesSiteIdUnitsAllRequest
 */
export interface UnitsApiPostApiSitesSiteIdUnitsAllRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiPostApiSitesSiteIdUnitsAll
     */
    readonly siteId: number

    /**
     * 
     * @type {boolean}
     * @memberof UnitsApiPostApiSitesSiteIdUnitsAll
     */
    readonly keepValueWhenNull?: boolean

    /**
     * 
     * @type {Array<Unit>}
     * @memberof UnitsApiPostApiSitesSiteIdUnitsAll
     */
    readonly unit?: Array<Unit>
}

/**
 * Request parameters for putApiSitesSiteIdUnitsId operation in UnitsApi.
 * @export
 * @interface UnitsApiPutApiSitesSiteIdUnitsIdRequest
 */
export interface UnitsApiPutApiSitesSiteIdUnitsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsApiPutApiSitesSiteIdUnitsId
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof UnitsApiPutApiSitesSiteIdUnitsId
     */
    readonly siteId: string

    /**
     * 
     * @type {Unit}
     * @memberof UnitsApiPutApiSitesSiteIdUnitsId
     */
    readonly unit?: Unit
}

/**
 * UnitsApi - object-oriented interface
 * @export
 * @class UnitsApi
 * @extends {BaseAPI}
 */
export class UnitsApi extends BaseAPI implements UnitsApiInterface {
    /**
     * 
     * @param {UnitsApiDeleteApiSitesSiteIdUnitsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public deleteApiSitesSiteIdUnitsId(requestParameters: UnitsApiDeleteApiSitesSiteIdUnitsIdRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).deleteApiSitesSiteIdUnitsId(requestParameters.id, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiGetApiSitesSiteIdUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiSitesSiteIdUnits(requestParameters: UnitsApiGetApiSitesSiteIdUnitsRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiSitesSiteIdUnits(requestParameters.siteId, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiGetApiSitesSiteIdUnitsAvailableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiSitesSiteIdUnitsAvailable(requestParameters: UnitsApiGetApiSitesSiteIdUnitsAvailableRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiSitesSiteIdUnitsAvailable(requestParameters.siteId, requestParameters.unitName, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiGetApiSitesSiteIdUnitsUnitIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiSitesSiteIdUnitsUnitId(requestParameters: UnitsApiGetApiSitesSiteIdUnitsUnitIdRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiSitesSiteIdUnitsUnitId(requestParameters.siteId, requestParameters.unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiGetApiSitesSiteIdUnitsUnitIdHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiSitesSiteIdUnitsUnitIdHistory(requestParameters: UnitsApiGetApiSitesSiteIdUnitsUnitIdHistoryRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiSitesSiteIdUnitsUnitIdHistory(requestParameters.siteId, requestParameters.unitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiUnits(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiUnits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiUnitsModels(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiUnitsModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public getApiUnitsShapes(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).getApiUnitsShapes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiPostApiSitesSiteIdUnitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public postApiSitesSiteIdUnits(requestParameters: UnitsApiPostApiSitesSiteIdUnitsRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).postApiSitesSiteIdUnits(requestParameters.siteId, requestParameters.unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiPostApiSitesSiteIdUnitsAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public postApiSitesSiteIdUnitsAll(requestParameters: UnitsApiPostApiSitesSiteIdUnitsAllRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).postApiSitesSiteIdUnitsAll(requestParameters.siteId, requestParameters.keepValueWhenNull, requestParameters.unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnitsApiPutApiSitesSiteIdUnitsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public putApiSitesSiteIdUnitsId(requestParameters: UnitsApiPutApiSitesSiteIdUnitsIdRequest, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).putApiSitesSiteIdUnitsId(requestParameters.id, requestParameters.siteId, requestParameters.unit, options).then((request) => request(this.axios, this.basePath));
    }
}
