// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * BargesApi - axios parameter creator
 * @export
 */
export const BargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiBargesAvailableCompanyId: async (companyId: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiBargesAvailableCompanyId', 'companyId', companyId)
            const localVarPath = `/api/Barges/available/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BargesApi - functional programming interface
 * @export
 */
export const BargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiBargesAvailableCompanyId(companyId: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiBargesAvailableCompanyId(companyId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BargesApi - factory interface
 * @export
 */
export const BargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BargesApiFp(configuration)
    return {
        /**
         * 
         * @param {BargesApiGetApiBargesAvailableCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiBargesAvailableCompanyId(requestParameters: BargesApiGetApiBargesAvailableCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.getApiBargesAvailableCompanyId(requestParameters.companyId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BargesApi - interface
 * @export
 * @interface BargesApi
 */
export interface BargesApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    getApiBargesAvailableCompanyId(companyId: string, name?: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * Request parameters for getApiBargesAvailableCompanyId operation in BargesApi.
 * @export
 * @interface BargesApiGetApiBargesAvailableCompanyIdRequest
 */
export interface BargesApiGetApiBargesAvailableCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof BargesApiGetApiBargesAvailableCompanyId
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiGetApiBargesAvailableCompanyId
     */
    readonly name?: string
}

/**
 * BargesApi - object-oriented interface
 * @export
 * @class BargesApi
 * @extends {BaseAPI}
 */
export class BargesApi extends BaseAPI implements BargesApiInterface {
    /**
     * 
     * @param {BargesApiGetApiBargesAvailableCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public getApiBargesAvailableCompanyId(requestParameters: BargesApiGetApiBargesAvailableCompanyIdRequest, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).getApiBargesAvailableCompanyId(requestParameters.companyId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }
}
