// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MortalityCategory } from '../model';
// @ts-ignore
import { MortalityCategoryResource } from '../model';
/**
 * MortalityCategoriesApi - axios parameter creator
 * @export
 */
export const MortalityCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityCategoryId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMortalityCategoryId', 'id', id)
            const localVarPath = `/api/mortality/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityCategory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityCategoryId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiMortalityCategoryId', 'id', id)
            const localVarPath = `/api/mortality/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortalityCategoryResource} [mortalityCategoryResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortalityCategory: async (mortalityCategoryResource?: MortalityCategoryResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityCategoryResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityCategoryResource} [mortalityCategoryResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityCategoryId: async (id: number, mortalityCategoryResource?: MortalityCategoryResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMortalityCategoryId', 'id', id)
            const localVarPath = `/api/mortality/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityCategoryResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortalityCategoriesApi - functional programming interface
 * @export
 */
export const MortalityCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortalityCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMortalityCategoryId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMortalityCategoryId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityCategory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MortalityCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityCategory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityCategoryId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityCategoryId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MortalityCategoryResource} [mortalityCategoryResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMortalityCategory(mortalityCategoryResource?: MortalityCategoryResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMortalityCategory(mortalityCategoryResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityCategoryResource} [mortalityCategoryResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMortalityCategoryId(id: number, mortalityCategoryResource?: MortalityCategoryResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMortalityCategoryId(id, mortalityCategoryResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortalityCategoriesApi - factory interface
 * @export
 */
export const MortalityCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortalityCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {MortalityCategoriesApiDeleteApiMortalityCategoryIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityCategoryId(requestParameters: MortalityCategoriesApiDeleteApiMortalityCategoryIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiMortalityCategoryId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityCategory(options?: AxiosRequestConfig): AxiosPromise<Array<MortalityCategory>> {
            return localVarFp.getApiMortalityCategory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityCategoriesApiGetApiMortalityCategoryIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityCategoryId(requestParameters: MortalityCategoriesApiGetApiMortalityCategoryIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityCategory> {
            return localVarFp.getApiMortalityCategoryId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityCategoriesApiPostApiMortalityCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortalityCategory(requestParameters: MortalityCategoriesApiPostApiMortalityCategoryRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MortalityCategory> {
            return localVarFp.postApiMortalityCategory(requestParameters.mortalityCategoryResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityCategoriesApiPutApiMortalityCategoryIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityCategoryId(requestParameters: MortalityCategoriesApiPutApiMortalityCategoryIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityCategory> {
            return localVarFp.putApiMortalityCategoryId(requestParameters.id, requestParameters.mortalityCategoryResource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortalityCategoriesApi - interface
 * @export
 * @interface MortalityCategoriesApi
 */
export interface MortalityCategoriesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApiInterface
     */
    deleteApiMortalityCategoryId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApiInterface
     */
    getApiMortalityCategory(options?: AxiosRequestConfig): AxiosPromise<Array<MortalityCategory>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApiInterface
     */
    getApiMortalityCategoryId(id: number, options?: AxiosRequestConfig): AxiosPromise<MortalityCategory>;

    /**
     * 
     * @param {MortalityCategoryResource} [mortalityCategoryResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApiInterface
     */
    postApiMortalityCategory(mortalityCategoryResource?: MortalityCategoryResource, options?: AxiosRequestConfig): AxiosPromise<MortalityCategory>;

    /**
     * 
     * @param {number} id 
     * @param {MortalityCategoryResource} [mortalityCategoryResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApiInterface
     */
    putApiMortalityCategoryId(id: number, mortalityCategoryResource?: MortalityCategoryResource, options?: AxiosRequestConfig): AxiosPromise<MortalityCategory>;

}

/**
 * Request parameters for deleteApiMortalityCategoryId operation in MortalityCategoriesApi.
 * @export
 * @interface MortalityCategoriesApiDeleteApiMortalityCategoryIdRequest
 */
export interface MortalityCategoriesApiDeleteApiMortalityCategoryIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityCategoriesApiDeleteApiMortalityCategoryId
     */
    readonly id: number
}

/**
 * Request parameters for getApiMortalityCategoryId operation in MortalityCategoriesApi.
 * @export
 * @interface MortalityCategoriesApiGetApiMortalityCategoryIdRequest
 */
export interface MortalityCategoriesApiGetApiMortalityCategoryIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityCategoriesApiGetApiMortalityCategoryId
     */
    readonly id: number
}

/**
 * Request parameters for postApiMortalityCategory operation in MortalityCategoriesApi.
 * @export
 * @interface MortalityCategoriesApiPostApiMortalityCategoryRequest
 */
export interface MortalityCategoriesApiPostApiMortalityCategoryRequest {
    /**
     * 
     * @type {MortalityCategoryResource}
     * @memberof MortalityCategoriesApiPostApiMortalityCategory
     */
    readonly mortalityCategoryResource?: MortalityCategoryResource
}

/**
 * Request parameters for putApiMortalityCategoryId operation in MortalityCategoriesApi.
 * @export
 * @interface MortalityCategoriesApiPutApiMortalityCategoryIdRequest
 */
export interface MortalityCategoriesApiPutApiMortalityCategoryIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityCategoriesApiPutApiMortalityCategoryId
     */
    readonly id: number

    /**
     * 
     * @type {MortalityCategoryResource}
     * @memberof MortalityCategoriesApiPutApiMortalityCategoryId
     */
    readonly mortalityCategoryResource?: MortalityCategoryResource
}

/**
 * MortalityCategoriesApi - object-oriented interface
 * @export
 * @class MortalityCategoriesApi
 * @extends {BaseAPI}
 */
export class MortalityCategoriesApi extends BaseAPI implements MortalityCategoriesApiInterface {
    /**
     * 
     * @param {MortalityCategoriesApiDeleteApiMortalityCategoryIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApi
     */
    public deleteApiMortalityCategoryId(requestParameters: MortalityCategoriesApiDeleteApiMortalityCategoryIdRequest, options?: AxiosRequestConfig) {
        return MortalityCategoriesApiFp(this.configuration).deleteApiMortalityCategoryId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApi
     */
    public getApiMortalityCategory(options?: AxiosRequestConfig) {
        return MortalityCategoriesApiFp(this.configuration).getApiMortalityCategory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityCategoriesApiGetApiMortalityCategoryIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApi
     */
    public getApiMortalityCategoryId(requestParameters: MortalityCategoriesApiGetApiMortalityCategoryIdRequest, options?: AxiosRequestConfig) {
        return MortalityCategoriesApiFp(this.configuration).getApiMortalityCategoryId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityCategoriesApiPostApiMortalityCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApi
     */
    public postApiMortalityCategory(requestParameters: MortalityCategoriesApiPostApiMortalityCategoryRequest = {}, options?: AxiosRequestConfig) {
        return MortalityCategoriesApiFp(this.configuration).postApiMortalityCategory(requestParameters.mortalityCategoryResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityCategoriesApiPutApiMortalityCategoryIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityCategoriesApi
     */
    public putApiMortalityCategoryId(requestParameters: MortalityCategoriesApiPutApiMortalityCategoryIdRequest, options?: AxiosRequestConfig) {
        return MortalityCategoriesApiFp(this.configuration).putApiMortalityCategoryId(requestParameters.id, requestParameters.mortalityCategoryResource, options).then((request) => request(this.axios, this.basePath));
    }
}
