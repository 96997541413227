// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const LicenseServerAuthCodes = {
    FOUND_HARDWARE_NO_CUSTOMER_NAME_IN_DATE: 'FOUND_HARDWARE_NO_CUSTOMER_NAME_IN_DATE',
    FOUND_HARDWARE_NO_CUSTOMER_NAME_EXPIRED: 'FOUND_HARDWARE_NO_CUSTOMER_NAME_EXPIRED',
    FOUND_HARDWARE_IN_DATE: 'FOUND_HARDWARE_IN_DATE',
    FOUND_HARDWARE_ONE_YEAR_LICENSE_RENEWED: 'FOUND_HARDWARE_ONE_YEAR_LICENSE_RENEWED',
    HARDWARE_NOT_FOUND_REGISTERED_TEMP_LICENSE: 'HARDWARE_NOT_FOUND_REGISTERED_TEMP_LICENSE',
    FOUND_HARDWARE_DISABLED: 'FOUND_HARDWARE_DISABLED',
    FOUND_HARDWARE_TEMP_LICENSE: 'FOUND_HARDWARE_TEMP_LICENSE',
    FOUND_HARDWARE_RENEWED_IN_DATE: 'FOUND_HARDWARE_RENEWED_IN_DATE',
    TEMPORARY_LICENSE_EXPIRED: 'TEMPORARY_LICENSE_EXPIRED',
    CONVERTED_FROM_V1_TO_V2_ONE_YEAR_LICENSE: 'CONVERTED_FROM_V1_TO_V2_ONE_YEAR_LICENSE',
    FOUND_HARDWARE_ONE_MONTH_LICENSE: 'FOUND_HARDWARE_ONE_MONTH_LICENSE',
    FOUND_HARDWARE_ONE_MONTH_RENEWED: 'FOUND_HARDWARE_ONE_MONTH_RENEWED',
    UNABLE_TO_DETERMINE_LICENSE_TYPE: 'UNABLE_TO_DETERMINE_LICENSE_TYPE',
    TEMP_LICENSE_REJECTED: 'TEMP_LICENSE_REJECTED',
    FOUND_HARDWARE_THREE_YEARS_LICENSE_RENEWED: 'FOUND_HARDWARE_THREE_YEARS_LICENSE_RENEWED'
} as const;

export type LicenseServerAuthCodes = typeof LicenseServerAuthCodes[keyof typeof LicenseServerAuthCodes];



