// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MortalityType } from '../model';
// @ts-ignore
import { MortalityTypeResource } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * MortalityTypesApi - axios parameter creator
 * @export
 */
export const MortalityTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityTypeId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMortalityTypeId', 'id', id)
            const localVarPath = `/api/mortality/type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityType: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityTypeByCompanyCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiMortalityTypeByCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/api/mortality/type/byCompany/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityTypeId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiMortalityTypeId', 'id', id)
            const localVarPath = `/api/mortality/type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiMortalityTypeId: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchApiMortalityTypeId', 'id', id)
            const localVarPath = `/api/mortality/type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortalityTypeResource} [mortalityTypeResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortalityType: async (mortalityTypeResource?: MortalityTypeResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityTypeResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityTypeResource} [mortalityTypeResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityTypeId: async (id: number, mortalityTypeResource?: MortalityTypeResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMortalityTypeId', 'id', id)
            const localVarPath = `/api/mortality/type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityTypeResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortalityTypesApi - functional programming interface
 * @export
 */
export const MortalityTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortalityTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMortalityTypeId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMortalityTypeId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityType(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MortalityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityType(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityTypeByCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MortalityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityTypeByCompanyCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityTypeId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityTypeId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiMortalityTypeId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiMortalityTypeId(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MortalityTypeResource} [mortalityTypeResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMortalityType(mortalityTypeResource?: MortalityTypeResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMortalityType(mortalityTypeResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityTypeResource} [mortalityTypeResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMortalityTypeId(id: number, mortalityTypeResource?: MortalityTypeResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMortalityTypeId(id, mortalityTypeResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortalityTypesApi - factory interface
 * @export
 */
export const MortalityTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortalityTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {MortalityTypesApiDeleteApiMortalityTypeIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityTypeId(requestParameters: MortalityTypesApiDeleteApiMortalityTypeIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiMortalityTypeId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityType(options?: AxiosRequestConfig): AxiosPromise<Array<MortalityType>> {
            return localVarFp.getApiMortalityType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityTypesApiGetApiMortalityTypeByCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityTypeByCompanyCompanyId(requestParameters: MortalityTypesApiGetApiMortalityTypeByCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MortalityType>> {
            return localVarFp.getApiMortalityTypeByCompanyCompanyId(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityTypesApiGetApiMortalityTypeIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityTypeId(requestParameters: MortalityTypesApiGetApiMortalityTypeIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityType> {
            return localVarFp.getApiMortalityTypeId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityTypesApiPatchApiMortalityTypeIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiMortalityTypeId(requestParameters: MortalityTypesApiPatchApiMortalityTypeIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityType> {
            return localVarFp.patchApiMortalityTypeId(requestParameters.id, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityTypesApiPostApiMortalityTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortalityType(requestParameters: MortalityTypesApiPostApiMortalityTypeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MortalityType> {
            return localVarFp.postApiMortalityType(requestParameters.mortalityTypeResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityTypesApiPutApiMortalityTypeIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityTypeId(requestParameters: MortalityTypesApiPutApiMortalityTypeIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityType> {
            return localVarFp.putApiMortalityTypeId(requestParameters.id, requestParameters.mortalityTypeResource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortalityTypesApi - interface
 * @export
 * @interface MortalityTypesApi
 */
export interface MortalityTypesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    deleteApiMortalityTypeId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    getApiMortalityType(options?: AxiosRequestConfig): AxiosPromise<Array<MortalityType>>;

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    getApiMortalityTypeByCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<MortalityType>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    getApiMortalityTypeId(id: number, options?: AxiosRequestConfig): AxiosPromise<MortalityType>;

    /**
     * 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    patchApiMortalityTypeId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<MortalityType>;

    /**
     * 
     * @param {MortalityTypeResource} [mortalityTypeResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    postApiMortalityType(mortalityTypeResource?: MortalityTypeResource, options?: AxiosRequestConfig): AxiosPromise<MortalityType>;

    /**
     * 
     * @param {number} id 
     * @param {MortalityTypeResource} [mortalityTypeResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApiInterface
     */
    putApiMortalityTypeId(id: number, mortalityTypeResource?: MortalityTypeResource, options?: AxiosRequestConfig): AxiosPromise<MortalityType>;

}

/**
 * Request parameters for deleteApiMortalityTypeId operation in MortalityTypesApi.
 * @export
 * @interface MortalityTypesApiDeleteApiMortalityTypeIdRequest
 */
export interface MortalityTypesApiDeleteApiMortalityTypeIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityTypesApiDeleteApiMortalityTypeId
     */
    readonly id: number
}

/**
 * Request parameters for getApiMortalityTypeByCompanyCompanyId operation in MortalityTypesApi.
 * @export
 * @interface MortalityTypesApiGetApiMortalityTypeByCompanyCompanyIdRequest
 */
export interface MortalityTypesApiGetApiMortalityTypeByCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof MortalityTypesApiGetApiMortalityTypeByCompanyCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for getApiMortalityTypeId operation in MortalityTypesApi.
 * @export
 * @interface MortalityTypesApiGetApiMortalityTypeIdRequest
 */
export interface MortalityTypesApiGetApiMortalityTypeIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityTypesApiGetApiMortalityTypeId
     */
    readonly id: number
}

/**
 * Request parameters for patchApiMortalityTypeId operation in MortalityTypesApi.
 * @export
 * @interface MortalityTypesApiPatchApiMortalityTypeIdRequest
 */
export interface MortalityTypesApiPatchApiMortalityTypeIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityTypesApiPatchApiMortalityTypeId
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof MortalityTypesApiPatchApiMortalityTypeId
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiMortalityType operation in MortalityTypesApi.
 * @export
 * @interface MortalityTypesApiPostApiMortalityTypeRequest
 */
export interface MortalityTypesApiPostApiMortalityTypeRequest {
    /**
     * 
     * @type {MortalityTypeResource}
     * @memberof MortalityTypesApiPostApiMortalityType
     */
    readonly mortalityTypeResource?: MortalityTypeResource
}

/**
 * Request parameters for putApiMortalityTypeId operation in MortalityTypesApi.
 * @export
 * @interface MortalityTypesApiPutApiMortalityTypeIdRequest
 */
export interface MortalityTypesApiPutApiMortalityTypeIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityTypesApiPutApiMortalityTypeId
     */
    readonly id: number

    /**
     * 
     * @type {MortalityTypeResource}
     * @memberof MortalityTypesApiPutApiMortalityTypeId
     */
    readonly mortalityTypeResource?: MortalityTypeResource
}

/**
 * MortalityTypesApi - object-oriented interface
 * @export
 * @class MortalityTypesApi
 * @extends {BaseAPI}
 */
export class MortalityTypesApi extends BaseAPI implements MortalityTypesApiInterface {
    /**
     * 
     * @param {MortalityTypesApiDeleteApiMortalityTypeIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public deleteApiMortalityTypeId(requestParameters: MortalityTypesApiDeleteApiMortalityTypeIdRequest, options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).deleteApiMortalityTypeId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public getApiMortalityType(options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).getApiMortalityType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityTypesApiGetApiMortalityTypeByCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public getApiMortalityTypeByCompanyCompanyId(requestParameters: MortalityTypesApiGetApiMortalityTypeByCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).getApiMortalityTypeByCompanyCompanyId(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityTypesApiGetApiMortalityTypeIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public getApiMortalityTypeId(requestParameters: MortalityTypesApiGetApiMortalityTypeIdRequest, options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).getApiMortalityTypeId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityTypesApiPatchApiMortalityTypeIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public patchApiMortalityTypeId(requestParameters: MortalityTypesApiPatchApiMortalityTypeIdRequest, options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).patchApiMortalityTypeId(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityTypesApiPostApiMortalityTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public postApiMortalityType(requestParameters: MortalityTypesApiPostApiMortalityTypeRequest = {}, options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).postApiMortalityType(requestParameters.mortalityTypeResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityTypesApiPutApiMortalityTypeIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityTypesApi
     */
    public putApiMortalityTypeId(requestParameters: MortalityTypesApiPutApiMortalityTypeIdRequest, options?: AxiosRequestConfig) {
        return MortalityTypesApiFp(this.configuration).putApiMortalityTypeId(requestParameters.id, requestParameters.mortalityTypeResource, options).then((request) => request(this.axios, this.basePath));
    }
}
