// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdditionalDetailsProperty } from '../model';
/**
 * AdditionalPropertiesApi - axios parameter creator
 * @export
 */
export const AdditionalPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiAdditionalPropertiesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiAdditionalPropertiesId', 'id', id)
            const localVarPath = `/api/AdditionalProperties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiAdditionalProperties: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdditionalProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdditionalDetailsProperty} [additionalDetailsProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAdditionalProperties: async (additionalDetailsProperty?: AdditionalDetailsProperty, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdditionalProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalDetailsProperty, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<AdditionalDetailsProperty>} [additionalDetailsProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiAdditionalProperties: async (additionalDetailsProperty?: Array<AdditionalDetailsProperty>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdditionalProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalDetailsProperty, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdditionalPropertiesApi - functional programming interface
 * @export
 */
export const AdditionalPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdditionalPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiAdditionalPropertiesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiAdditionalPropertiesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiAdditionalProperties(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdditionalDetailsProperty>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiAdditionalProperties(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdditionalDetailsProperty} [additionalDetailsProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiAdditionalProperties(additionalDetailsProperty?: AdditionalDetailsProperty, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiAdditionalProperties(additionalDetailsProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<AdditionalDetailsProperty>} [additionalDetailsProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiAdditionalProperties(additionalDetailsProperty?: Array<AdditionalDetailsProperty>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiAdditionalProperties(additionalDetailsProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdditionalPropertiesApi - factory interface
 * @export
 */
export const AdditionalPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdditionalPropertiesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdditionalPropertiesApiDeleteApiAdditionalPropertiesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiAdditionalPropertiesId(requestParameters: AdditionalPropertiesApiDeleteApiAdditionalPropertiesIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiAdditionalPropertiesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiAdditionalProperties(options?: AxiosRequestConfig): AxiosPromise<Array<AdditionalDetailsProperty>> {
            return localVarFp.getApiAdditionalProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdditionalPropertiesApiPostApiAdditionalPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAdditionalProperties(requestParameters: AdditionalPropertiesApiPostApiAdditionalPropertiesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postApiAdditionalProperties(requestParameters.additionalDetailsProperty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdditionalPropertiesApiPutApiAdditionalPropertiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiAdditionalProperties(requestParameters: AdditionalPropertiesApiPutApiAdditionalPropertiesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiAdditionalProperties(requestParameters.additionalDetailsProperty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdditionalPropertiesApi - interface
 * @export
 * @interface AdditionalPropertiesApi
 */
export interface AdditionalPropertiesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApiInterface
     */
    deleteApiAdditionalPropertiesId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApiInterface
     */
    getApiAdditionalProperties(options?: AxiosRequestConfig): AxiosPromise<Array<AdditionalDetailsProperty>>;

    /**
     * 
     * @param {AdditionalDetailsProperty} [additionalDetailsProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApiInterface
     */
    postApiAdditionalProperties(additionalDetailsProperty?: AdditionalDetailsProperty, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<AdditionalDetailsProperty>} [additionalDetailsProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApiInterface
     */
    putApiAdditionalProperties(additionalDetailsProperty?: Array<AdditionalDetailsProperty>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiAdditionalPropertiesId operation in AdditionalPropertiesApi.
 * @export
 * @interface AdditionalPropertiesApiDeleteApiAdditionalPropertiesIdRequest
 */
export interface AdditionalPropertiesApiDeleteApiAdditionalPropertiesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof AdditionalPropertiesApiDeleteApiAdditionalPropertiesId
     */
    readonly id: number
}

/**
 * Request parameters for postApiAdditionalProperties operation in AdditionalPropertiesApi.
 * @export
 * @interface AdditionalPropertiesApiPostApiAdditionalPropertiesRequest
 */
export interface AdditionalPropertiesApiPostApiAdditionalPropertiesRequest {
    /**
     * 
     * @type {AdditionalDetailsProperty}
     * @memberof AdditionalPropertiesApiPostApiAdditionalProperties
     */
    readonly additionalDetailsProperty?: AdditionalDetailsProperty
}

/**
 * Request parameters for putApiAdditionalProperties operation in AdditionalPropertiesApi.
 * @export
 * @interface AdditionalPropertiesApiPutApiAdditionalPropertiesRequest
 */
export interface AdditionalPropertiesApiPutApiAdditionalPropertiesRequest {
    /**
     * 
     * @type {Array<AdditionalDetailsProperty>}
     * @memberof AdditionalPropertiesApiPutApiAdditionalProperties
     */
    readonly additionalDetailsProperty?: Array<AdditionalDetailsProperty>
}

/**
 * AdditionalPropertiesApi - object-oriented interface
 * @export
 * @class AdditionalPropertiesApi
 * @extends {BaseAPI}
 */
export class AdditionalPropertiesApi extends BaseAPI implements AdditionalPropertiesApiInterface {
    /**
     * 
     * @param {AdditionalPropertiesApiDeleteApiAdditionalPropertiesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApi
     */
    public deleteApiAdditionalPropertiesId(requestParameters: AdditionalPropertiesApiDeleteApiAdditionalPropertiesIdRequest, options?: AxiosRequestConfig) {
        return AdditionalPropertiesApiFp(this.configuration).deleteApiAdditionalPropertiesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApi
     */
    public getApiAdditionalProperties(options?: AxiosRequestConfig) {
        return AdditionalPropertiesApiFp(this.configuration).getApiAdditionalProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdditionalPropertiesApiPostApiAdditionalPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApi
     */
    public postApiAdditionalProperties(requestParameters: AdditionalPropertiesApiPostApiAdditionalPropertiesRequest = {}, options?: AxiosRequestConfig) {
        return AdditionalPropertiesApiFp(this.configuration).postApiAdditionalProperties(requestParameters.additionalDetailsProperty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdditionalPropertiesApiPutApiAdditionalPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalPropertiesApi
     */
    public putApiAdditionalProperties(requestParameters: AdditionalPropertiesApiPutApiAdditionalPropertiesRequest = {}, options?: AxiosRequestConfig) {
        return AdditionalPropertiesApiFp(this.configuration).putApiAdditionalProperties(requestParameters.additionalDetailsProperty, options).then((request) => request(this.axios, this.basePath));
    }
}
