// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedSupplier } from '../model';
// @ts-ignore
import { FeedSupplierResource } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * FeedSuppliersApi - axios parameter creator
 * @export
 */
export const FeedSuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedSuppliersId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiFeedSuppliersId', 'id', id)
            const localVarPath = `/api/FeedSuppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedSuppliers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedSuppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedSuppliersBycompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedSuppliers/bycompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedSuppliersId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiFeedSuppliersId', 'id', id)
            const localVarPath = `/api/FeedSuppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiFeedSuppliersId: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchApiFeedSuppliersId', 'id', id)
            const localVarPath = `/api/FeedSuppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FeedSupplierResource} [feedSupplierResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedSuppliers: async (feedSupplierResource?: FeedSupplierResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedSuppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedSupplierResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedSupplierResource} [feedSupplierResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedSuppliersId: async (id: number, feedSupplierResource?: FeedSupplierResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiFeedSuppliersId', 'id', id)
            const localVarPath = `/api/FeedSuppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedSupplierResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedSuppliersApi - functional programming interface
 * @export
 */
export const FeedSuppliersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedSuppliersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiFeedSuppliersId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiFeedSuppliersId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedSuppliers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedSupplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedSuppliers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedSuppliersBycompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedSupplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedSuppliersBycompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedSuppliersId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedSuppliersId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiFeedSuppliersId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiFeedSuppliersId(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FeedSupplierResource} [feedSupplierResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiFeedSuppliers(feedSupplierResource?: FeedSupplierResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiFeedSuppliers(feedSupplierResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedSupplierResource} [feedSupplierResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiFeedSuppliersId(id: number, feedSupplierResource?: FeedSupplierResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiFeedSuppliersId(id, feedSupplierResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedSuppliersApi - factory interface
 * @export
 */
export const FeedSuppliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedSuppliersApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedSuppliersApiDeleteApiFeedSuppliersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedSuppliersId(requestParameters: FeedSuppliersApiDeleteApiFeedSuppliersIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiFeedSuppliersId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedSuppliers(options?: AxiosRequestConfig): AxiosPromise<Array<FeedSupplier>> {
            return localVarFp.getApiFeedSuppliers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedSuppliersBycompany(options?: AxiosRequestConfig): AxiosPromise<Array<FeedSupplier>> {
            return localVarFp.getApiFeedSuppliersBycompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiGetApiFeedSuppliersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedSuppliersId(requestParameters: FeedSuppliersApiGetApiFeedSuppliersIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.getApiFeedSuppliersId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPatchApiFeedSuppliersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiFeedSuppliersId(requestParameters: FeedSuppliersApiPatchApiFeedSuppliersIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.patchApiFeedSuppliersId(requestParameters.id, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPostApiFeedSuppliersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedSuppliers(requestParameters: FeedSuppliersApiPostApiFeedSuppliersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.postApiFeedSuppliers(requestParameters.feedSupplierResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPutApiFeedSuppliersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedSuppliersId(requestParameters: FeedSuppliersApiPutApiFeedSuppliersIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.putApiFeedSuppliersId(requestParameters.id, requestParameters.feedSupplierResource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedSuppliersApi - interface
 * @export
 * @interface FeedSuppliersApi
 */
export interface FeedSuppliersApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    deleteApiFeedSuppliersId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiFeedSuppliers(options?: AxiosRequestConfig): AxiosPromise<Array<FeedSupplier>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiFeedSuppliersBycompany(options?: AxiosRequestConfig): AxiosPromise<Array<FeedSupplier>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiFeedSuppliersId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    patchApiFeedSuppliersId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {FeedSupplierResource} [feedSupplierResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    postApiFeedSuppliers(feedSupplierResource?: FeedSupplierResource, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} id 
     * @param {FeedSupplierResource} [feedSupplierResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    putApiFeedSuppliersId(id: number, feedSupplierResource?: FeedSupplierResource, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

}

/**
 * Request parameters for deleteApiFeedSuppliersId operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiDeleteApiFeedSuppliersIdRequest
 */
export interface FeedSuppliersApiDeleteApiFeedSuppliersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiDeleteApiFeedSuppliersId
     */
    readonly id: number
}

/**
 * Request parameters for getApiFeedSuppliersId operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiGetApiFeedSuppliersIdRequest
 */
export interface FeedSuppliersApiGetApiFeedSuppliersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiGetApiFeedSuppliersId
     */
    readonly id: number
}

/**
 * Request parameters for patchApiFeedSuppliersId operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPatchApiFeedSuppliersIdRequest
 */
export interface FeedSuppliersApiPatchApiFeedSuppliersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiFeedSuppliersId
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedSuppliersApiPatchApiFeedSuppliersId
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiFeedSuppliers operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPostApiFeedSuppliersRequest
 */
export interface FeedSuppliersApiPostApiFeedSuppliersRequest {
    /**
     * 
     * @type {FeedSupplierResource}
     * @memberof FeedSuppliersApiPostApiFeedSuppliers
     */
    readonly feedSupplierResource?: FeedSupplierResource
}

/**
 * Request parameters for putApiFeedSuppliersId operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPutApiFeedSuppliersIdRequest
 */
export interface FeedSuppliersApiPutApiFeedSuppliersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiPutApiFeedSuppliersId
     */
    readonly id: number

    /**
     * 
     * @type {FeedSupplierResource}
     * @memberof FeedSuppliersApiPutApiFeedSuppliersId
     */
    readonly feedSupplierResource?: FeedSupplierResource
}

/**
 * FeedSuppliersApi - object-oriented interface
 * @export
 * @class FeedSuppliersApi
 * @extends {BaseAPI}
 */
export class FeedSuppliersApi extends BaseAPI implements FeedSuppliersApiInterface {
    /**
     * 
     * @param {FeedSuppliersApiDeleteApiFeedSuppliersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public deleteApiFeedSuppliersId(requestParameters: FeedSuppliersApiDeleteApiFeedSuppliersIdRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).deleteApiFeedSuppliersId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiFeedSuppliers(options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiFeedSuppliers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiFeedSuppliersBycompany(options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiFeedSuppliersBycompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiGetApiFeedSuppliersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiFeedSuppliersId(requestParameters: FeedSuppliersApiGetApiFeedSuppliersIdRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiFeedSuppliersId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPatchApiFeedSuppliersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public patchApiFeedSuppliersId(requestParameters: FeedSuppliersApiPatchApiFeedSuppliersIdRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).patchApiFeedSuppliersId(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPostApiFeedSuppliersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public postApiFeedSuppliers(requestParameters: FeedSuppliersApiPostApiFeedSuppliersRequest = {}, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).postApiFeedSuppliers(requestParameters.feedSupplierResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPutApiFeedSuppliersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public putApiFeedSuppliersId(requestParameters: FeedSuppliersApiPutApiFeedSuppliersIdRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).putApiFeedSuppliersId(requestParameters.id, requestParameters.feedSupplierResource, options).then((request) => request(this.axios, this.basePath));
    }
}
