// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Application } from '../model';
/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
export const ApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiApplicationsId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiApplicationsId', 'id', id)
            const localVarPath = `/api/Applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApplications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApplicationsId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiApplicationsId', 'id', id)
            const localVarPath = `/api/Applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Application} [application] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApplications: async (application?: Application, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(application, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Application} [application] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiApplicationsId: async (id: string, application?: Application, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiApplicationsId', 'id', id)
            const localVarPath = `/api/Applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(application, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiApplicationsId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiApplicationsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApplications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Application>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApplicationsId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApplicationsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Application} [application] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiApplications(application?: Application, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiApplications(application, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Application} [application] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiApplicationsId(id: string, application?: Application, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiApplicationsId(id, application, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationsApiDeleteApiApplicationsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiApplicationsId(requestParameters: ApplicationsApiDeleteApiApplicationsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Application> {
            return localVarFp.deleteApiApplicationsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApplications(options?: AxiosRequestConfig): AxiosPromise<Array<Application>> {
            return localVarFp.getApiApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationsApiGetApiApplicationsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApplicationsId(requestParameters: ApplicationsApiGetApiApplicationsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Application> {
            return localVarFp.getApiApplicationsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationsApiPostApiApplicationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApplications(requestParameters: ApplicationsApiPostApiApplicationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Application> {
            return localVarFp.postApiApplications(requestParameters.application, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationsApiPutApiApplicationsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiApplicationsId(requestParameters: ApplicationsApiPutApiApplicationsIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiApplicationsId(requestParameters.id, requestParameters.application, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsApi - interface
 * @export
 * @interface ApplicationsApi
 */
export interface ApplicationsApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    deleteApiApplicationsId(id: string, options?: AxiosRequestConfig): AxiosPromise<Application>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    getApiApplications(options?: AxiosRequestConfig): AxiosPromise<Array<Application>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    getApiApplicationsId(id: string, options?: AxiosRequestConfig): AxiosPromise<Application>;

    /**
     * 
     * @param {Application} [application] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    postApiApplications(application?: Application, options?: AxiosRequestConfig): AxiosPromise<Application>;

    /**
     * 
     * @param {string} id 
     * @param {Application} [application] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    putApiApplicationsId(id: string, application?: Application, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiApplicationsId operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiDeleteApiApplicationsIdRequest
 */
export interface ApplicationsApiDeleteApiApplicationsIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationsApiDeleteApiApplicationsId
     */
    readonly id: string
}

/**
 * Request parameters for getApiApplicationsId operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetApiApplicationsIdRequest
 */
export interface ApplicationsApiGetApiApplicationsIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationsApiGetApiApplicationsId
     */
    readonly id: string
}

/**
 * Request parameters for postApiApplications operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiPostApiApplicationsRequest
 */
export interface ApplicationsApiPostApiApplicationsRequest {
    /**
     * 
     * @type {Application}
     * @memberof ApplicationsApiPostApiApplications
     */
    readonly application?: Application
}

/**
 * Request parameters for putApiApplicationsId operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiPutApiApplicationsIdRequest
 */
export interface ApplicationsApiPutApiApplicationsIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationsApiPutApiApplicationsId
     */
    readonly id: string

    /**
     * 
     * @type {Application}
     * @memberof ApplicationsApiPutApiApplicationsId
     */
    readonly application?: Application
}

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI implements ApplicationsApiInterface {
    /**
     * 
     * @param {ApplicationsApiDeleteApiApplicationsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public deleteApiApplicationsId(requestParameters: ApplicationsApiDeleteApiApplicationsIdRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).deleteApiApplicationsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApiApplications(options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getApiApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationsApiGetApiApplicationsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApiApplicationsId(requestParameters: ApplicationsApiGetApiApplicationsIdRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getApiApplicationsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationsApiPostApiApplicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public postApiApplications(requestParameters: ApplicationsApiPostApiApplicationsRequest = {}, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).postApiApplications(requestParameters.application, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationsApiPutApiApplicationsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public putApiApplicationsId(requestParameters: ApplicationsApiPutApiApplicationsIdRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).putApiApplicationsId(requestParameters.id, requestParameters.application, options).then((request) => request(this.axios, this.basePath));
    }
}
