// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplicationVersion } from '../model';
// @ts-ignore
import { ApplicationVersionPost } from '../model';
/**
 * VersionsApi - axios parameter creator
 * @export
 */
export const VersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationVersionPost} [applicationVersionPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV1Versions: async (applicationVersionPost?: ApplicationVersionPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationVersionPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsApplicationIdCompanyIdCompanyId: async (applicationId: string, companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApiV1VersionsApplicationIdCompanyIdCompanyId', 'applicationId', applicationId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV1VersionsApplicationIdCompanyIdCompanyId', 'companyId', companyId)
            const localVarPath = `/api/v1/Versions/{applicationId}/companyId/{companyId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} companyId 
         * @param {number} siteId 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId: async (id: string, companyId: string, siteId: number, applicationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'id', id)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'companyId', companyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'siteId', siteId)
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'applicationId', applicationId)
            const localVarPath = `/api/v1/Versions/{applicationId}/companyId/{companyId}/siteId/{siteId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)))
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Versions/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId: async (applicationId: string, companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId', 'applicationId', applicationId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId', 'companyId', companyId)
            const localVarPath = `/api/v1/Versions/history/{applicationId}/companyId/{companyId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} companyId 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId: async (applicationId: string, companyId: string, siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'applicationId', applicationId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'companyId', companyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId', 'siteId', siteId)
            const localVarPath = `/api/v1/Versions/history/{applicationId}/companyId/{companyId}/siteId/{siteId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsSites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Versions/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationVersionPost} [applicationVersionPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1Versions: async (applicationVersionPost?: ApplicationVersionPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationVersionPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationVersionPost} [applicationVersionPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV1VersionsLastActive: async (applicationVersionPost?: ApplicationVersionPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Versions/LastActive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationVersionPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionsApi - functional programming interface
 * @export
 */
export const VersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationVersionPost} [applicationVersionPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV1Versions(applicationVersionPost?: ApplicationVersionPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV1Versions(applicationVersionPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1VersionsApplicationIdCompanyIdCompanyId(applicationId: string, companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1VersionsApplicationIdCompanyIdCompanyId(applicationId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} companyId 
         * @param {number} siteId 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(id: string, companyId: string, siteId: number, applicationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(id, companyId, siteId, applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1VersionsCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1VersionsCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(applicationId: string, companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(applicationId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {string} companyId 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(applicationId: string, companyId: string, siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(applicationId, companyId, siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1VersionsSites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1VersionsSites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationVersionPost} [applicationVersionPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1Versions(applicationVersionPost?: ApplicationVersionPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1Versions(applicationVersionPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationVersionPost} [applicationVersionPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV1VersionsLastActive(applicationVersionPost?: ApplicationVersionPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV1VersionsLastActive(applicationVersionPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionsApi - factory interface
 * @export
 */
export const VersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionsApiFp(configuration)
    return {
        /**
         * 
         * @param {VersionsApiDeleteApiV1VersionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV1Versions(requestParameters: VersionsApiDeleteApiV1VersionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion> {
            return localVarFp.deleteApiV1Versions(requestParameters.applicationVersionPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsApplicationIdCompanyIdCompanyId(requestParameters: VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>> {
            return localVarFp.getApiV1VersionsApplicationIdCompanyIdCompanyId(requestParameters.applicationId, requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters: VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion> {
            return localVarFp.getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters.id, requestParameters.companyId, requestParameters.siteId, requestParameters.applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>> {
            return localVarFp.getApiV1VersionsCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(requestParameters: VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>> {
            return localVarFp.getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(requestParameters.applicationId, requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters: VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>> {
            return localVarFp.getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters.applicationId, requestParameters.companyId, requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1VersionsSites(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>> {
            return localVarFp.getApiV1VersionsSites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionsApiPostApiV1VersionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1Versions(requestParameters: VersionsApiPostApiV1VersionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion> {
            return localVarFp.postApiV1Versions(requestParameters.applicationVersionPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionsApiPutApiV1VersionsLastActiveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV1VersionsLastActive(requestParameters: VersionsApiPutApiV1VersionsLastActiveRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion> {
            return localVarFp.putApiV1VersionsLastActive(requestParameters.applicationVersionPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionsApi - interface
 * @export
 * @interface VersionsApi
 */
export interface VersionsApiInterface {
    /**
     * 
     * @param {ApplicationVersionPost} [applicationVersionPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    deleteApiV1Versions(applicationVersionPost?: ApplicationVersionPost, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion>;

    /**
     * 
     * @param {string} applicationId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    getApiV1VersionsApplicationIdCompanyIdCompanyId(applicationId: string, companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>>;

    /**
     * 
     * @param {string} id 
     * @param {string} companyId 
     * @param {number} siteId 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(id: string, companyId: string, siteId: number, applicationId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    getApiV1VersionsCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>>;

    /**
     * 
     * @param {string} applicationId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(applicationId: string, companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>>;

    /**
     * 
     * @param {string} applicationId 
     * @param {string} companyId 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(applicationId: string, companyId: string, siteId: number, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    getApiV1VersionsSites(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationVersion>>;

    /**
     * 
     * @param {ApplicationVersionPost} [applicationVersionPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    postApiV1Versions(applicationVersionPost?: ApplicationVersionPost, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion>;

    /**
     * 
     * @param {ApplicationVersionPost} [applicationVersionPost] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApiInterface
     */
    putApiV1VersionsLastActive(applicationVersionPost?: ApplicationVersionPost, options?: AxiosRequestConfig): AxiosPromise<ApplicationVersion>;

}

/**
 * Request parameters for deleteApiV1Versions operation in VersionsApi.
 * @export
 * @interface VersionsApiDeleteApiV1VersionsRequest
 */
export interface VersionsApiDeleteApiV1VersionsRequest {
    /**
     * 
     * @type {ApplicationVersionPost}
     * @memberof VersionsApiDeleteApiV1Versions
     */
    readonly applicationVersionPost?: ApplicationVersionPost
}

/**
 * Request parameters for getApiV1VersionsApplicationIdCompanyIdCompanyId operation in VersionsApi.
 * @export
 * @interface VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdRequest
 */
export interface VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyId
     */
    readonly applicationId: string

    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId operation in VersionsApi.
 * @export
 * @interface VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest
 */
export interface VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly companyId: string

    /**
     * 
     * @type {number}
     * @memberof VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly siteId: number

    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly applicationId: string
}

/**
 * Request parameters for getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId operation in VersionsApi.
 * @export
 * @interface VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdRequest
 */
export interface VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyId
     */
    readonly applicationId: string

    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId operation in VersionsApi.
 * @export
 * @interface VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest
 */
export interface VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly applicationId: string

    /**
     * 
     * @type {string}
     * @memberof VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly companyId: string

    /**
     * 
     * @type {number}
     * @memberof VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId
     */
    readonly siteId: number
}

/**
 * Request parameters for postApiV1Versions operation in VersionsApi.
 * @export
 * @interface VersionsApiPostApiV1VersionsRequest
 */
export interface VersionsApiPostApiV1VersionsRequest {
    /**
     * 
     * @type {ApplicationVersionPost}
     * @memberof VersionsApiPostApiV1Versions
     */
    readonly applicationVersionPost?: ApplicationVersionPost
}

/**
 * Request parameters for putApiV1VersionsLastActive operation in VersionsApi.
 * @export
 * @interface VersionsApiPutApiV1VersionsLastActiveRequest
 */
export interface VersionsApiPutApiV1VersionsLastActiveRequest {
    /**
     * 
     * @type {ApplicationVersionPost}
     * @memberof VersionsApiPutApiV1VersionsLastActive
     */
    readonly applicationVersionPost?: ApplicationVersionPost
}

/**
 * VersionsApi - object-oriented interface
 * @export
 * @class VersionsApi
 * @extends {BaseAPI}
 */
export class VersionsApi extends BaseAPI implements VersionsApiInterface {
    /**
     * 
     * @param {VersionsApiDeleteApiV1VersionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public deleteApiV1Versions(requestParameters: VersionsApiDeleteApiV1VersionsRequest = {}, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).deleteApiV1Versions(requestParameters.applicationVersionPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getApiV1VersionsApplicationIdCompanyIdCompanyId(requestParameters: VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdRequest, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getApiV1VersionsApplicationIdCompanyIdCompanyId(requestParameters.applicationId, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters: VersionsApiGetApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getApiV1VersionsApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters.id, requestParameters.companyId, requestParameters.siteId, requestParameters.applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getApiV1VersionsCompanies(options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getApiV1VersionsCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(requestParameters: VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdRequest, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getApiV1VersionsHistoryApplicationIdCompanyIdCompanyId(requestParameters.applicationId, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters: VersionsApiGetApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteIdRequest, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getApiV1VersionsHistoryApplicationIdCompanyIdCompanyIdSiteIdSiteId(requestParameters.applicationId, requestParameters.companyId, requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getApiV1VersionsSites(options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getApiV1VersionsSites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionsApiPostApiV1VersionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public postApiV1Versions(requestParameters: VersionsApiPostApiV1VersionsRequest = {}, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).postApiV1Versions(requestParameters.applicationVersionPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionsApiPutApiV1VersionsLastActiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public putApiV1VersionsLastActive(requestParameters: VersionsApiPutApiV1VersionsLastActiveRequest = {}, options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).putApiV1VersionsLastActive(requestParameters.applicationVersionPost, options).then((request) => request(this.axios, this.basePath));
    }
}
