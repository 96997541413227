import { gosApiBaseUrl } from "utils/appsettings";
import * as GosApiV1 from "api/swagger/operation.services.api.1.0/api";
import { generateSwaggerApiClient } from "./genericApiClient";

const gosApiV1Client = generateSwaggerApiClient(GosApiV1, gosApiBaseUrl);

export * as GosApiV1Models from "api/swagger/operation.services.api.1.0/model";
export default gosApiV1Client;

export enum UnitShape {
  Round = "Round",
  Rectangular = "Rectangular",
}
