// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OperationType = {
    Add: 'Add',
    Remove: 'Remove',
    Replace: 'Replace',
    Move: 'Move',
    Copy: 'Copy',
    Test: 'Test',
    Invalid: 'Invalid'
} as const;

export type OperationType = typeof OperationType[keyof typeof OperationType];



