// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Pigment } from '../model';
/**
 * PigmentsApi - axios parameter creator
 * @export
 */
export const PigmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiPigmentsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiPigmentsId', 'id', id)
            const localVarPath = `/api/Pigments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pigments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigmentsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiPigmentsId', 'id', id)
            const localVarPath = `/api/Pigments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pigment} [pigment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiPigments: async (pigment?: Pigment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pigments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pigment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Pigment} [pigment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiPigmentsId: async (id: number, pigment?: Pigment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiPigmentsId', 'id', id)
            const localVarPath = `/api/Pigments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pigment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PigmentsApi - functional programming interface
 * @export
 */
export const PigmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PigmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiPigmentsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pigment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiPigmentsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiPigments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pigment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiPigments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiPigmentsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pigment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiPigmentsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pigment} [pigment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiPigments(pigment?: Pigment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pigment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiPigments(pigment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Pigment} [pigment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiPigmentsId(id: number, pigment?: Pigment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pigment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiPigmentsId(id, pigment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PigmentsApi - factory interface
 * @export
 */
export const PigmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PigmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {PigmentsApiDeleteApiPigmentsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiPigmentsId(requestParameters: PigmentsApiDeleteApiPigmentsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Pigment> {
            return localVarFp.deleteApiPigmentsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigments(options?: AxiosRequestConfig): AxiosPromise<Array<Pigment>> {
            return localVarFp.getApiPigments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PigmentsApiGetApiPigmentsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigmentsId(requestParameters: PigmentsApiGetApiPigmentsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Pigment> {
            return localVarFp.getApiPigmentsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PigmentsApiPostApiPigmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiPigments(requestParameters: PigmentsApiPostApiPigmentsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Pigment> {
            return localVarFp.postApiPigments(requestParameters.pigment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PigmentsApiPutApiPigmentsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiPigmentsId(requestParameters: PigmentsApiPutApiPigmentsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Pigment> {
            return localVarFp.putApiPigmentsId(requestParameters.id, requestParameters.pigment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PigmentsApi - interface
 * @export
 * @interface PigmentsApi
 */
export interface PigmentsApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApiInterface
     */
    deleteApiPigmentsId(id: number, options?: AxiosRequestConfig): AxiosPromise<Pigment>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApiInterface
     */
    getApiPigments(options?: AxiosRequestConfig): AxiosPromise<Array<Pigment>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApiInterface
     */
    getApiPigmentsId(id: number, options?: AxiosRequestConfig): AxiosPromise<Pigment>;

    /**
     * 
     * @param {Pigment} [pigment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApiInterface
     */
    postApiPigments(pigment?: Pigment, options?: AxiosRequestConfig): AxiosPromise<Pigment>;

    /**
     * 
     * @param {number} id 
     * @param {Pigment} [pigment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApiInterface
     */
    putApiPigmentsId(id: number, pigment?: Pigment, options?: AxiosRequestConfig): AxiosPromise<Pigment>;

}

/**
 * Request parameters for deleteApiPigmentsId operation in PigmentsApi.
 * @export
 * @interface PigmentsApiDeleteApiPigmentsIdRequest
 */
export interface PigmentsApiDeleteApiPigmentsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PigmentsApiDeleteApiPigmentsId
     */
    readonly id: number
}

/**
 * Request parameters for getApiPigmentsId operation in PigmentsApi.
 * @export
 * @interface PigmentsApiGetApiPigmentsIdRequest
 */
export interface PigmentsApiGetApiPigmentsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PigmentsApiGetApiPigmentsId
     */
    readonly id: number
}

/**
 * Request parameters for postApiPigments operation in PigmentsApi.
 * @export
 * @interface PigmentsApiPostApiPigmentsRequest
 */
export interface PigmentsApiPostApiPigmentsRequest {
    /**
     * 
     * @type {Pigment}
     * @memberof PigmentsApiPostApiPigments
     */
    readonly pigment?: Pigment
}

/**
 * Request parameters for putApiPigmentsId operation in PigmentsApi.
 * @export
 * @interface PigmentsApiPutApiPigmentsIdRequest
 */
export interface PigmentsApiPutApiPigmentsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PigmentsApiPutApiPigmentsId
     */
    readonly id: number

    /**
     * 
     * @type {Pigment}
     * @memberof PigmentsApiPutApiPigmentsId
     */
    readonly pigment?: Pigment
}

/**
 * PigmentsApi - object-oriented interface
 * @export
 * @class PigmentsApi
 * @extends {BaseAPI}
 */
export class PigmentsApi extends BaseAPI implements PigmentsApiInterface {
    /**
     * 
     * @param {PigmentsApiDeleteApiPigmentsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApi
     */
    public deleteApiPigmentsId(requestParameters: PigmentsApiDeleteApiPigmentsIdRequest, options?: AxiosRequestConfig) {
        return PigmentsApiFp(this.configuration).deleteApiPigmentsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApi
     */
    public getApiPigments(options?: AxiosRequestConfig) {
        return PigmentsApiFp(this.configuration).getApiPigments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PigmentsApiGetApiPigmentsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApi
     */
    public getApiPigmentsId(requestParameters: PigmentsApiGetApiPigmentsIdRequest, options?: AxiosRequestConfig) {
        return PigmentsApiFp(this.configuration).getApiPigmentsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PigmentsApiPostApiPigmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApi
     */
    public postApiPigments(requestParameters: PigmentsApiPostApiPigmentsRequest = {}, options?: AxiosRequestConfig) {
        return PigmentsApiFp(this.configuration).postApiPigments(requestParameters.pigment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PigmentsApiPutApiPigmentsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentsApi
     */
    public putApiPigmentsId(requestParameters: PigmentsApiPutApiPigmentsIdRequest, options?: AxiosRequestConfig) {
        return PigmentsApiFp(this.configuration).putApiPigmentsId(requestParameters.id, requestParameters.pigment, options).then((request) => request(this.axios, this.basePath));
    }
}
