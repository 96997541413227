// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TreatmentProduct } from '../model';
/**
 * TreatmentProductsApi - axios parameter creator
 * @export
 */
export const TreatmentProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTreatmentProductsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiTreatmentProductsId', 'id', id)
            const localVarPath = `/api/TreatmentProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTreatmentProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TreatmentProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTreatmentProductsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiTreatmentProductsId', 'id', id)
            const localVarPath = `/api/TreatmentProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TreatmentProduct} [treatmentProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTreatmentProducts: async (treatmentProduct?: TreatmentProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TreatmentProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatmentProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TreatmentProduct} [treatmentProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiTreatmentProductsId: async (id: number, treatmentProduct?: TreatmentProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiTreatmentProductsId', 'id', id)
            const localVarPath = `/api/TreatmentProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatmentProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TreatmentProductsApi - functional programming interface
 * @export
 */
export const TreatmentProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TreatmentProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTreatmentProductsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTreatmentProductsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTreatmentProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TreatmentProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTreatmentProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTreatmentProductsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTreatmentProductsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TreatmentProduct} [treatmentProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiTreatmentProducts(treatmentProduct?: TreatmentProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiTreatmentProducts(treatmentProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TreatmentProduct} [treatmentProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiTreatmentProductsId(id: number, treatmentProduct?: TreatmentProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiTreatmentProductsId(id, treatmentProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TreatmentProductsApi - factory interface
 * @export
 */
export const TreatmentProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TreatmentProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {TreatmentProductsApiDeleteApiTreatmentProductsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTreatmentProductsId(requestParameters: TreatmentProductsApiDeleteApiTreatmentProductsIdRequest, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct> {
            return localVarFp.deleteApiTreatmentProductsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTreatmentProducts(options?: AxiosRequestConfig): AxiosPromise<Array<TreatmentProduct>> {
            return localVarFp.getApiTreatmentProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TreatmentProductsApiGetApiTreatmentProductsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTreatmentProductsId(requestParameters: TreatmentProductsApiGetApiTreatmentProductsIdRequest, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct> {
            return localVarFp.getApiTreatmentProductsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TreatmentProductsApiPostApiTreatmentProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiTreatmentProducts(requestParameters: TreatmentProductsApiPostApiTreatmentProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct> {
            return localVarFp.postApiTreatmentProducts(requestParameters.treatmentProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TreatmentProductsApiPutApiTreatmentProductsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiTreatmentProductsId(requestParameters: TreatmentProductsApiPutApiTreatmentProductsIdRequest, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct> {
            return localVarFp.putApiTreatmentProductsId(requestParameters.id, requestParameters.treatmentProduct, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TreatmentProductsApi - interface
 * @export
 * @interface TreatmentProductsApi
 */
export interface TreatmentProductsApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApiInterface
     */
    deleteApiTreatmentProductsId(id: number, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApiInterface
     */
    getApiTreatmentProducts(options?: AxiosRequestConfig): AxiosPromise<Array<TreatmentProduct>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApiInterface
     */
    getApiTreatmentProductsId(id: number, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct>;

    /**
     * 
     * @param {TreatmentProduct} [treatmentProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApiInterface
     */
    postApiTreatmentProducts(treatmentProduct?: TreatmentProduct, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct>;

    /**
     * 
     * @param {number} id 
     * @param {TreatmentProduct} [treatmentProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApiInterface
     */
    putApiTreatmentProductsId(id: number, treatmentProduct?: TreatmentProduct, options?: AxiosRequestConfig): AxiosPromise<TreatmentProduct>;

}

/**
 * Request parameters for deleteApiTreatmentProductsId operation in TreatmentProductsApi.
 * @export
 * @interface TreatmentProductsApiDeleteApiTreatmentProductsIdRequest
 */
export interface TreatmentProductsApiDeleteApiTreatmentProductsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TreatmentProductsApiDeleteApiTreatmentProductsId
     */
    readonly id: number
}

/**
 * Request parameters for getApiTreatmentProductsId operation in TreatmentProductsApi.
 * @export
 * @interface TreatmentProductsApiGetApiTreatmentProductsIdRequest
 */
export interface TreatmentProductsApiGetApiTreatmentProductsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TreatmentProductsApiGetApiTreatmentProductsId
     */
    readonly id: number
}

/**
 * Request parameters for postApiTreatmentProducts operation in TreatmentProductsApi.
 * @export
 * @interface TreatmentProductsApiPostApiTreatmentProductsRequest
 */
export interface TreatmentProductsApiPostApiTreatmentProductsRequest {
    /**
     * 
     * @type {TreatmentProduct}
     * @memberof TreatmentProductsApiPostApiTreatmentProducts
     */
    readonly treatmentProduct?: TreatmentProduct
}

/**
 * Request parameters for putApiTreatmentProductsId operation in TreatmentProductsApi.
 * @export
 * @interface TreatmentProductsApiPutApiTreatmentProductsIdRequest
 */
export interface TreatmentProductsApiPutApiTreatmentProductsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TreatmentProductsApiPutApiTreatmentProductsId
     */
    readonly id: number

    /**
     * 
     * @type {TreatmentProduct}
     * @memberof TreatmentProductsApiPutApiTreatmentProductsId
     */
    readonly treatmentProduct?: TreatmentProduct
}

/**
 * TreatmentProductsApi - object-oriented interface
 * @export
 * @class TreatmentProductsApi
 * @extends {BaseAPI}
 */
export class TreatmentProductsApi extends BaseAPI implements TreatmentProductsApiInterface {
    /**
     * 
     * @param {TreatmentProductsApiDeleteApiTreatmentProductsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApi
     */
    public deleteApiTreatmentProductsId(requestParameters: TreatmentProductsApiDeleteApiTreatmentProductsIdRequest, options?: AxiosRequestConfig) {
        return TreatmentProductsApiFp(this.configuration).deleteApiTreatmentProductsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApi
     */
    public getApiTreatmentProducts(options?: AxiosRequestConfig) {
        return TreatmentProductsApiFp(this.configuration).getApiTreatmentProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TreatmentProductsApiGetApiTreatmentProductsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApi
     */
    public getApiTreatmentProductsId(requestParameters: TreatmentProductsApiGetApiTreatmentProductsIdRequest, options?: AxiosRequestConfig) {
        return TreatmentProductsApiFp(this.configuration).getApiTreatmentProductsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TreatmentProductsApiPostApiTreatmentProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApi
     */
    public postApiTreatmentProducts(requestParameters: TreatmentProductsApiPostApiTreatmentProductsRequest = {}, options?: AxiosRequestConfig) {
        return TreatmentProductsApiFp(this.configuration).postApiTreatmentProducts(requestParameters.treatmentProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TreatmentProductsApiPutApiTreatmentProductsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentProductsApi
     */
    public putApiTreatmentProductsId(requestParameters: TreatmentProductsApiPutApiTreatmentProductsIdRequest, options?: AxiosRequestConfig) {
        return TreatmentProductsApiFp(this.configuration).putApiTreatmentProductsId(requestParameters.id, requestParameters.treatmentProduct, options).then((request) => request(this.axios, this.basePath));
    }
}
