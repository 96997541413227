// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MortalityGender } from '../model';
// @ts-ignore
import { MortalityGenderResource } from '../model';
/**
 * MortalityGendersApi - axios parameter creator
 * @export
 */
export const MortalityGendersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityGenderId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiMortalityGenderId', 'id', id)
            const localVarPath = `/api/mortality/gender/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityGender: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality/gender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityGenderId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiMortalityGenderId', 'id', id)
            const localVarPath = `/api/mortality/gender/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortalityGenderResource} [mortalityGenderResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortalityGender: async (mortalityGenderResource?: MortalityGenderResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mortality/gender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityGenderResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityGenderResource} [mortalityGenderResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityGenderId: async (id: number, mortalityGenderResource?: MortalityGenderResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiMortalityGenderId', 'id', id)
            const localVarPath = `/api/mortality/gender/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mortalityGenderResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortalityGendersApi - functional programming interface
 * @export
 */
export const MortalityGendersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortalityGendersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMortalityGenderId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMortalityGenderId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityGender(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MortalityGender>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityGender(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMortalityGenderId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityGender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMortalityGenderId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MortalityGenderResource} [mortalityGenderResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiMortalityGender(mortalityGenderResource?: MortalityGenderResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityGender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiMortalityGender(mortalityGenderResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MortalityGenderResource} [mortalityGenderResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiMortalityGenderId(id: number, mortalityGenderResource?: MortalityGenderResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortalityGender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiMortalityGenderId(id, mortalityGenderResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortalityGendersApi - factory interface
 * @export
 */
export const MortalityGendersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortalityGendersApiFp(configuration)
    return {
        /**
         * 
         * @param {MortalityGendersApiDeleteApiMortalityGenderIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMortalityGenderId(requestParameters: MortalityGendersApiDeleteApiMortalityGenderIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiMortalityGenderId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityGender(options?: AxiosRequestConfig): AxiosPromise<Array<MortalityGender>> {
            return localVarFp.getApiMortalityGender(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityGendersApiGetApiMortalityGenderIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMortalityGenderId(requestParameters: MortalityGendersApiGetApiMortalityGenderIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityGender> {
            return localVarFp.getApiMortalityGenderId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityGendersApiPostApiMortalityGenderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiMortalityGender(requestParameters: MortalityGendersApiPostApiMortalityGenderRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MortalityGender> {
            return localVarFp.postApiMortalityGender(requestParameters.mortalityGenderResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortalityGendersApiPutApiMortalityGenderIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiMortalityGenderId(requestParameters: MortalityGendersApiPutApiMortalityGenderIdRequest, options?: AxiosRequestConfig): AxiosPromise<MortalityGender> {
            return localVarFp.putApiMortalityGenderId(requestParameters.id, requestParameters.mortalityGenderResource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortalityGendersApi - interface
 * @export
 * @interface MortalityGendersApi
 */
export interface MortalityGendersApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApiInterface
     */
    deleteApiMortalityGenderId(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApiInterface
     */
    getApiMortalityGender(options?: AxiosRequestConfig): AxiosPromise<Array<MortalityGender>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApiInterface
     */
    getApiMortalityGenderId(id: number, options?: AxiosRequestConfig): AxiosPromise<MortalityGender>;

    /**
     * 
     * @param {MortalityGenderResource} [mortalityGenderResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApiInterface
     */
    postApiMortalityGender(mortalityGenderResource?: MortalityGenderResource, options?: AxiosRequestConfig): AxiosPromise<MortalityGender>;

    /**
     * 
     * @param {number} id 
     * @param {MortalityGenderResource} [mortalityGenderResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApiInterface
     */
    putApiMortalityGenderId(id: number, mortalityGenderResource?: MortalityGenderResource, options?: AxiosRequestConfig): AxiosPromise<MortalityGender>;

}

/**
 * Request parameters for deleteApiMortalityGenderId operation in MortalityGendersApi.
 * @export
 * @interface MortalityGendersApiDeleteApiMortalityGenderIdRequest
 */
export interface MortalityGendersApiDeleteApiMortalityGenderIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityGendersApiDeleteApiMortalityGenderId
     */
    readonly id: number
}

/**
 * Request parameters for getApiMortalityGenderId operation in MortalityGendersApi.
 * @export
 * @interface MortalityGendersApiGetApiMortalityGenderIdRequest
 */
export interface MortalityGendersApiGetApiMortalityGenderIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityGendersApiGetApiMortalityGenderId
     */
    readonly id: number
}

/**
 * Request parameters for postApiMortalityGender operation in MortalityGendersApi.
 * @export
 * @interface MortalityGendersApiPostApiMortalityGenderRequest
 */
export interface MortalityGendersApiPostApiMortalityGenderRequest {
    /**
     * 
     * @type {MortalityGenderResource}
     * @memberof MortalityGendersApiPostApiMortalityGender
     */
    readonly mortalityGenderResource?: MortalityGenderResource
}

/**
 * Request parameters for putApiMortalityGenderId operation in MortalityGendersApi.
 * @export
 * @interface MortalityGendersApiPutApiMortalityGenderIdRequest
 */
export interface MortalityGendersApiPutApiMortalityGenderIdRequest {
    /**
     * 
     * @type {number}
     * @memberof MortalityGendersApiPutApiMortalityGenderId
     */
    readonly id: number

    /**
     * 
     * @type {MortalityGenderResource}
     * @memberof MortalityGendersApiPutApiMortalityGenderId
     */
    readonly mortalityGenderResource?: MortalityGenderResource
}

/**
 * MortalityGendersApi - object-oriented interface
 * @export
 * @class MortalityGendersApi
 * @extends {BaseAPI}
 */
export class MortalityGendersApi extends BaseAPI implements MortalityGendersApiInterface {
    /**
     * 
     * @param {MortalityGendersApiDeleteApiMortalityGenderIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApi
     */
    public deleteApiMortalityGenderId(requestParameters: MortalityGendersApiDeleteApiMortalityGenderIdRequest, options?: AxiosRequestConfig) {
        return MortalityGendersApiFp(this.configuration).deleteApiMortalityGenderId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApi
     */
    public getApiMortalityGender(options?: AxiosRequestConfig) {
        return MortalityGendersApiFp(this.configuration).getApiMortalityGender(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityGendersApiGetApiMortalityGenderIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApi
     */
    public getApiMortalityGenderId(requestParameters: MortalityGendersApiGetApiMortalityGenderIdRequest, options?: AxiosRequestConfig) {
        return MortalityGendersApiFp(this.configuration).getApiMortalityGenderId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityGendersApiPostApiMortalityGenderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApi
     */
    public postApiMortalityGender(requestParameters: MortalityGendersApiPostApiMortalityGenderRequest = {}, options?: AxiosRequestConfig) {
        return MortalityGendersApiFp(this.configuration).postApiMortalityGender(requestParameters.mortalityGenderResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortalityGendersApiPutApiMortalityGenderIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortalityGendersApi
     */
    public putApiMortalityGenderId(requestParameters: MortalityGendersApiPutApiMortalityGenderIdRequest, options?: AxiosRequestConfig) {
        return MortalityGendersApiFp(this.configuration).putApiMortalityGenderId(requestParameters.id, requestParameters.mortalityGenderResource, options).then((request) => request(this.axios, this.basePath));
    }
}
