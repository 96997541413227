// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthResult } from '../model';
// @ts-ignore
import { CreatedActivationCodesResponse } from '../model';
// @ts-ignore
import { CreatedLicenseKeyResponse } from '../model';
// @ts-ignore
import { CreatedLicenseKeysResponse } from '../model';
// @ts-ignore
import { StationLicense } from '../model';
// @ts-ignore
import { StationLicenseBindingModel } from '../model';
/**
 * StationLicensesApi - axios parameter creator
 * @export
 */
export const StationLicensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [licenseIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiStationLicenses: async (licenseIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (licenseIds) {
                localVarQueryParameter['licenseIds'] = licenseIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiStationLicensesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiStationLicensesId', 'id', id)
            const localVarPath = `/api/StationLicenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicenses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicensesAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicensesCompanyCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiStationLicensesCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/api/StationLicenses/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicensesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiStationLicensesId', 'id', id)
            const localVarPath = `/api/StationLicenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicenses: async (stationLicense?: StationLicense, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST      {          \"activationCode\": \"your activation code\",          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"version\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
         * @summary Activate license with activation code
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesActivatelicense: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/activatelicense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"swVersion\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
         * @summary Validate a license
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesAuth: async (stationLicense?: StationLicense, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreateactivationcode: async (stationLicense?: StationLicense, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/createactivationcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StationLicense>} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreateactivationcodes: async (stationLicense?: Array<StationLicense>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/createactivationcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreatelicensekey: async (stationLicense?: StationLicense, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/createlicensekey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StationLicense>} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreatelicensekeys: async (stationLicense?: Array<StationLicense>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/createlicensekeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"applicationName\": \"Vision\",      }
         * @summary Update license status to server
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesStatus: async (stationLicense?: StationLicense, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StationLicenseBindingModel>} [stationLicenseBindingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiStationLicenses: async (stationLicenseBindingModel?: Array<StationLicenseBindingModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StationLicenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicenseBindingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StationLicenseBindingModel} [stationLicenseBindingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiStationLicensesId: async (id: number, stationLicenseBindingModel?: StationLicenseBindingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiStationLicensesId', 'id', id)
            const localVarPath = `/api/StationLicenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stationLicenseBindingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StationLicensesApi - functional programming interface
 * @export
 */
export const StationLicensesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StationLicensesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [licenseIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiStationLicenses(licenseIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiStationLicenses(licenseIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiStationLicensesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiStationLicensesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiStationLicenses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StationLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiStationLicenses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiStationLicensesAuth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiStationLicensesAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiStationLicensesCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StationLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiStationLicensesCompanyCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiStationLicensesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiStationLicensesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicenses(stationLicense?: StationLicense, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicenses(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST      {          \"activationCode\": \"your activation code\",          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"version\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
         * @summary Activate license with activation code
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesActivatelicense(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesActivatelicense(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"swVersion\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
         * @summary Validate a license
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesAuth(stationLicense?: StationLicense, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesAuth(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesCreateactivationcode(stationLicense?: StationLicense, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesCreateactivationcode(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<StationLicense>} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesCreateactivationcodes(stationLicense?: Array<StationLicense>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedActivationCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesCreateactivationcodes(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesCreatelicensekey(stationLicense?: StationLicense, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedLicenseKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesCreatelicensekey(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<StationLicense>} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesCreatelicensekeys(stationLicense?: Array<StationLicense>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedLicenseKeysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesCreatelicensekeys(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"applicationName\": \"Vision\",      }
         * @summary Update license status to server
         * @param {StationLicense} [stationLicense] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiStationLicensesStatus(stationLicense?: StationLicense, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiStationLicensesStatus(stationLicense, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<StationLicenseBindingModel>} [stationLicenseBindingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiStationLicenses(stationLicenseBindingModel?: Array<StationLicenseBindingModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StationLicense>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiStationLicenses(stationLicenseBindingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StationLicenseBindingModel} [stationLicenseBindingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiStationLicensesId(id: number, stationLicenseBindingModel?: StationLicenseBindingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationLicense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiStationLicensesId(id, stationLicenseBindingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StationLicensesApi - factory interface
 * @export
 */
export const StationLicensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StationLicensesApiFp(configuration)
    return {
        /**
         * 
         * @param {StationLicensesApiDeleteApiStationLicensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiStationLicenses(requestParameters: StationLicensesApiDeleteApiStationLicensesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiStationLicenses(requestParameters.licenseIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiDeleteApiStationLicensesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiStationLicensesId(requestParameters: StationLicensesApiDeleteApiStationLicensesIdRequest, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.deleteApiStationLicensesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicenses(options?: AxiosRequestConfig): AxiosPromise<Array<StationLicense>> {
            return localVarFp.getApiStationLicenses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicensesAuth(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getApiStationLicensesAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiGetApiStationLicensesCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicensesCompanyCompanyId(requestParameters: StationLicensesApiGetApiStationLicensesCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<StationLicense>> {
            return localVarFp.getApiStationLicensesCompanyCompanyId(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiGetApiStationLicensesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiStationLicensesId(requestParameters: StationLicensesApiGetApiStationLicensesIdRequest, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.getApiStationLicensesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPostApiStationLicensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicenses(requestParameters: StationLicensesApiPostApiStationLicensesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.postApiStationLicenses(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST      {          \"activationCode\": \"your activation code\",          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"version\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
         * @summary Activate license with activation code
         * @param {StationLicensesApiPostApiStationLicensesActivatelicenseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesActivatelicense(requestParameters: StationLicensesApiPostApiStationLicensesActivatelicenseRequest = {}, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.postApiStationLicensesActivatelicense(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"swVersion\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
         * @summary Validate a license
         * @param {StationLicensesApiPostApiStationLicensesAuthRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesAuth(requestParameters: StationLicensesApiPostApiStationLicensesAuthRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AuthResult> {
            return localVarFp.postApiStationLicensesAuth(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPostApiStationLicensesCreateactivationcodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreateactivationcode(requestParameters: StationLicensesApiPostApiStationLicensesCreateactivationcodeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.postApiStationLicensesCreateactivationcode(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPostApiStationLicensesCreateactivationcodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreateactivationcodes(requestParameters: StationLicensesApiPostApiStationLicensesCreateactivationcodesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreatedActivationCodesResponse> {
            return localVarFp.postApiStationLicensesCreateactivationcodes(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPostApiStationLicensesCreatelicensekeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreatelicensekey(requestParameters: StationLicensesApiPostApiStationLicensesCreatelicensekeyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreatedLicenseKeyResponse> {
            return localVarFp.postApiStationLicensesCreatelicensekey(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPostApiStationLicensesCreatelicensekeysRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesCreatelicensekeys(requestParameters: StationLicensesApiPostApiStationLicensesCreatelicensekeysRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreatedLicenseKeysResponse> {
            return localVarFp.postApiStationLicensesCreatelicensekeys(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"applicationName\": \"Vision\",      }
         * @summary Update license status to server
         * @param {StationLicensesApiPostApiStationLicensesStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiStationLicensesStatus(requestParameters: StationLicensesApiPostApiStationLicensesStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.postApiStationLicensesStatus(requestParameters.stationLicense, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPutApiStationLicensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiStationLicenses(requestParameters: StationLicensesApiPutApiStationLicensesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<StationLicense>> {
            return localVarFp.putApiStationLicenses(requestParameters.stationLicenseBindingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StationLicensesApiPutApiStationLicensesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiStationLicensesId(requestParameters: StationLicensesApiPutApiStationLicensesIdRequest, options?: AxiosRequestConfig): AxiosPromise<StationLicense> {
            return localVarFp.putApiStationLicensesId(requestParameters.id, requestParameters.stationLicenseBindingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StationLicensesApi - interface
 * @export
 * @interface StationLicensesApi
 */
export interface StationLicensesApiInterface {
    /**
     * 
     * @param {Array<number>} [licenseIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    deleteApiStationLicenses(licenseIds?: Array<number>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    deleteApiStationLicensesId(id: number, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    getApiStationLicenses(options?: AxiosRequestConfig): AxiosPromise<Array<StationLicense>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    getApiStationLicensesAuth(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    getApiStationLicensesCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<StationLicense>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    getApiStationLicensesId(id: number, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

    /**
     * 
     * @param {StationLicense} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicenses(stationLicense?: StationLicense, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

    /**
     * Sample request:                    POST      {          \"activationCode\": \"your activation code\",          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"version\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
     * @summary Activate license with activation code
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesActivatelicense(body?: any, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

    /**
     * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"swVersion\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
     * @summary Validate a license
     * @param {StationLicense} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesAuth(stationLicense?: StationLicense, options?: AxiosRequestConfig): AxiosPromise<AuthResult>;

    /**
     * 
     * @param {StationLicense} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesCreateactivationcode(stationLicense?: StationLicense, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

    /**
     * 
     * @param {Array<StationLicense>} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesCreateactivationcodes(stationLicense?: Array<StationLicense>, options?: AxiosRequestConfig): AxiosPromise<CreatedActivationCodesResponse>;

    /**
     * 
     * @param {StationLicense} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesCreatelicensekey(stationLicense?: StationLicense, options?: AxiosRequestConfig): AxiosPromise<CreatedLicenseKeyResponse>;

    /**
     * 
     * @param {Array<StationLicense>} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesCreatelicensekeys(stationLicense?: Array<StationLicense>, options?: AxiosRequestConfig): AxiosPromise<CreatedLicenseKeysResponse>;

    /**
     * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"applicationName\": \"Vision\",      }
     * @summary Update license status to server
     * @param {StationLicense} [stationLicense] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    postApiStationLicensesStatus(stationLicense?: StationLicense, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

    /**
     * 
     * @param {Array<StationLicenseBindingModel>} [stationLicenseBindingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    putApiStationLicenses(stationLicenseBindingModel?: Array<StationLicenseBindingModel>, options?: AxiosRequestConfig): AxiosPromise<Array<StationLicense>>;

    /**
     * 
     * @param {number} id 
     * @param {StationLicenseBindingModel} [stationLicenseBindingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApiInterface
     */
    putApiStationLicensesId(id: number, stationLicenseBindingModel?: StationLicenseBindingModel, options?: AxiosRequestConfig): AxiosPromise<StationLicense>;

}

/**
 * Request parameters for deleteApiStationLicenses operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiDeleteApiStationLicensesRequest
 */
export interface StationLicensesApiDeleteApiStationLicensesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof StationLicensesApiDeleteApiStationLicenses
     */
    readonly licenseIds?: Array<number>
}

/**
 * Request parameters for deleteApiStationLicensesId operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiDeleteApiStationLicensesIdRequest
 */
export interface StationLicensesApiDeleteApiStationLicensesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof StationLicensesApiDeleteApiStationLicensesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiStationLicensesCompanyCompanyId operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiGetApiStationLicensesCompanyCompanyIdRequest
 */
export interface StationLicensesApiGetApiStationLicensesCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof StationLicensesApiGetApiStationLicensesCompanyCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for getApiStationLicensesId operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiGetApiStationLicensesIdRequest
 */
export interface StationLicensesApiGetApiStationLicensesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof StationLicensesApiGetApiStationLicensesId
     */
    readonly id: number
}

/**
 * Request parameters for postApiStationLicenses operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesRequest
 */
export interface StationLicensesApiPostApiStationLicensesRequest {
    /**
     * 
     * @type {StationLicense}
     * @memberof StationLicensesApiPostApiStationLicenses
     */
    readonly stationLicense?: StationLicense
}

/**
 * Request parameters for postApiStationLicensesActivatelicense operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesActivatelicenseRequest
 */
export interface StationLicensesApiPostApiStationLicensesActivatelicenseRequest {
    /**
     * 
     * @type {any}
     * @memberof StationLicensesApiPostApiStationLicensesActivatelicense
     */
    readonly body?: any
}

/**
 * Request parameters for postApiStationLicensesAuth operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesAuthRequest
 */
export interface StationLicensesApiPostApiStationLicensesAuthRequest {
    /**
     * 
     * @type {StationLicense}
     * @memberof StationLicensesApiPostApiStationLicensesAuth
     */
    readonly stationLicense?: StationLicense
}

/**
 * Request parameters for postApiStationLicensesCreateactivationcode operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesCreateactivationcodeRequest
 */
export interface StationLicensesApiPostApiStationLicensesCreateactivationcodeRequest {
    /**
     * 
     * @type {StationLicense}
     * @memberof StationLicensesApiPostApiStationLicensesCreateactivationcode
     */
    readonly stationLicense?: StationLicense
}

/**
 * Request parameters for postApiStationLicensesCreateactivationcodes operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesCreateactivationcodesRequest
 */
export interface StationLicensesApiPostApiStationLicensesCreateactivationcodesRequest {
    /**
     * 
     * @type {Array<StationLicense>}
     * @memberof StationLicensesApiPostApiStationLicensesCreateactivationcodes
     */
    readonly stationLicense?: Array<StationLicense>
}

/**
 * Request parameters for postApiStationLicensesCreatelicensekey operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesCreatelicensekeyRequest
 */
export interface StationLicensesApiPostApiStationLicensesCreatelicensekeyRequest {
    /**
     * 
     * @type {StationLicense}
     * @memberof StationLicensesApiPostApiStationLicensesCreatelicensekey
     */
    readonly stationLicense?: StationLicense
}

/**
 * Request parameters for postApiStationLicensesCreatelicensekeys operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesCreatelicensekeysRequest
 */
export interface StationLicensesApiPostApiStationLicensesCreatelicensekeysRequest {
    /**
     * 
     * @type {Array<StationLicense>}
     * @memberof StationLicensesApiPostApiStationLicensesCreatelicensekeys
     */
    readonly stationLicense?: Array<StationLicense>
}

/**
 * Request parameters for postApiStationLicensesStatus operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPostApiStationLicensesStatusRequest
 */
export interface StationLicensesApiPostApiStationLicensesStatusRequest {
    /**
     * 
     * @type {StationLicense}
     * @memberof StationLicensesApiPostApiStationLicensesStatus
     */
    readonly stationLicense?: StationLicense
}

/**
 * Request parameters for putApiStationLicenses operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPutApiStationLicensesRequest
 */
export interface StationLicensesApiPutApiStationLicensesRequest {
    /**
     * 
     * @type {Array<StationLicenseBindingModel>}
     * @memberof StationLicensesApiPutApiStationLicenses
     */
    readonly stationLicenseBindingModel?: Array<StationLicenseBindingModel>
}

/**
 * Request parameters for putApiStationLicensesId operation in StationLicensesApi.
 * @export
 * @interface StationLicensesApiPutApiStationLicensesIdRequest
 */
export interface StationLicensesApiPutApiStationLicensesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof StationLicensesApiPutApiStationLicensesId
     */
    readonly id: number

    /**
     * 
     * @type {StationLicenseBindingModel}
     * @memberof StationLicensesApiPutApiStationLicensesId
     */
    readonly stationLicenseBindingModel?: StationLicenseBindingModel
}

/**
 * StationLicensesApi - object-oriented interface
 * @export
 * @class StationLicensesApi
 * @extends {BaseAPI}
 */
export class StationLicensesApi extends BaseAPI implements StationLicensesApiInterface {
    /**
     * 
     * @param {StationLicensesApiDeleteApiStationLicensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public deleteApiStationLicenses(requestParameters: StationLicensesApiDeleteApiStationLicensesRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).deleteApiStationLicenses(requestParameters.licenseIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiDeleteApiStationLicensesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public deleteApiStationLicensesId(requestParameters: StationLicensesApiDeleteApiStationLicensesIdRequest, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).deleteApiStationLicensesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public getApiStationLicenses(options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).getApiStationLicenses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public getApiStationLicensesAuth(options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).getApiStationLicensesAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiGetApiStationLicensesCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public getApiStationLicensesCompanyCompanyId(requestParameters: StationLicensesApiGetApiStationLicensesCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).getApiStationLicensesCompanyCompanyId(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiGetApiStationLicensesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public getApiStationLicensesId(requestParameters: StationLicensesApiGetApiStationLicensesIdRequest, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).getApiStationLicensesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPostApiStationLicensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicenses(requestParameters: StationLicensesApiPostApiStationLicensesRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicenses(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST      {          \"activationCode\": \"your activation code\",          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"version\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
     * @summary Activate license with activation code
     * @param {StationLicensesApiPostApiStationLicensesActivatelicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesActivatelicense(requestParameters: StationLicensesApiPostApiStationLicensesActivatelicenseRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesActivatelicense(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"swVersion\": \"1.x\",          \"applicationName\": \"FeedStation\",      }
     * @summary Validate a license
     * @param {StationLicensesApiPostApiStationLicensesAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesAuth(requestParameters: StationLicensesApiPostApiStationLicensesAuthRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesAuth(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPostApiStationLicensesCreateactivationcodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesCreateactivationcode(requestParameters: StationLicensesApiPostApiStationLicensesCreateactivationcodeRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesCreateactivationcode(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPostApiStationLicensesCreateactivationcodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesCreateactivationcodes(requestParameters: StationLicensesApiPostApiStationLicensesCreateactivationcodesRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesCreateactivationcodes(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPostApiStationLicensesCreatelicensekeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesCreatelicensekey(requestParameters: StationLicensesApiPostApiStationLicensesCreatelicensekeyRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesCreatelicensekey(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPostApiStationLicensesCreatelicensekeysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesCreatelicensekeys(requestParameters: StationLicensesApiPostApiStationLicensesCreatelicensekeysRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesCreatelicensekeys(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST      {          \"hdSerial\": \"your hd serial\",          \"MACAddress\": \"your mac address\",          \"applicationName\": \"Vision\",      }
     * @summary Update license status to server
     * @param {StationLicensesApiPostApiStationLicensesStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public postApiStationLicensesStatus(requestParameters: StationLicensesApiPostApiStationLicensesStatusRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).postApiStationLicensesStatus(requestParameters.stationLicense, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPutApiStationLicensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public putApiStationLicenses(requestParameters: StationLicensesApiPutApiStationLicensesRequest = {}, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).putApiStationLicenses(requestParameters.stationLicenseBindingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StationLicensesApiPutApiStationLicensesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationLicensesApi
     */
    public putApiStationLicensesId(requestParameters: StationLicensesApiPutApiStationLicensesIdRequest, options?: AxiosRequestConfig) {
        return StationLicensesApiFp(this.configuration).putApiStationLicensesId(requestParameters.id, requestParameters.stationLicenseBindingModel, options).then((request) => request(this.axios, this.basePath));
    }
}
