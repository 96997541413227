// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DietType } from '../model';
/**
 * DietTypesApi - axios parameter creator
 * @export
 */
export const DietTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDietTypesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiDietTypesId', 'id', id)
            const localVarPath = `/api/DietTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDietTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DietTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDietTypesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiDietTypesId', 'id', id)
            const localVarPath = `/api/DietTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DietType} [dietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDietTypes: async (dietType?: DietType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DietTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dietType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DietType} [dietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiDietTypesId: async (id: number, dietType?: DietType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiDietTypesId', 'id', id)
            const localVarPath = `/api/DietTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dietType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DietTypesApi - functional programming interface
 * @export
 */
export const DietTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DietTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiDietTypesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DietType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiDietTypesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDietTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DietType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDietTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDietTypesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DietType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDietTypesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DietType} [dietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDietTypes(dietType?: DietType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DietType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDietTypes(dietType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DietType} [dietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiDietTypesId(id: number, dietType?: DietType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DietType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiDietTypesId(id, dietType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DietTypesApi - factory interface
 * @export
 */
export const DietTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DietTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {DietTypesApiDeleteApiDietTypesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDietTypesId(requestParameters: DietTypesApiDeleteApiDietTypesIdRequest, options?: AxiosRequestConfig): AxiosPromise<DietType> {
            return localVarFp.deleteApiDietTypesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDietTypes(options?: AxiosRequestConfig): AxiosPromise<Array<DietType>> {
            return localVarFp.getApiDietTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DietTypesApiGetApiDietTypesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDietTypesId(requestParameters: DietTypesApiGetApiDietTypesIdRequest, options?: AxiosRequestConfig): AxiosPromise<DietType> {
            return localVarFp.getApiDietTypesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DietTypesApiPostApiDietTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDietTypes(requestParameters: DietTypesApiPostApiDietTypesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<DietType> {
            return localVarFp.postApiDietTypes(requestParameters.dietType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DietTypesApiPutApiDietTypesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiDietTypesId(requestParameters: DietTypesApiPutApiDietTypesIdRequest, options?: AxiosRequestConfig): AxiosPromise<DietType> {
            return localVarFp.putApiDietTypesId(requestParameters.id, requestParameters.dietType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DietTypesApi - interface
 * @export
 * @interface DietTypesApi
 */
export interface DietTypesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApiInterface
     */
    deleteApiDietTypesId(id: number, options?: AxiosRequestConfig): AxiosPromise<DietType>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApiInterface
     */
    getApiDietTypes(options?: AxiosRequestConfig): AxiosPromise<Array<DietType>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApiInterface
     */
    getApiDietTypesId(id: number, options?: AxiosRequestConfig): AxiosPromise<DietType>;

    /**
     * 
     * @param {DietType} [dietType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApiInterface
     */
    postApiDietTypes(dietType?: DietType, options?: AxiosRequestConfig): AxiosPromise<DietType>;

    /**
     * 
     * @param {number} id 
     * @param {DietType} [dietType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApiInterface
     */
    putApiDietTypesId(id: number, dietType?: DietType, options?: AxiosRequestConfig): AxiosPromise<DietType>;

}

/**
 * Request parameters for deleteApiDietTypesId operation in DietTypesApi.
 * @export
 * @interface DietTypesApiDeleteApiDietTypesIdRequest
 */
export interface DietTypesApiDeleteApiDietTypesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof DietTypesApiDeleteApiDietTypesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiDietTypesId operation in DietTypesApi.
 * @export
 * @interface DietTypesApiGetApiDietTypesIdRequest
 */
export interface DietTypesApiGetApiDietTypesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof DietTypesApiGetApiDietTypesId
     */
    readonly id: number
}

/**
 * Request parameters for postApiDietTypes operation in DietTypesApi.
 * @export
 * @interface DietTypesApiPostApiDietTypesRequest
 */
export interface DietTypesApiPostApiDietTypesRequest {
    /**
     * 
     * @type {DietType}
     * @memberof DietTypesApiPostApiDietTypes
     */
    readonly dietType?: DietType
}

/**
 * Request parameters for putApiDietTypesId operation in DietTypesApi.
 * @export
 * @interface DietTypesApiPutApiDietTypesIdRequest
 */
export interface DietTypesApiPutApiDietTypesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof DietTypesApiPutApiDietTypesId
     */
    readonly id: number

    /**
     * 
     * @type {DietType}
     * @memberof DietTypesApiPutApiDietTypesId
     */
    readonly dietType?: DietType
}

/**
 * DietTypesApi - object-oriented interface
 * @export
 * @class DietTypesApi
 * @extends {BaseAPI}
 */
export class DietTypesApi extends BaseAPI implements DietTypesApiInterface {
    /**
     * 
     * @param {DietTypesApiDeleteApiDietTypesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApi
     */
    public deleteApiDietTypesId(requestParameters: DietTypesApiDeleteApiDietTypesIdRequest, options?: AxiosRequestConfig) {
        return DietTypesApiFp(this.configuration).deleteApiDietTypesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApi
     */
    public getApiDietTypes(options?: AxiosRequestConfig) {
        return DietTypesApiFp(this.configuration).getApiDietTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DietTypesApiGetApiDietTypesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApi
     */
    public getApiDietTypesId(requestParameters: DietTypesApiGetApiDietTypesIdRequest, options?: AxiosRequestConfig) {
        return DietTypesApiFp(this.configuration).getApiDietTypesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DietTypesApiPostApiDietTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApi
     */
    public postApiDietTypes(requestParameters: DietTypesApiPostApiDietTypesRequest = {}, options?: AxiosRequestConfig) {
        return DietTypesApiFp(this.configuration).postApiDietTypes(requestParameters.dietType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DietTypesApiPutApiDietTypesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietTypesApi
     */
    public putApiDietTypesId(requestParameters: DietTypesApiPutApiDietTypesIdRequest, options?: AxiosRequestConfig) {
        return DietTypesApiFp(this.configuration).putApiDietTypesId(requestParameters.id, requestParameters.dietType, options).then((request) => request(this.axios, this.basePath));
    }
}
