// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AuditType = {
    Unknown: 'Unknown',
    Created: 'Created',
    Updated: 'Updated',
    Deleted: 'Deleted'
} as const;

export type AuditType = typeof AuditType[keyof typeof AuditType];



