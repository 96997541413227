// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedAdditive } from '../model';
/**
 * FeedAdditivesApi - axios parameter creator
 * @export
 */
export const FeedAdditivesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedAdditivesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiFeedAdditivesId', 'id', id)
            const localVarPath = `/api/FeedAdditives/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedAdditives: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedAdditives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedAdditivesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiFeedAdditivesId', 'id', id)
            const localVarPath = `/api/FeedAdditives/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FeedAdditive} [feedAdditive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedAdditives: async (feedAdditive?: FeedAdditive, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedAdditives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedAdditive, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedAdditive} [feedAdditive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedAdditivesId: async (id: number, feedAdditive?: FeedAdditive, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiFeedAdditivesId', 'id', id)
            const localVarPath = `/api/FeedAdditives/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedAdditive, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedAdditivesApi - functional programming interface
 * @export
 */
export const FeedAdditivesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedAdditivesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiFeedAdditivesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedAdditive>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiFeedAdditivesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedAdditives(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedAdditive>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedAdditives(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedAdditivesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedAdditive>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedAdditivesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FeedAdditive} [feedAdditive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiFeedAdditives(feedAdditive?: FeedAdditive, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedAdditive>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiFeedAdditives(feedAdditive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedAdditive} [feedAdditive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiFeedAdditivesId(id: number, feedAdditive?: FeedAdditive, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedAdditive>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiFeedAdditivesId(id, feedAdditive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedAdditivesApi - factory interface
 * @export
 */
export const FeedAdditivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedAdditivesApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedAdditivesApiDeleteApiFeedAdditivesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedAdditivesId(requestParameters: FeedAdditivesApiDeleteApiFeedAdditivesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive> {
            return localVarFp.deleteApiFeedAdditivesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedAdditives(options?: AxiosRequestConfig): AxiosPromise<Array<FeedAdditive>> {
            return localVarFp.getApiFeedAdditives(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedAdditivesApiGetApiFeedAdditivesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedAdditivesId(requestParameters: FeedAdditivesApiGetApiFeedAdditivesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive> {
            return localVarFp.getApiFeedAdditivesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedAdditivesApiPostApiFeedAdditivesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedAdditives(requestParameters: FeedAdditivesApiPostApiFeedAdditivesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive> {
            return localVarFp.postApiFeedAdditives(requestParameters.feedAdditive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedAdditivesApiPutApiFeedAdditivesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedAdditivesId(requestParameters: FeedAdditivesApiPutApiFeedAdditivesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive> {
            return localVarFp.putApiFeedAdditivesId(requestParameters.id, requestParameters.feedAdditive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedAdditivesApi - interface
 * @export
 * @interface FeedAdditivesApi
 */
export interface FeedAdditivesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApiInterface
     */
    deleteApiFeedAdditivesId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApiInterface
     */
    getApiFeedAdditives(options?: AxiosRequestConfig): AxiosPromise<Array<FeedAdditive>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApiInterface
     */
    getApiFeedAdditivesId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive>;

    /**
     * 
     * @param {FeedAdditive} [feedAdditive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApiInterface
     */
    postApiFeedAdditives(feedAdditive?: FeedAdditive, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive>;

    /**
     * 
     * @param {number} id 
     * @param {FeedAdditive} [feedAdditive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApiInterface
     */
    putApiFeedAdditivesId(id: number, feedAdditive?: FeedAdditive, options?: AxiosRequestConfig): AxiosPromise<FeedAdditive>;

}

/**
 * Request parameters for deleteApiFeedAdditivesId operation in FeedAdditivesApi.
 * @export
 * @interface FeedAdditivesApiDeleteApiFeedAdditivesIdRequest
 */
export interface FeedAdditivesApiDeleteApiFeedAdditivesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedAdditivesApiDeleteApiFeedAdditivesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiFeedAdditivesId operation in FeedAdditivesApi.
 * @export
 * @interface FeedAdditivesApiGetApiFeedAdditivesIdRequest
 */
export interface FeedAdditivesApiGetApiFeedAdditivesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedAdditivesApiGetApiFeedAdditivesId
     */
    readonly id: number
}

/**
 * Request parameters for postApiFeedAdditives operation in FeedAdditivesApi.
 * @export
 * @interface FeedAdditivesApiPostApiFeedAdditivesRequest
 */
export interface FeedAdditivesApiPostApiFeedAdditivesRequest {
    /**
     * 
     * @type {FeedAdditive}
     * @memberof FeedAdditivesApiPostApiFeedAdditives
     */
    readonly feedAdditive?: FeedAdditive
}

/**
 * Request parameters for putApiFeedAdditivesId operation in FeedAdditivesApi.
 * @export
 * @interface FeedAdditivesApiPutApiFeedAdditivesIdRequest
 */
export interface FeedAdditivesApiPutApiFeedAdditivesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedAdditivesApiPutApiFeedAdditivesId
     */
    readonly id: number

    /**
     * 
     * @type {FeedAdditive}
     * @memberof FeedAdditivesApiPutApiFeedAdditivesId
     */
    readonly feedAdditive?: FeedAdditive
}

/**
 * FeedAdditivesApi - object-oriented interface
 * @export
 * @class FeedAdditivesApi
 * @extends {BaseAPI}
 */
export class FeedAdditivesApi extends BaseAPI implements FeedAdditivesApiInterface {
    /**
     * 
     * @param {FeedAdditivesApiDeleteApiFeedAdditivesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApi
     */
    public deleteApiFeedAdditivesId(requestParameters: FeedAdditivesApiDeleteApiFeedAdditivesIdRequest, options?: AxiosRequestConfig) {
        return FeedAdditivesApiFp(this.configuration).deleteApiFeedAdditivesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApi
     */
    public getApiFeedAdditives(options?: AxiosRequestConfig) {
        return FeedAdditivesApiFp(this.configuration).getApiFeedAdditives(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedAdditivesApiGetApiFeedAdditivesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApi
     */
    public getApiFeedAdditivesId(requestParameters: FeedAdditivesApiGetApiFeedAdditivesIdRequest, options?: AxiosRequestConfig) {
        return FeedAdditivesApiFp(this.configuration).getApiFeedAdditivesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedAdditivesApiPostApiFeedAdditivesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApi
     */
    public postApiFeedAdditives(requestParameters: FeedAdditivesApiPostApiFeedAdditivesRequest = {}, options?: AxiosRequestConfig) {
        return FeedAdditivesApiFp(this.configuration).postApiFeedAdditives(requestParameters.feedAdditive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedAdditivesApiPutApiFeedAdditivesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAdditivesApi
     */
    public putApiFeedAdditivesId(requestParameters: FeedAdditivesApiPutApiFeedAdditivesIdRequest, options?: AxiosRequestConfig) {
        return FeedAdditivesApiFp(this.configuration).putApiFeedAdditivesId(requestParameters.id, requestParameters.feedAdditive, options).then((request) => request(this.axios, this.basePath));
    }
}
