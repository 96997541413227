// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PropertyType = {
    Int: 'Int',
    Float: 'Float',
    String: 'String',
    Password: 'Password'
} as const;

export type PropertyType = typeof PropertyType[keyof typeof PropertyType];



