// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Feed } from '../model';
// @ts-ignore
import { FeedResource } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * FeedsApi - axios parameter creator
 * @export
 */
export const FeedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiFeedsId', 'id', id)
            const localVarPath = `/api/Feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeeds: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedsBycompany: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feeds/bycompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedsBycompanyCompanyId: async (companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiFeedsBycompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/api/Feeds/bycompany/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiFeedsId', 'id', id)
            const localVarPath = `/api/Feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiFeedsId: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchApiFeedsId', 'id', id)
            const localVarPath = `/api/Feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FeedResource} [feedResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeeds: async (feedResource?: FeedResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedsImport: async (files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feeds/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedResource} [feedResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedsId: async (id: number, feedResource?: FeedResource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiFeedsId', 'id', id)
            const localVarPath = `/api/Feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedResource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedsApi - functional programming interface
 * @export
 */
export const FeedsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiFeedsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiFeedsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeeds($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeeds($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedsBycompany($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedsBycompany($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedsBycompanyCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedsBycompanyCompanyId(companyId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiFeedsId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiFeedsId(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FeedResource} [feedResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiFeeds(feedResource?: FeedResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiFeeds(feedResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiFeedsImport(files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiFeedsImport(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedResource} [feedResource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiFeedsId(id: number, feedResource?: FeedResource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiFeedsId(id, feedResource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedsApi - factory interface
 * @export
 */
export const FeedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedsApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedsApiDeleteApiFeedsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedsId(requestParameters: FeedsApiDeleteApiFeedsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.deleteApiFeedsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiFeedsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeeds(requestParameters: FeedsApiGetApiFeedsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Feed>> {
            return localVarFp.getApiFeeds(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiFeedsBycompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedsBycompany(requestParameters: FeedsApiGetApiFeedsBycompanyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<Feed>> {
            return localVarFp.getApiFeedsBycompany(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiFeedsBycompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedsBycompanyCompanyId(requestParameters: FeedsApiGetApiFeedsBycompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Feed>> {
            return localVarFp.getApiFeedsBycompanyCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiFeedsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedsId(requestParameters: FeedsApiGetApiFeedsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.getApiFeedsId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPatchApiFeedsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiFeedsId(requestParameters: FeedsApiPatchApiFeedsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.patchApiFeedsId(requestParameters.id, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPostApiFeedsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeeds(requestParameters: FeedsApiPostApiFeedsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.postApiFeeds(requestParameters.feedResource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPostApiFeedsImportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedsImport(requestParameters: FeedsApiPostApiFeedsImportRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.postApiFeedsImport(requestParameters.files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPutApiFeedsIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedsId(requestParameters: FeedsApiPutApiFeedsIdRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.putApiFeedsId(requestParameters.id, requestParameters.feedResource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedsApi - interface
 * @export
 * @interface FeedsApi
 */
export interface FeedsApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    deleteApiFeedsId(id: number, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiFeeds($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Feed>>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiFeedsBycompany($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Feed>>;

    /**
     * 
     * @param {string} companyId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiFeedsBycompanyCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Feed>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiFeedsId(id: number, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    patchApiFeedsId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {FeedResource} [feedResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    postApiFeeds(feedResource?: FeedResource, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    postApiFeedsImport(files?: Array<File>, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {number} id 
     * @param {FeedResource} [feedResource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    putApiFeedsId(id: number, feedResource?: FeedResource, options?: AxiosRequestConfig): AxiosPromise<Feed>;

}

/**
 * Request parameters for deleteApiFeedsId operation in FeedsApi.
 * @export
 * @interface FeedsApiDeleteApiFeedsIdRequest
 */
export interface FeedsApiDeleteApiFeedsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiDeleteApiFeedsId
     */
    readonly id: number
}

/**
 * Request parameters for getApiFeeds operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiFeedsRequest
 */
export interface FeedsApiGetApiFeedsRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiFeeds
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiFeedsBycompany operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiFeedsBycompanyRequest
 */
export interface FeedsApiGetApiFeedsBycompanyRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiFeedsBycompany
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiFeedsBycompanyCompanyId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiFeedsBycompanyCompanyIdRequest
 */
export interface FeedsApiGetApiFeedsBycompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly companyId: string

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiFeedsBycompanyCompanyId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiFeedsId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiFeedsIdRequest
 */
export interface FeedsApiGetApiFeedsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiFeedsId
     */
    readonly id: number
}

/**
 * Request parameters for patchApiFeedsId operation in FeedsApi.
 * @export
 * @interface FeedsApiPatchApiFeedsIdRequest
 */
export interface FeedsApiPatchApiFeedsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiPatchApiFeedsId
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedsApiPatchApiFeedsId
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiFeeds operation in FeedsApi.
 * @export
 * @interface FeedsApiPostApiFeedsRequest
 */
export interface FeedsApiPostApiFeedsRequest {
    /**
     * 
     * @type {FeedResource}
     * @memberof FeedsApiPostApiFeeds
     */
    readonly feedResource?: FeedResource
}

/**
 * Request parameters for postApiFeedsImport operation in FeedsApi.
 * @export
 * @interface FeedsApiPostApiFeedsImportRequest
 */
export interface FeedsApiPostApiFeedsImportRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof FeedsApiPostApiFeedsImport
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for putApiFeedsId operation in FeedsApi.
 * @export
 * @interface FeedsApiPutApiFeedsIdRequest
 */
export interface FeedsApiPutApiFeedsIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiPutApiFeedsId
     */
    readonly id: number

    /**
     * 
     * @type {FeedResource}
     * @memberof FeedsApiPutApiFeedsId
     */
    readonly feedResource?: FeedResource
}

/**
 * FeedsApi - object-oriented interface
 * @export
 * @class FeedsApi
 * @extends {BaseAPI}
 */
export class FeedsApi extends BaseAPI implements FeedsApiInterface {
    /**
     * 
     * @param {FeedsApiDeleteApiFeedsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public deleteApiFeedsId(requestParameters: FeedsApiDeleteApiFeedsIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).deleteApiFeedsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiFeedsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiFeeds(requestParameters: FeedsApiGetApiFeedsRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiFeeds(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiFeedsBycompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiFeedsBycompany(requestParameters: FeedsApiGetApiFeedsBycompanyRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiFeedsBycompany(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiFeedsBycompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiFeedsBycompanyCompanyId(requestParameters: FeedsApiGetApiFeedsBycompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiFeedsBycompanyCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiFeedsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiFeedsId(requestParameters: FeedsApiGetApiFeedsIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiFeedsId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPatchApiFeedsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public patchApiFeedsId(requestParameters: FeedsApiPatchApiFeedsIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).patchApiFeedsId(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPostApiFeedsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public postApiFeeds(requestParameters: FeedsApiPostApiFeedsRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).postApiFeeds(requestParameters.feedResource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPostApiFeedsImportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public postApiFeedsImport(requestParameters: FeedsApiPostApiFeedsImportRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).postApiFeedsImport(requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPutApiFeedsIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public putApiFeedsId(requestParameters: FeedsApiPutApiFeedsIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).putApiFeedsId(requestParameters.id, requestParameters.feedResource, options).then((request) => request(this.axios, this.basePath));
    }
}
