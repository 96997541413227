// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiKey } from '../model';
// @ts-ignore
import { Scope } from '../model';
/**
 * ApiKeysApi - axios parameter creator
 * @export
 */
export const ApiKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiApiKeysId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiApiKeysId', 'id', id)
            const localVarPath = `/api/ApiKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysCompanyCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiApiKeysCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/api/ApiKeys/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiApiKeysId', 'id', id)
            const localVarPath = `/api/ApiKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysIdActive: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiApiKeysIdActive', 'id', id)
            const localVarPath = `/api/ApiKeys/{id}/Active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysIdDeactive: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiApiKeysIdDeactive', 'id', id)
            const localVarPath = `/api/ApiKeys/{id}/Deactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysSiteSiteId: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiApiKeysSiteSiteId', 'siteId', siteId)
            const localVarPath = `/api/ApiKeys/site/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiScopes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApiKeyValidate: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKey/Validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiKey} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApiKeys: async (apiKey?: ApiKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiKey} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApiKeysGenerate: async (apiKey?: ApiKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKeys/Generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApiKey} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiApiKeysId: async (id: number, apiKey?: ApiKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiApiKeysId', 'id', id)
            const localVarPath = `/api/ApiKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeysApi - functional programming interface
 * @export
 */
export const ApiKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiApiKeysId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiApiKeysId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApiKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApiKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApiKeysCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApiKeysCompanyCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApiKeysId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApiKeysId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApiKeysIdActive(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApiKeysIdActive(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApiKeysIdDeactive(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApiKeysIdDeactive(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiApiKeysSiteSiteId(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiApiKeysSiteSiteId(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiScopes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Scope>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiScopes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiApiKeyValidate(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiApiKeyValidate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiKey} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiApiKeys(apiKey?: ApiKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiApiKeys(apiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiKey} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiApiKeysGenerate(apiKey?: ApiKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiApiKeysGenerate(apiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApiKey} [apiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiApiKeysId(id: number, apiKey?: ApiKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiApiKeysId(id, apiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiKeysApi - factory interface
 * @export
 */
export const ApiKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiKeysApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiKeysApiDeleteApiApiKeysIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiApiKeysId(requestParameters: ApiKeysApiDeleteApiApiKeysIdRequest, options?: AxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.deleteApiApiKeysId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeys(options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>> {
            return localVarFp.getApiApiKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiGetApiApiKeysCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysCompanyCompanyId(requestParameters: ApiKeysApiGetApiApiKeysCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>> {
            return localVarFp.getApiApiKeysCompanyCompanyId(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiGetApiApiKeysIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysId(requestParameters: ApiKeysApiGetApiApiKeysIdRequest, options?: AxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.getApiApiKeysId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiGetApiApiKeysIdActiveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysIdActive(requestParameters: ApiKeysApiGetApiApiKeysIdActiveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getApiApiKeysIdActive(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiGetApiApiKeysIdDeactiveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysIdDeactive(requestParameters: ApiKeysApiGetApiApiKeysIdDeactiveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getApiApiKeysIdDeactive(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiGetApiApiKeysSiteSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiApiKeysSiteSiteId(requestParameters: ApiKeysApiGetApiApiKeysSiteSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>> {
            return localVarFp.getApiApiKeysSiteSiteId(requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiScopes(options?: AxiosRequestConfig): AxiosPromise<Array<Scope>> {
            return localVarFp.getApiScopes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiPostApiApiKeyValidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApiKeyValidate(requestParameters: ApiKeysApiPostApiApiKeyValidateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.postApiApiKeyValidate(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiPostApiApiKeysRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApiKeys(requestParameters: ApiKeysApiPostApiApiKeysRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.postApiApiKeys(requestParameters.apiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiPostApiApiKeysGenerateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiApiKeysGenerate(requestParameters: ApiKeysApiPostApiApiKeysGenerateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.postApiApiKeysGenerate(requestParameters.apiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeysApiPutApiApiKeysIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiApiKeysId(requestParameters: ApiKeysApiPutApiApiKeysIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiApiKeysId(requestParameters.id, requestParameters.apiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiKeysApi - interface
 * @export
 * @interface ApiKeysApi
 */
export interface ApiKeysApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    deleteApiApiKeysId(id: number, options?: AxiosRequestConfig): AxiosPromise<ApiKey>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiApiKeys(options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>>;

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiApiKeysCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiApiKeysId(id: number, options?: AxiosRequestConfig): AxiosPromise<ApiKey>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiApiKeysIdActive(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiApiKeysIdDeactive(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiApiKeysSiteSiteId(siteId: number, options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    getApiScopes(options?: AxiosRequestConfig): AxiosPromise<Array<Scope>>;

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    postApiApiKeyValidate(body?: string, options?: AxiosRequestConfig): AxiosPromise<ApiKey>;

    /**
     * 
     * @param {ApiKey} [apiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    postApiApiKeys(apiKey?: ApiKey, options?: AxiosRequestConfig): AxiosPromise<ApiKey>;

    /**
     * 
     * @param {ApiKey} [apiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    postApiApiKeysGenerate(apiKey?: ApiKey, options?: AxiosRequestConfig): AxiosPromise<ApiKey>;

    /**
     * 
     * @param {number} id 
     * @param {ApiKey} [apiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApiInterface
     */
    putApiApiKeysId(id: number, apiKey?: ApiKey, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiApiKeysId operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiDeleteApiApiKeysIdRequest
 */
export interface ApiKeysApiDeleteApiApiKeysIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiKeysApiDeleteApiApiKeysId
     */
    readonly id: number
}

/**
 * Request parameters for getApiApiKeysCompanyCompanyId operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiGetApiApiKeysCompanyCompanyIdRequest
 */
export interface ApiKeysApiGetApiApiKeysCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiKeysApiGetApiApiKeysCompanyCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for getApiApiKeysId operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiGetApiApiKeysIdRequest
 */
export interface ApiKeysApiGetApiApiKeysIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiKeysApiGetApiApiKeysId
     */
    readonly id: number
}

/**
 * Request parameters for getApiApiKeysIdActive operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiGetApiApiKeysIdActiveRequest
 */
export interface ApiKeysApiGetApiApiKeysIdActiveRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiKeysApiGetApiApiKeysIdActive
     */
    readonly id: number
}

/**
 * Request parameters for getApiApiKeysIdDeactive operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiGetApiApiKeysIdDeactiveRequest
 */
export interface ApiKeysApiGetApiApiKeysIdDeactiveRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiKeysApiGetApiApiKeysIdDeactive
     */
    readonly id: number
}

/**
 * Request parameters for getApiApiKeysSiteSiteId operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiGetApiApiKeysSiteSiteIdRequest
 */
export interface ApiKeysApiGetApiApiKeysSiteSiteIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiKeysApiGetApiApiKeysSiteSiteId
     */
    readonly siteId: number
}

/**
 * Request parameters for postApiApiKeyValidate operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiPostApiApiKeyValidateRequest
 */
export interface ApiKeysApiPostApiApiKeyValidateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiKeysApiPostApiApiKeyValidate
     */
    readonly body?: string
}

/**
 * Request parameters for postApiApiKeys operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiPostApiApiKeysRequest
 */
export interface ApiKeysApiPostApiApiKeysRequest {
    /**
     * 
     * @type {ApiKey}
     * @memberof ApiKeysApiPostApiApiKeys
     */
    readonly apiKey?: ApiKey
}

/**
 * Request parameters for postApiApiKeysGenerate operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiPostApiApiKeysGenerateRequest
 */
export interface ApiKeysApiPostApiApiKeysGenerateRequest {
    /**
     * 
     * @type {ApiKey}
     * @memberof ApiKeysApiPostApiApiKeysGenerate
     */
    readonly apiKey?: ApiKey
}

/**
 * Request parameters for putApiApiKeysId operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiPutApiApiKeysIdRequest
 */
export interface ApiKeysApiPutApiApiKeysIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiKeysApiPutApiApiKeysId
     */
    readonly id: number

    /**
     * 
     * @type {ApiKey}
     * @memberof ApiKeysApiPutApiApiKeysId
     */
    readonly apiKey?: ApiKey
}

/**
 * ApiKeysApi - object-oriented interface
 * @export
 * @class ApiKeysApi
 * @extends {BaseAPI}
 */
export class ApiKeysApi extends BaseAPI implements ApiKeysApiInterface {
    /**
     * 
     * @param {ApiKeysApiDeleteApiApiKeysIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public deleteApiApiKeysId(requestParameters: ApiKeysApiDeleteApiApiKeysIdRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).deleteApiApiKeysId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiApiKeys(options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiApiKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiGetApiApiKeysCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiApiKeysCompanyCompanyId(requestParameters: ApiKeysApiGetApiApiKeysCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiApiKeysCompanyCompanyId(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiGetApiApiKeysIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiApiKeysId(requestParameters: ApiKeysApiGetApiApiKeysIdRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiApiKeysId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiGetApiApiKeysIdActiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiApiKeysIdActive(requestParameters: ApiKeysApiGetApiApiKeysIdActiveRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiApiKeysIdActive(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiGetApiApiKeysIdDeactiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiApiKeysIdDeactive(requestParameters: ApiKeysApiGetApiApiKeysIdDeactiveRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiApiKeysIdDeactive(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiGetApiApiKeysSiteSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiApiKeysSiteSiteId(requestParameters: ApiKeysApiGetApiApiKeysSiteSiteIdRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiApiKeysSiteSiteId(requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public getApiScopes(options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).getApiScopes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiPostApiApiKeyValidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public postApiApiKeyValidate(requestParameters: ApiKeysApiPostApiApiKeyValidateRequest = {}, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).postApiApiKeyValidate(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiPostApiApiKeysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public postApiApiKeys(requestParameters: ApiKeysApiPostApiApiKeysRequest = {}, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).postApiApiKeys(requestParameters.apiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiPostApiApiKeysGenerateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public postApiApiKeysGenerate(requestParameters: ApiKeysApiPostApiApiKeysGenerateRequest = {}, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).postApiApiKeysGenerate(requestParameters.apiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeysApiPutApiApiKeysIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public putApiApiKeysId(requestParameters: ApiKeysApiPutApiApiKeysIdRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).putApiApiKeysId(requestParameters.id, requestParameters.apiKey, options).then((request) => request(this.axios, this.basePath));
    }
}
