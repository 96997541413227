// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PigmentProvider } from '../model';
/**
 * PigmentProvidersApi - axios parameter creator
 * @export
 */
export const PigmentProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiPigmentProvidersId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiPigmentProvidersId', 'id', id)
            const localVarPath = `/api/PigmentProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigmentProviders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PigmentProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigmentProvidersId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiPigmentProvidersId', 'id', id)
            const localVarPath = `/api/PigmentProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PigmentProvider} [pigmentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiPigmentProviders: async (pigmentProvider?: PigmentProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PigmentProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pigmentProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PigmentProvider} [pigmentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiPigmentProvidersId: async (id: number, pigmentProvider?: PigmentProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiPigmentProvidersId', 'id', id)
            const localVarPath = `/api/PigmentProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pigmentProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PigmentProvidersApi - functional programming interface
 * @export
 */
export const PigmentProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PigmentProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiPigmentProvidersId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PigmentProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiPigmentProvidersId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiPigmentProviders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PigmentProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiPigmentProviders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiPigmentProvidersId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PigmentProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiPigmentProvidersId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PigmentProvider} [pigmentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiPigmentProviders(pigmentProvider?: PigmentProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PigmentProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiPigmentProviders(pigmentProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PigmentProvider} [pigmentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiPigmentProvidersId(id: number, pigmentProvider?: PigmentProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PigmentProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiPigmentProvidersId(id, pigmentProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PigmentProvidersApi - factory interface
 * @export
 */
export const PigmentProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PigmentProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {PigmentProvidersApiDeleteApiPigmentProvidersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiPigmentProvidersId(requestParameters: PigmentProvidersApiDeleteApiPigmentProvidersIdRequest, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider> {
            return localVarFp.deleteApiPigmentProvidersId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigmentProviders(options?: AxiosRequestConfig): AxiosPromise<Array<PigmentProvider>> {
            return localVarFp.getApiPigmentProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PigmentProvidersApiGetApiPigmentProvidersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiPigmentProvidersId(requestParameters: PigmentProvidersApiGetApiPigmentProvidersIdRequest, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider> {
            return localVarFp.getApiPigmentProvidersId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PigmentProvidersApiPostApiPigmentProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiPigmentProviders(requestParameters: PigmentProvidersApiPostApiPigmentProvidersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider> {
            return localVarFp.postApiPigmentProviders(requestParameters.pigmentProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PigmentProvidersApiPutApiPigmentProvidersIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiPigmentProvidersId(requestParameters: PigmentProvidersApiPutApiPigmentProvidersIdRequest, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider> {
            return localVarFp.putApiPigmentProvidersId(requestParameters.id, requestParameters.pigmentProvider, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PigmentProvidersApi - interface
 * @export
 * @interface PigmentProvidersApi
 */
export interface PigmentProvidersApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApiInterface
     */
    deleteApiPigmentProvidersId(id: number, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApiInterface
     */
    getApiPigmentProviders(options?: AxiosRequestConfig): AxiosPromise<Array<PigmentProvider>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApiInterface
     */
    getApiPigmentProvidersId(id: number, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider>;

    /**
     * 
     * @param {PigmentProvider} [pigmentProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApiInterface
     */
    postApiPigmentProviders(pigmentProvider?: PigmentProvider, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider>;

    /**
     * 
     * @param {number} id 
     * @param {PigmentProvider} [pigmentProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApiInterface
     */
    putApiPigmentProvidersId(id: number, pigmentProvider?: PigmentProvider, options?: AxiosRequestConfig): AxiosPromise<PigmentProvider>;

}

/**
 * Request parameters for deleteApiPigmentProvidersId operation in PigmentProvidersApi.
 * @export
 * @interface PigmentProvidersApiDeleteApiPigmentProvidersIdRequest
 */
export interface PigmentProvidersApiDeleteApiPigmentProvidersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PigmentProvidersApiDeleteApiPigmentProvidersId
     */
    readonly id: number
}

/**
 * Request parameters for getApiPigmentProvidersId operation in PigmentProvidersApi.
 * @export
 * @interface PigmentProvidersApiGetApiPigmentProvidersIdRequest
 */
export interface PigmentProvidersApiGetApiPigmentProvidersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PigmentProvidersApiGetApiPigmentProvidersId
     */
    readonly id: number
}

/**
 * Request parameters for postApiPigmentProviders operation in PigmentProvidersApi.
 * @export
 * @interface PigmentProvidersApiPostApiPigmentProvidersRequest
 */
export interface PigmentProvidersApiPostApiPigmentProvidersRequest {
    /**
     * 
     * @type {PigmentProvider}
     * @memberof PigmentProvidersApiPostApiPigmentProviders
     */
    readonly pigmentProvider?: PigmentProvider
}

/**
 * Request parameters for putApiPigmentProvidersId operation in PigmentProvidersApi.
 * @export
 * @interface PigmentProvidersApiPutApiPigmentProvidersIdRequest
 */
export interface PigmentProvidersApiPutApiPigmentProvidersIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PigmentProvidersApiPutApiPigmentProvidersId
     */
    readonly id: number

    /**
     * 
     * @type {PigmentProvider}
     * @memberof PigmentProvidersApiPutApiPigmentProvidersId
     */
    readonly pigmentProvider?: PigmentProvider
}

/**
 * PigmentProvidersApi - object-oriented interface
 * @export
 * @class PigmentProvidersApi
 * @extends {BaseAPI}
 */
export class PigmentProvidersApi extends BaseAPI implements PigmentProvidersApiInterface {
    /**
     * 
     * @param {PigmentProvidersApiDeleteApiPigmentProvidersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApi
     */
    public deleteApiPigmentProvidersId(requestParameters: PigmentProvidersApiDeleteApiPigmentProvidersIdRequest, options?: AxiosRequestConfig) {
        return PigmentProvidersApiFp(this.configuration).deleteApiPigmentProvidersId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApi
     */
    public getApiPigmentProviders(options?: AxiosRequestConfig) {
        return PigmentProvidersApiFp(this.configuration).getApiPigmentProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PigmentProvidersApiGetApiPigmentProvidersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApi
     */
    public getApiPigmentProvidersId(requestParameters: PigmentProvidersApiGetApiPigmentProvidersIdRequest, options?: AxiosRequestConfig) {
        return PigmentProvidersApiFp(this.configuration).getApiPigmentProvidersId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PigmentProvidersApiPostApiPigmentProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApi
     */
    public postApiPigmentProviders(requestParameters: PigmentProvidersApiPostApiPigmentProvidersRequest = {}, options?: AxiosRequestConfig) {
        return PigmentProvidersApiFp(this.configuration).postApiPigmentProviders(requestParameters.pigmentProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PigmentProvidersApiPutApiPigmentProvidersIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PigmentProvidersApi
     */
    public putApiPigmentProvidersId(requestParameters: PigmentProvidersApiPutApiPigmentProvidersIdRequest, options?: AxiosRequestConfig) {
        return PigmentProvidersApiFp(this.configuration).putApiPigmentProvidersId(requestParameters.id, requestParameters.pigmentProvider, options).then((request) => request(this.axios, this.basePath));
    }
}
