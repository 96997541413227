// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedProductLine } from '../model';
/**
 * FeedProductLinesApi - axios parameter creator
 * @export
 */
export const FeedProductLinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedProductLinesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiFeedProductLinesId', 'id', id)
            const localVarPath = `/api/FeedProductLines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedProductLines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedProductLines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedProductLinesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiFeedProductLinesId', 'id', id)
            const localVarPath = `/api/FeedProductLines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedProductLines: async (feedProductLine?: FeedProductLine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FeedProductLines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedProductLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedProductLinesId: async (id: number, feedProductLine?: FeedProductLine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiFeedProductLinesId', 'id', id)
            const localVarPath = `/api/FeedProductLines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedProductLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedProductLinesApi - functional programming interface
 * @export
 */
export const FeedProductLinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedProductLinesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiFeedProductLinesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiFeedProductLinesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedProductLines(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedProductLine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedProductLines(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiFeedProductLinesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiFeedProductLinesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiFeedProductLines(feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiFeedProductLines(feedProductLine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiFeedProductLinesId(id: number, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiFeedProductLinesId(id, feedProductLine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedProductLinesApi - factory interface
 * @export
 */
export const FeedProductLinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedProductLinesApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedProductLinesApiDeleteApiFeedProductLinesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiFeedProductLinesId(requestParameters: FeedProductLinesApiDeleteApiFeedProductLinesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.deleteApiFeedProductLinesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedProductLines(options?: AxiosRequestConfig): AxiosPromise<Array<FeedProductLine>> {
            return localVarFp.getApiFeedProductLines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiGetApiFeedProductLinesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiFeedProductLinesId(requestParameters: FeedProductLinesApiGetApiFeedProductLinesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.getApiFeedProductLinesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPostApiFeedProductLinesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiFeedProductLines(requestParameters: FeedProductLinesApiPostApiFeedProductLinesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.postApiFeedProductLines(requestParameters.feedProductLine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPutApiFeedProductLinesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiFeedProductLinesId(requestParameters: FeedProductLinesApiPutApiFeedProductLinesIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.putApiFeedProductLinesId(requestParameters.id, requestParameters.feedProductLine, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedProductLinesApi - interface
 * @export
 * @interface FeedProductLinesApi
 */
export interface FeedProductLinesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    deleteApiFeedProductLinesId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    getApiFeedProductLines(options?: AxiosRequestConfig): AxiosPromise<Array<FeedProductLine>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    getApiFeedProductLinesId(id: number, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {FeedProductLine} [feedProductLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    postApiFeedProductLines(feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} id 
     * @param {FeedProductLine} [feedProductLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    putApiFeedProductLinesId(id: number, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

}

/**
 * Request parameters for deleteApiFeedProductLinesId operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiDeleteApiFeedProductLinesIdRequest
 */
export interface FeedProductLinesApiDeleteApiFeedProductLinesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiDeleteApiFeedProductLinesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiFeedProductLinesId operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiGetApiFeedProductLinesIdRequest
 */
export interface FeedProductLinesApiGetApiFeedProductLinesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiGetApiFeedProductLinesId
     */
    readonly id: number
}

/**
 * Request parameters for postApiFeedProductLines operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPostApiFeedProductLinesRequest
 */
export interface FeedProductLinesApiPostApiFeedProductLinesRequest {
    /**
     * 
     * @type {FeedProductLine}
     * @memberof FeedProductLinesApiPostApiFeedProductLines
     */
    readonly feedProductLine?: FeedProductLine
}

/**
 * Request parameters for putApiFeedProductLinesId operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPutApiFeedProductLinesIdRequest
 */
export interface FeedProductLinesApiPutApiFeedProductLinesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiPutApiFeedProductLinesId
     */
    readonly id: number

    /**
     * 
     * @type {FeedProductLine}
     * @memberof FeedProductLinesApiPutApiFeedProductLinesId
     */
    readonly feedProductLine?: FeedProductLine
}

/**
 * FeedProductLinesApi - object-oriented interface
 * @export
 * @class FeedProductLinesApi
 * @extends {BaseAPI}
 */
export class FeedProductLinesApi extends BaseAPI implements FeedProductLinesApiInterface {
    /**
     * 
     * @param {FeedProductLinesApiDeleteApiFeedProductLinesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public deleteApiFeedProductLinesId(requestParameters: FeedProductLinesApiDeleteApiFeedProductLinesIdRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).deleteApiFeedProductLinesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public getApiFeedProductLines(options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).getApiFeedProductLines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiGetApiFeedProductLinesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public getApiFeedProductLinesId(requestParameters: FeedProductLinesApiGetApiFeedProductLinesIdRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).getApiFeedProductLinesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPostApiFeedProductLinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public postApiFeedProductLines(requestParameters: FeedProductLinesApiPostApiFeedProductLinesRequest = {}, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).postApiFeedProductLines(requestParameters.feedProductLine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPutApiFeedProductLinesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public putApiFeedProductLinesId(requestParameters: FeedProductLinesApiPutApiFeedProductLinesIdRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).putApiFeedProductLinesId(requestParameters.id, requestParameters.feedProductLine, options).then((request) => request(this.axios, this.basePath));
    }
}
