// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Operation } from '../model';
// @ts-ignore
import { Site } from '../model';
// @ts-ignore
import { SiteBriefInfo } from '../model';
// @ts-ignore
import { SiteCompanyBriefInfo } from '../model';
// @ts-ignore
import { SiteWeb } from '../model';
// @ts-ignore
import { UpdateSiteRequest } from '../model';
/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiSitesId', 'id', id)
            const localVarPath = `/api/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesCompanyIdSites: async (companyId: string, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiCompaniesCompanyIdSites', 'companyId', companyId)
            const localVarPath = `/api/Companies/{companyId}/Sites`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sites/All`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiSitesId', 'id', id)
            const localVarPath = `/api/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sitesInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [$include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSelfId: async (id: number, $include?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiSitesSelfId', 'id', id)
            const localVarPath = `/api/Sites/Self/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($include !== undefined) {
                localVarQueryParameter['$include'] = $include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiSitesId: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchApiSitesId', 'id', id)
            const localVarPath = `/api/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Site} [site] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSites: async (site?: Site, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(site, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesId: async (id: number, updateSiteRequest?: UpdateSiteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiSitesId', 'id', id)
            const localVarPath = `/api/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiSitesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiSitesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiCompaniesCompanyIdSites(companyId: string, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiCompaniesCompanyIdSites(companyId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteCompanyBriefInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteCompanyBriefInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [$include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiSitesSelfId(id: number, $include?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteBriefInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiSitesSelfId(id, $include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiSitesId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiSitesId(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Site} [site] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiSites(site?: Site, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiSites(site, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSiteRequest} [updateSiteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiSitesId(id: number, updateSiteRequest?: UpdateSiteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiSitesId(id, updateSiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitesApiFp(configuration)
    return {
        /**
         * 
         * @param {SitesApiDeleteApiSitesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiSitesId(requestParameters: SitesApiDeleteApiSitesIdRequest, options?: AxiosRequestConfig): AxiosPromise<Site> {
            return localVarFp.deleteApiSitesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SitesApiGetApiCompaniesCompanyIdSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiCompaniesCompanyIdSites(requestParameters: SitesApiGetApiCompaniesCompanyIdSitesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SiteWeb>> {
            return localVarFp.getApiCompaniesCompanyIdSites(requestParameters.companyId, requestParameters.includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSites(options?: AxiosRequestConfig): AxiosPromise<Array<SiteCompanyBriefInfo>> {
            return localVarFp.getApiSites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesAll(options?: AxiosRequestConfig): AxiosPromise<SiteWeb> {
            return localVarFp.getApiSitesAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SitesApiGetApiSitesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesId(requestParameters: SitesApiGetApiSitesIdRequest, options?: AxiosRequestConfig): AxiosPromise<SiteWeb> {
            return localVarFp.getApiSitesId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesInfo(options?: AxiosRequestConfig): AxiosPromise<Array<SiteCompanyBriefInfo>> {
            return localVarFp.getApiSitesInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SitesApiGetApiSitesSelfIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiSitesSelfId(requestParameters: SitesApiGetApiSitesSelfIdRequest, options?: AxiosRequestConfig): AxiosPromise<SiteBriefInfo> {
            return localVarFp.getApiSitesSelfId(requestParameters.id, requestParameters.$include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SitesApiPatchApiSitesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiSitesId(requestParameters: SitesApiPatchApiSitesIdRequest, options?: AxiosRequestConfig): AxiosPromise<Site> {
            return localVarFp.patchApiSitesId(requestParameters.id, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SitesApiPostApiSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiSites(requestParameters: SitesApiPostApiSitesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Site> {
            return localVarFp.postApiSites(requestParameters.site, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SitesApiPutApiSitesIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiSitesId(requestParameters: SitesApiPutApiSitesIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putApiSitesId(requestParameters.id, requestParameters.updateSiteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SitesApi - interface
 * @export
 * @interface SitesApi
 */
export interface SitesApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    deleteApiSitesId(id: number, options?: AxiosRequestConfig): AxiosPromise<Site>;

    /**
     * 
     * @param {string} companyId 
     * @param {boolean} [includeDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    getApiCompaniesCompanyIdSites(companyId: string, includeDeleted?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<SiteWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    getApiSites(options?: AxiosRequestConfig): AxiosPromise<Array<SiteCompanyBriefInfo>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    getApiSitesAll(options?: AxiosRequestConfig): AxiosPromise<SiteWeb>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    getApiSitesId(id: number, options?: AxiosRequestConfig): AxiosPromise<SiteWeb>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    getApiSitesInfo(options?: AxiosRequestConfig): AxiosPromise<Array<SiteCompanyBriefInfo>>;

    /**
     * 
     * @param {number} id 
     * @param {string} [$include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    getApiSitesSelfId(id: number, $include?: string, options?: AxiosRequestConfig): AxiosPromise<SiteBriefInfo>;

    /**
     * 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    patchApiSitesId(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Site>;

    /**
     * 
     * @param {Site} [site] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    postApiSites(site?: Site, options?: AxiosRequestConfig): AxiosPromise<Site>;

    /**
     * 
     * @param {number} id 
     * @param {UpdateSiteRequest} [updateSiteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApiInterface
     */
    putApiSitesId(id: number, updateSiteRequest?: UpdateSiteRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteApiSitesId operation in SitesApi.
 * @export
 * @interface SitesApiDeleteApiSitesIdRequest
 */
export interface SitesApiDeleteApiSitesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiDeleteApiSitesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiCompaniesCompanyIdSites operation in SitesApi.
 * @export
 * @interface SitesApiGetApiCompaniesCompanyIdSitesRequest
 */
export interface SitesApiGetApiCompaniesCompanyIdSitesRequest {
    /**
     * 
     * @type {string}
     * @memberof SitesApiGetApiCompaniesCompanyIdSites
     */
    readonly companyId: string

    /**
     * 
     * @type {boolean}
     * @memberof SitesApiGetApiCompaniesCompanyIdSites
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for getApiSitesId operation in SitesApi.
 * @export
 * @interface SitesApiGetApiSitesIdRequest
 */
export interface SitesApiGetApiSitesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiGetApiSitesId
     */
    readonly id: number
}

/**
 * Request parameters for getApiSitesSelfId operation in SitesApi.
 * @export
 * @interface SitesApiGetApiSitesSelfIdRequest
 */
export interface SitesApiGetApiSitesSelfIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiGetApiSitesSelfId
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SitesApiGetApiSitesSelfId
     */
    readonly $include?: string
}

/**
 * Request parameters for patchApiSitesId operation in SitesApi.
 * @export
 * @interface SitesApiPatchApiSitesIdRequest
 */
export interface SitesApiPatchApiSitesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiPatchApiSitesId
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof SitesApiPatchApiSitesId
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiSites operation in SitesApi.
 * @export
 * @interface SitesApiPostApiSitesRequest
 */
export interface SitesApiPostApiSitesRequest {
    /**
     * 
     * @type {Site}
     * @memberof SitesApiPostApiSites
     */
    readonly site?: Site
}

/**
 * Request parameters for putApiSitesId operation in SitesApi.
 * @export
 * @interface SitesApiPutApiSitesIdRequest
 */
export interface SitesApiPutApiSitesIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SitesApiPutApiSitesId
     */
    readonly id: number

    /**
     * 
     * @type {UpdateSiteRequest}
     * @memberof SitesApiPutApiSitesId
     */
    readonly updateSiteRequest?: UpdateSiteRequest
}

/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI implements SitesApiInterface {
    /**
     * 
     * @param {SitesApiDeleteApiSitesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public deleteApiSitesId(requestParameters: SitesApiDeleteApiSitesIdRequest, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).deleteApiSitesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SitesApiGetApiCompaniesCompanyIdSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getApiCompaniesCompanyIdSites(requestParameters: SitesApiGetApiCompaniesCompanyIdSitesRequest, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).getApiCompaniesCompanyIdSites(requestParameters.companyId, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getApiSites(options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).getApiSites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getApiSitesAll(options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).getApiSitesAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SitesApiGetApiSitesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getApiSitesId(requestParameters: SitesApiGetApiSitesIdRequest, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).getApiSitesId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getApiSitesInfo(options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).getApiSitesInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SitesApiGetApiSitesSelfIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getApiSitesSelfId(requestParameters: SitesApiGetApiSitesSelfIdRequest, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).getApiSitesSelfId(requestParameters.id, requestParameters.$include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SitesApiPatchApiSitesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public patchApiSitesId(requestParameters: SitesApiPatchApiSitesIdRequest, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).patchApiSitesId(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SitesApiPostApiSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public postApiSites(requestParameters: SitesApiPostApiSitesRequest = {}, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).postApiSites(requestParameters.site, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SitesApiPutApiSitesIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public putApiSitesId(requestParameters: SitesApiPutApiSitesIdRequest, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).putApiSitesId(requestParameters.id, requestParameters.updateSiteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
